import { notificationPush } from 'utils/notificationUtils';
import { useCallback, useState } from 'react';
import { AuthorizationRepository } from 'repository';
import useAuth from './useAuth';
import { CheckRequest } from '@gamebase-ops/interfaces/authorization';

export default function usePageAuthCheck() {
    const [isLoading, setIsLoading] = useState(true);
    const { logoutRequest } = useAuth();

    const pageAuthCheck = useCallback(
        async (param: CheckRequest): Promise<CheckRequest | null> => {
            try {
                await AuthorizationRepository.check({ query: param });
                setIsLoading(false);
                return param;
            } catch (e: any) {
                if (e.code === 401) {
                    logoutRequest();
                    notificationPush({ status: 'error', message: '로그인이 만료되었습니다. 로그아웃합니다.' });
                    return null;
                }
                if (param.level && param.level > 1) {
                    return pageAuthCheck({ ...param, level: param.level - 1 });
                }
                setIsLoading(false);

                return null;
            }
        },
        [logoutRequest],
    );

    return {
        isLoading,
        pageAuthCheck,
    };
}
