import { MyProjectDto } from '@gamebase-ops/interfaces/authorization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthorizationRepository } from 'repository';

export default function useProjectList() {
    const [projectList, setProjectList] = useState<MyProjectDto[]>();

    const requestProjectList = useCallback(async () => {
        const result = await AuthorizationRepository.projectList();

        setProjectList(result.result);
    }, []);

    useEffect(() => {
        requestProjectList();
    }, [requestProjectList]);

    const groupByEnvProjectList = useMemo(() => {
        const groupMap: Record<string, MyProjectDto[]> = {
            prod: [],
            qa: [],
            dev: [],
        };

        projectList?.map((project) => {
            if (project.environment) {
                groupMap[project.environment].push(project);
            }
        });

        return groupMap;
    }, [projectList]);

    return {
        projectList,
        groupByEnvProjectList,
    };
}
