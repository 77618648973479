import { IMailTemplateRepository } from '@gamebase-ops/interfaces/mailTemplate/interface';
import {
    MailTemplateAllRequest,
    MailTemplateInfoRequest,
    MailTemplateListRequest,
} from '@gamebase-ops/interfaces/mailTemplate/request';
import {
    MailTemplateAllResponse,
    MailTemplateInfoResponse,
    MailTemplateListResponse,
} from '@gamebase-ops/interfaces/mailTemplate/response';
import { Query } from '@gamebase-ops/interfaces/utils';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/mail-templates';

class MailTemplateRepository implements IMailTemplateRepository {
    all(param: Query<MailTemplateAllRequest>): Promise<MailTemplateAllResponse> {
        return get(baseUrl + '/all', new MailTemplateAllRequest(param.query));
    }
    info(param: Query<MailTemplateInfoRequest>): Promise<MailTemplateInfoResponse> {
        return get(baseUrl + '/info', new MailTemplateInfoRequest(param.query));
    }
    list(param: Query<MailTemplateListRequest>): Promise<MailTemplateListResponse> {
        return get(baseUrl + '/list', new MailTemplateListRequest(param.query));
    }
}

export default new MailTemplateRepository();
