import { css } from '@emotion/react';
import { EPlatform } from '@gamebase-ops/interfaces/consts';
import { Button } from 'antd';
import { OsIcon } from 'components';
import { NOTICE_STATE_TEXT, URGENT_NOTICE_STATE_TEXT_KOREAN } from 'constants/constants';
import { useMaintenance, useModal } from 'hooks';

import MaintenanceModal from './MaintenanceModal';
import ServerStatusIcon from './ServerStatusIcon';

interface IProp {
    hasWritePermission: boolean;
    projectId: string;
}

export default function ServerControl({ projectId, hasWritePermission }: IProp) {
    const { maintenanceInfo } = useMaintenance(projectId);
    const { openModal, visible, closeModal } = useModal();

    return (
        <div css={wrapper}>
            {hasWritePermission && <Button onClick={openModal}>점검 설정</Button>}
            <section css={osWrapper}>
                {maintenanceInfo?.map((it) => (
                    <div css={osContents} key={it.platform}>
                        <OsIcon os={it.platform === EPlatform.AOS ? 'Android' : 'iOS'} style={{ fontSize: '32px' }} />
                        <div css={operationViewWrapper}>
                            <ServerStatusIcon serverStatus={it.serverState} />
                            {URGENT_NOTICE_STATE_TEXT_KOREAN[it.serverState]}
                        </div>
                        <div>{NOTICE_STATE_TEXT[it.requestState]}</div>
                    </div>
                ))}
            </section>

            {visible && <MaintenanceModal projectId={projectId} visible={visible} onCancel={closeModal} />}
        </div>
    );
}

const wrapper = css`
    display: flex;
`;

const osWrapper = css`
    display: flex;
`;

const osContents = css`
    margin-left: 16px;
    text-align: center;
`;

const operationViewWrapper = css`
    display: flex;
    align-items: center;
`;
