import { lazy } from 'react';

export const Home = lazy(() => import('./Home'));

export const Account = lazy(() => import('./account/Account'));
export const ProjectServiceAuth = lazy(() => import('./admin/ProjectServiceAuth'));
export const RolePage = lazy(() => import('./Role'));
export const MyPage = lazy(() => import('./myPage/MyPage'));
export const ItemPage = lazy(() => import('./project/Item'));
export const AccountDetail = lazy(() => import('./account/AccountDetail'));
export const ProjectDashBoard = lazy(() => import('./project/ProjectDashBoard'));
export const MailPage = lazy(() => import('./project/mail/Mail'));
export const MailDetailPage = lazy(() => import('./project/mail/detail/MailDetail'));
export const UserPage = lazy(() => import('./project/user/User'));
export const NoticePage = lazy(() => import('./project/notice/Notice'));
export const AccountMerge = lazy(() => import('./project/accountMerge'));
export const ProjectSettingsPage = lazy(() => import('./project/project/ProjectSetting'));
export const LogPage = lazy(() => import('./project/log/Log'));
export const VoidedPurchasePage = lazy(() => import('./project/VoidedPurchase'));
export const OrderPage = lazy(() => import('./project/Order'));
export const BlockUserPage = lazy(() => import('./project/BlockUser'));
export const WebviewEventPage = lazy(() => import('./project/event/WebviewEvent'));

export { default as LoginPage } from './Login';
