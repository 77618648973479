import { css } from '@emotion/react';
import { Input } from 'antd';
import { EnterIgnoreForm } from 'components';
import { useFormatMessage } from 'hooks';
import { useState } from 'react';

interface IUserNameButtonProps {
    onNickNameChange?: (nickName: string) => void;
    username: string;
    hasWritePermission: boolean;
}

/**
 * 유저 이름 변경 버튼 부분
 * hasWritePermission이 false이면 이름만 보여준다.
 */
function UserNameButton({ onNickNameChange, username, hasWritePermission }: IUserNameButtonProps) {
    const [isNameChangeMode, setIsNameChangeMode] = useState(false);
    const { messageFormatting } = useFormatMessage();

    if (!onNickNameChange || !hasWritePermission) {
        return <div>{username}</div>;
    }
    return (
        <div css={nameWrapper}>
            {isNameChangeMode ? (
                <EnterIgnoreForm
                    onSubmit={({ name }: any) => {
                        setIsNameChangeMode(false);
                        onNickNameChange && onNickNameChange(name);
                    }}
                >
                    <Input name="name" css={nameChangeInput} />
                    <button type="submit" css={changeButton}>
                        {messageFormatting('CHANGE')}
                    </button>
                </EnterIgnoreForm>
            ) : (
                <>
                    {username}
                    {hasWritePermission && (
                        <section css={changeButton} onClick={() => setIsNameChangeMode(true)}>
                            {messageFormatting('CHANGE')}
                        </section>
                    )}
                </>
            )}
        </div>
    );
}

export default UserNameButton;

const nameWrapper = css`
    display: flex;
    border: 1px solid #1d1541;
    border-radius: 6px;
    padding: 4px 8px;
`;

const changeButton = css`
    padding: 0 8px;
    margin-left: 4px;
    background-color: #141541;
    color: white;
    border-radius: 4px;
    cursor: pointer;
`;

const nameChangeInput = css`
    width: 150px;
    padding: 0px 4px;
`;
