import { BaseSyntheticEvent } from 'react';
import { assocPath } from './deepSetObject';

export const findTargetElementByType = (e: BaseSyntheticEvent, type: string | string[]) => {
    let target: HTMLElement | null = e.target;

    const typeCheck = (targetType?: string) => {
        if (typeof type === 'string') return targetType !== type;
        return !type.find((typeStr) => targetType == typeStr);
    };

    while (target && typeCheck(target.dataset['type'])) {
        target = target.parentElement;
    }
    return target;
};

export const findTargetElementByClassName = (e: BaseSyntheticEvent, className: string) => {
    let target: HTMLElement | null = e.target;
    while (target) {
        if (target.classList.contains(className)) break;
        target = target.parentElement;
    }
    return target;
};

export const submitListener = (onSubmit?: (arg: any) => void) => (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const entries = formData.entries();

    const obj: any = {};
    for (const item of entries) {
        const splitKey = item[0].split('.');
        if (splitKey.length === 1) {
            obj[item[0]] = item[1];
        } else {
            assocPath(splitKey, item[1], obj);
        }
    }
    if (onSubmit) {
        onSubmit(obj);
    }
};
