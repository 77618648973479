import { css } from '@emotion/react';
import { Button, Modal, Form, Input } from 'antd';
import { EnterIgnoreForm, ItemFormList } from 'components';
import { ISendMailItem } from 'dto/mail.dto';
import { PackageItem } from 'dto/package.dto';
import { useCallback, useState } from 'react';

interface IProp {
    visible: boolean;
    onClose?: () => void;
    onSubmit?: (param: any) => void;
    packageDetail?: PackageItem;
}

export default function ItemPackageModal({ visible, onClose, onSubmit, packageDetail }: IProp) {
    const [items, setItems] = useState<ISendMailItem[]>([]);

    const submitListner = useCallback(
        (obj) => {
            if (!window.confirm('정말로 완료했습니까?')) return;

            if (onSubmit) {
                const items =
                    obj.mailItems?.map((item: any) => ({
                        ItemId: Number(item.ItemId),
                        Value: Number(item.Value),
                    })) || [];
                if (obj.productId) obj.productId = Number(obj.productId);
                onSubmit({
                    ...obj,
                    items,
                });
            }
        },
        [onSubmit],
    );

    return (
        <Modal visible={visible} onCancel={onClose} title="패키지 생성" footer={null} width="1000px">
            <EnterIgnoreForm onSubmit={submitListner}>
                <div css={formBody}>
                    <Form.Item label="productId">
                        <Input
                            type="number"
                            name="productId"
                            style={{ width: '400px' }}
                            defaultValue={packageDetail?.productId}
                        />
                    </Form.Item>
                    <Form.Item label="패키지명">
                        <Form.Item label="ko">
                            <Input style={{ width: '400px' }} name="name.ko" defaultValue={packageDetail?.name.ko} />
                        </Form.Item>
                        <Form.Item label="ja">
                            <Input style={{ width: '400px' }} name="name.ja" defaultValue={packageDetail?.name.ja} />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="설명">
                        <textarea defaultValue={packageDetail?.description} name="description" css={description} />
                    </Form.Item>
                    <Form.Item label="구성상품">
                        <ItemFormList
                            items={items}
                            addItems={(item: ISendMailItem) => setItems((prev) => [...prev, item])}
                            deleteItem={(index: number) => setItems((prev) => prev.filter((_, i) => index !== i))}
                        />
                    </Form.Item>
                </div>
                <footer css={formFooter}>
                    <Button type="primary" htmlType="submit">
                        {packageDetail ? '수정' : '생성'}
                    </Button>
                </footer>
            </EnterIgnoreForm>
        </Modal>
    );
}

const formFooter = css`
    text-align: right;
`;

const formBody = css`
    margin-bottom: 64px;
`;

const description = css`
    width: 400px;
    height: 100px;
`;
