import classNames from 'classnames/bind';
import styles from './Header.module.scss';

interface IProp {
    title: string;
}

const cx = classNames.bind(styles);

const Header: React.FC<IProp> = ({ children, title }) => {
    return (
        <header className={cx('header')}>
            <h1 className={cx('title')}>{title}</h1>
            {children}
        </header>
    );
};

export default Header;
