import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';

interface IProp extends React.HTMLAttributes<HTMLDetailsElement> {
    summary?: React.ReactChild;
}

export default function Details(props: IProp) {
    const { children, summary, ...ohthers } = props;
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback((e) => {
        setIsOpen(e.target.open);
    }, []);
    return (
        <details {...ohthers} css={[detailStyle, props.className]} onToggle={onToggle}>
            <summary>
                {isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />}
                {summary}
            </summary>
            {children}
        </details>
    );
}

const detailStyle = css`
    summary {
        display: flex;
        cursor: pointer;
    }
`;
