import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { MailTemplateRepository } from 'repository';

export default function useMailTemplateModel(defaultProjectId?: string) {
    const [projectId, setProjectId] = useState<string | undefined>(defaultProjectId);
    const queries = useQuery(['mailTemplates', projectId], () =>
        projectId ? MailTemplateRepository.all({ query: { projectId } }) : null,
    );

    const getByTemplateId = useCallback(
        (templateId) => {
            const { data } = queries;
            if (data) {
                return data.result.find((it) => it.templateId === templateId);
            }
        },
        [queries],
    );

    return {
        ...queries,
        setProjectId,
        getByTemplateId,
    };
}
