import { IAuthenticationRepository } from '@gamebase-ops/interfaces/authentication/interface';
import { ProfileUpdateRequest } from '@gamebase-ops/interfaces/authentication/request';
import { ProfileResponse } from '@gamebase-ops/interfaces/authentication/response';
import { Body } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils/response';
import { get, patch } from 'utils/api/client';

const baseUrl = '/api/v1/authentication';

class AuthenticationRepository implements IAuthenticationRepository {
    profile(): Promise<ProfileResponse> {
        return get(baseUrl + '/profile');
    }
    updateProfile(param: Body<ProfileUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/profile', new ProfileUpdateRequest(param.body));
    }
    check(): Promise<BaseResponse> {
        return get(baseUrl + '/check');
    }
}

export default new AuthenticationRepository();
