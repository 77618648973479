import { IEventRepository } from '@gamebase-ops/interfaces/event/interface';
import {
    WebviewEventCreateRequest,
    WebviewEventUpdateRequest,
    WebviewEventInfoRequest,
    WebviewEventListRequest,
    WebviewEventListOfDeployedRequest,
    WebviewEventDeployRequest,
    WebviewEventUndeployRequest,
    WebviewEventSortRequest,
    WebviewEventHtmlUploadRequest,
} from '@gamebase-ops/interfaces/event/request';
import {
    WebviewEventInfoResponse,
    WebviewEventListResponse,
    WebviewEventListOfDeployedResponse,
    WebviewFileUploadResponse,
} from '@gamebase-ops/interfaces/event/response';
import { Query, Auth, Body, BaseResponse } from '@gamebase-ops/interfaces/utils';

import { get, patch, post } from 'utils/api/client';
const baseUrl = '/api/v1/events';
class WebviewEventRepository implements IEventRepository {
    createWebviewEvent(param: Query<Auth> & Body<WebviewEventCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/webview', new WebviewEventCreateRequest(param.body), new Auth(param.query));
    }
    updateWebviewEvent(param: Query<Auth> & Body<WebviewEventUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview', new WebviewEventUpdateRequest(param.body), new Auth(param.query));
    }
    infoWebviewEvent(param: Query<WebviewEventInfoRequest>): Promise<WebviewEventInfoResponse> {
        return get(baseUrl + '/webview/info', new WebviewEventInfoRequest(param.query));
    }
    listWebviewEvent(param: Query<WebviewEventListRequest>): Promise<WebviewEventListResponse> {
        return get(baseUrl + '/webview/list', new WebviewEventListRequest(param.query));
    }
    listOfDeployedWebviewEvent(
        param: Query<WebviewEventListOfDeployedRequest>,
    ): Promise<WebviewEventListOfDeployedResponse> {
        return get(baseUrl + '/webview/list/deployed', new WebviewEventListOfDeployedRequest(param.query));
    }
    deployWebviewEvent(param: Query<Auth> & Body<WebviewEventDeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview/deploy', new WebviewEventDeployRequest(param.body), new Auth(param.query));
    }
    undeployWebviewEvent(param: Query<Auth> & Body<WebviewEventUndeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview/undeploy', new WebviewEventUndeployRequest(param.body), new Auth(param.query));
    }
    sortWebviewEvent(param: Query<Auth> & Body<WebviewEventSortRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview/sort', new WebviewEventSortRequest(param.body), new Auth(param.query));
    }
    uploadImage(param: Query<Auth> & Body<FormData>): Promise<WebviewFileUploadResponse> {
        return post(baseUrl + '/webview/upload/image', param.body, new Auth(param.query));
    }
    uploadHtml(param: Query<Auth> & Body<WebviewEventHtmlUploadRequest>): Promise<WebviewFileUploadResponse> {
        return post(
            baseUrl + '/webview/upload/html',
            new WebviewEventHtmlUploadRequest(param.body),
            new Auth(param.query),
        );
    }
}

export default new WebviewEventRepository();
