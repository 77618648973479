import { menusPriority } from 'constants/constants';
import { useCallback, useState } from 'react';
import { AuthorizationRepository } from 'repository';

export default function useProjectCategory() {
    const [categories, setCategorires] = useState<ILinkProps[]>([]);

    const requestProjectCategories = useCallback(async (projectId: string) => {
        const { result } = await AuthorizationRepository.serviceList({ query: { projectId } });

        result.sort((a, b) => {
            let aIndex = menusPriority.findIndex((menu) => menu === a);
            let bIndex = menusPriority.findIndex((menu) => menu === b);
            aIndex = aIndex === -1 ? 999 : aIndex;
            bIndex = bIndex === -1 ? 999 : bIndex;
            return aIndex - bIndex;
        });

        const link = result.map((service) => ({ title: service, link: `/project/${service}` }));

        setCategorires([{ title: 'home', link: '/project/dashboard' }, ...link]);
    }, []);

    return { categories, requestProjectCategories };
}
