import { css } from '@emotion/react';
import { ProjectDetailDto } from '@gamebase-ops/interfaces/project';
import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';
import { Button } from 'antd';
import { Dropdown } from 'components';
import { usePermission } from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { findTargetElementByType } from 'utils/eventListener';
import { notificationPush } from 'utils/notificationUtils';

interface IProp {
    project: ProjectDetailDto;
    serviceAuthList?: ServiceAuthDto[];
    refetch?: () => void;
}

export default function ProjectPermissionEdit({ project, serviceAuthList, refetch }: IProp) {
    const { requestByProjectOid, postRequestPermission, softDeletePermissionRequest } = usePermission();

    const canAddServiceList = useMemo(() => {
        return serviceAuthList?.filter(
            (it) =>
                !project.permissions.find(
                    (permission) =>
                        permission.serviceAuth.service === it.service && permission.serviceAuth.level === it.level,
                ),
        );
    }, [project.permissions, serviceAuthList]);

    const projectOid = project.id;

    const permissionAddUlClickListener = useCallback(
        async (e: React.MouseEvent<HTMLUListElement>) => {
            if (!serviceAuthList) return;
            const target = findTargetElementByType(e, 'permissionAdd');
            if (target) {
                const serviceAuthId = target.dataset.id;
                const serviceAuthIndex = serviceAuthList?.findIndex((serviceAuth) => serviceAuth.id === serviceAuthId);

                if (serviceAuthId && window.confirm('정말로 권한을 추가하시겠습니까?')) {
                    await postRequestPermission({
                        project: projectOid,
                        serviceAuth: serviceAuthId,
                        name: `${project.name}_${serviceAuthList[serviceAuthIndex].description}`,
                        description: `${project.name}_${serviceAuthList[serviceAuthIndex].service}`,
                    });
                    refetch?.();
                    notificationPush({ status: 'success', title: '성공적으로 추가했습니다.' });
                    requestByProjectOid(projectOid);
                }
            }
        },
        [project, postRequestPermission, projectOid, serviceAuthList, requestByProjectOid, refetch],
    );

    const permissionRemoveUlClickListener = useCallback(
        async (e: React.MouseEvent<HTMLUListElement>) => {
            const target = findTargetElementByType(e, 'remove');

            if (target) {
                const permissionId = target.dataset.id;
                if (permissionId && window.confirm('정말로 삭제하시겠습니까?')) {
                    await softDeletePermissionRequest(permissionId);
                    notificationPush({ status: 'success', title: '성공적으로 삭제 했습니다.' });
                    requestByProjectOid(projectOid);
                    refetch?.();
                }
            }
        },
        [softDeletePermissionRequest, requestByProjectOid, projectOid, refetch],
    );

    useEffect(() => {
        requestByProjectOid(projectOid);
    }, [projectOid, requestByProjectOid]);

    return (
        <section>
            <section css={dropDownWrapper}>
                <Dropdown title="권한 추가">
                    <ul css={serviceUl} onClick={permissionAddUlClickListener}>
                        {canAddServiceList
                            ?.filter(
                                (serviceAuth) =>
                                    project.permissions.findIndex((permission) => serviceAuth.id === permission.id) ===
                                    -1,
                            )
                            .map((serviceAuth) => (
                                <li
                                    css={serviceLi}
                                    key={serviceAuth.id}
                                    data-type="permissionAdd"
                                    data-id={serviceAuth.id}
                                >
                                    {serviceAuth.description}
                                </li>
                            ))}
                    </ul>
                </Dropdown>
            </section>

            <details>
                <summary>권한 리스트</summary>
                <ul css={serviceUl} onClick={permissionRemoveUlClickListener}>
                    {project.permissions.map((permission) => (
                        <li css={serviceLi} key={permission.id}>
                            <span>{permission.name}</span>
                            <Button type="primary" danger data-type="remove" data-id={permission.id}>
                                삭제
                            </Button>
                        </li>
                    ))}
                </ul>
            </details>
        </section>
    );
}

const dropDownWrapper = css`
    width: 100%;
    text-align: right;
    margin-bottom: 24px;
`;

const serviceUl = css`
    list-style: none;
    padding: 0;
`;

const serviceLi = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 50%;
    cursor: pointer;
    &:hover {
        background: #b4b4ff;
    }
`;
