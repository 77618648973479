import { get, patch } from 'utils/api/client';
import { IItemRepository } from '@gamebase-ops/interfaces/item/interface';
import {
    ItemAllRequest,
    ItemInfoRequest,
    ItemListRequest,
    ItemLockRequest,
    ItemUnlockRequest,
    LockItemListRequest,
} from '@gamebase-ops/interfaces/item/request';
import {
    ItemAllResponse,
    ItemInfoResponse,
    ItemListResponse,
    LockItemListResponse,
} from '@gamebase-ops/interfaces/item/response';
import { Auth, Body, Query } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils';

const baseUrl = '/api/v1/items';
class ItemRepository implements IItemRepository {
    all(param: Query<ItemAllRequest>): Promise<ItemAllResponse> {
        return get(baseUrl + '/all', new ItemAllRequest(param.query));
    }
    listInLockItem(param: Query<LockItemListRequest>): Promise<LockItemListResponse> {
        return get(baseUrl + '/lock', new LockItemListRequest(param.query));
    }
    lock(param: Query<Auth> & Body<ItemLockRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/lock', new ItemLockRequest(param.body), new Auth(param.query));
    }
    unlock(param: Query<Auth> & Body<ItemUnlockRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/unlock', new ItemUnlockRequest(param.body), new Auth(param.query));
    }
    info(param: Query<ItemInfoRequest>): Promise<ItemInfoResponse> {
        return get(baseUrl + '/info', new ItemInfoRequest(param.query));
    }
    list(param: Query<ItemListRequest>): Promise<ItemListResponse> {
        return get(baseUrl + '/list', new ItemListRequest(param.query));
    }
}

export default new ItemRepository();
