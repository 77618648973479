import { IsDate, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { NOTICE_STATE } from 'constants/constants';
import { PaginationQuery } from './utils.dto';

export class WebviewNoticeCreateDto {
    constructor(param: WebviewNoticeCreateDto) {
        this.titles = param.titles;
        this.contents = param.contents;
        this.mainImages = param.mainImages;
        this.startTime = param.startTime;
        this.endTime = param.endTime;
        this.defaultLanguage = param.defaultLanguage;
    }
    @IsNotEmpty()
    @IsObject()
    titles: Record<string, string>;

    @IsNotEmpty()
    @IsObject()
    contents: Record<string, string>;

    @IsNotEmpty()
    @IsObject()
    mainImages: Record<string, string>;

    @IsNotEmpty()
    @IsDate()
    startTime: Date;

    @IsNotEmpty()
    @IsDate()
    endTime: Date;

    @IsNotEmpty()
    @IsString()
    defaultLanguage: string;
}

export class WebviewNoticeQueryDto extends PaginationQuery {
    constructor(param: WebviewNoticeQueryDto) {
        super(param);
        this.projectId = param.projectId;
        this.state = param.state;
        this.title = param.title ? param.title.trim() : undefined;
    }
    @IsNotEmpty()
    @IsNumber()
    projectId: number;

    @IsOptional()
    @IsNumber()
    state?: NOTICE_STATE;

    @IsOptional()
    @IsString()
    title?: string;
}

export type TWebviewContentsMode = 'link' | 'contents';

export interface IWebviewNotice {
    titles?: Record<string, string>;
    contents?: Record<string, string>;
    mainImages?: Record<string, string>;
    state: NOTICE_STATE;
    startTime: string;
    endTime: string;
    projectId: number;
    defaultLanguage: string;
    _id: string;
    sorted: number;
    category?: string;

    updDt: string;
    regDt: string;
    regId: IAccount;
    updId: IAccount;
}

export class WebviewNoticeSortDto {
    constructor(param: WebviewNoticeSortDto) {
        this.notices = param.notices;
    }

    @IsNotEmpty()
    notices: Array<{
        oid: string;
        sorted: number;
    }>;
}
