import {
    CheckRequest,
    IAuthorizationRepository,
    PermissionListResponse,
    ProjectListResponse,
    RoleListRequest,
    RoleListResponse,
    ServiceListRequest,
    ServiceListResponse,
} from '@gamebase-ops/interfaces/authorization';
import { Query } from '@gamebase-ops/interfaces/utils/request';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/authorization';

class AuthorizationRepository implements IAuthorizationRepository {
    roleList(param: Query<RoleListRequest>): Promise<RoleListResponse> {
        return get(baseUrl + '/roles/list', new RoleListRequest(param.query));
    }
    permissionList(): Promise<PermissionListResponse> {
        return get(baseUrl + '/permissions/list');
    }
    projectList(): Promise<ProjectListResponse> {
        return get(baseUrl + '/projects/list');
    }
    serviceList(param: Query<ServiceListRequest>): Promise<ServiceListResponse> {
        return get(baseUrl + '/services/list', new ServiceListRequest(param.query));
    }
    check(param: Query<CheckRequest>): Promise<void> {
        return get(baseUrl + '/check', new CheckRequest(param.query));
    }
}

export default new AuthorizationRepository();
