import { WebviewNoticeCreateDto } from 'dto/webviewNotice.dto';
import { useCallback } from 'react';
import { NoticeRepository } from 'repository';

import useWebviewNoticeListModel from './model/useWebviewNoticeList.model';

export default function useWebviewNotice(projectId: string) {
    const {
        data: webviewNoticeData,
        setPage,
        setFirstPageOrRefetch,
        filter,
        findByNoticeTitle,
    } = useWebviewNoticeListModel(projectId);

    const createWebviewNotice = useCallback(
        async (param: WebviewNoticeCreateDto) => {
            await NoticeRepository.createWebviewNotice({ query: { projectId }, body: param });
        },
        [projectId],
    );

    const updateWebviewNoticeContents = useCallback(
        async (oid: string, param: WebviewNoticeCreateDto) => {
            await NoticeRepository.updateWebviewNotice({ query: { projectId }, body: { id: oid, ...param } });
        },
        [projectId],
    );

    const publishNotice = useCallback(
        async (oid: string) => {
            await NoticeRepository.deployWebviewNotice({ query: { projectId }, body: { id: oid } });
        },
        [projectId],
    );

    const unpublishNotice = useCallback(
        async (oid: string) => {
            await NoticeRepository.undeployWebviewNotice({ query: { projectId }, body: { id: oid } });
        },
        [projectId],
    );

    return {
        createWebviewNotice,
        webviewNoticeData,
        setPage,
        setFirstPageOrRefetch,
        filter,
        updateWebviewNoticeContents,
        publishNotice,
        unpublishNotice,
        findByNoticeTitle,
    };
}
