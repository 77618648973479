import {
    AccountInfoRequest,
    AccountInfoResponse,
    AccountListRequest,
    AccountListResponse,
    AccountUpdateRequest,
    GuestLoginRequest,
    GuestRegisterRequest,
    IAccountRepository,
    StaffLoginRequest,
} from '@gamebase-ops/interfaces/account';
import { Body, Query, Auth } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils/response';
import { get, patch, post } from 'utils/api/client';

const baseUrl = '/api/v1/accounts';

class AccountRepository implements IAccountRepository {
    logout(): Promise<BaseResponse> {
        return post(baseUrl + '/logout', {}, { projectId: 'COMMON' });
    }
    registerGuest(param: Body<GuestRegisterRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/guest', new GuestRegisterRequest(param.body));
    }
    login(param: Body<GuestLoginRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/guest/login', new GuestLoginRequest(param.body));
    }
    loginStaff(param: Body<StaffLoginRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/staff/login', new StaffLoginRequest(param.body));
    }
    update(param: Query<Auth> & Body<AccountUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new AccountUpdateRequest(param.body), param.query);
    }
    info(param: Query<AccountInfoRequest>): Promise<AccountInfoResponse> {
        return get(baseUrl + '/info', new AccountInfoRequest(param.query));
    }
    list(param: Query<AccountListRequest>): Promise<AccountListResponse> {
        return get(baseUrl + '/list', new AccountListRequest(param.query));
    }
}

export default new AccountRepository();
