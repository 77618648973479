import { del, get, patch, post } from 'utils/api/client';
import { IRoleRepository } from '@gamebase-ops/interfaces/role/interface';
import {
    RoleCreateRequest,
    RoleUpdateRequest,
    RoleInfoRequest,
    RoleInfoResponse,
    RoleListRequest,
    RoleListResponse,
    RoleDeleteRequest,
} from '@gamebase-ops/interfaces/role';
import { Body, BaseResponse, Query } from '@gamebase-ops/interfaces/utils';

const baseUrl = '/api/v1/roles';

class RoleRepository implements IRoleRepository {
    delete(param: Query<RoleDeleteRequest>): Promise<BaseResponse> {
        return del(baseUrl, new RoleDeleteRequest(param.query));
    }
    create(param: Body<RoleCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl, new RoleCreateRequest(param.body));
    }
    update(param: Body<RoleUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new RoleUpdateRequest(param.body));
    }
    info(params: Query<RoleInfoRequest>): Promise<RoleInfoResponse> {
        return get(baseUrl + '/info', new RoleInfoRequest(params.query));
    }
    list(params: Query<RoleListRequest>): Promise<RoleListResponse> {
        return get(baseUrl + '/list', new RoleListRequest(params.query));
    }
}

export default new RoleRepository();
