import { Modal } from 'antd';

const ModalWrapper: React.FC<ModalWrapperProps> = ({ title, visible, children, onCancel, onClick, width }) => {
    return (
        <Modal title={title} visible={visible} onCancel={onCancel} footer={false} zIndex={998} width={width}>
            <div onClick={onClick}>{children}</div>
        </Modal>
    );
};

export default ModalWrapper;
