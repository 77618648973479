import { add, remove } from 'utils/arrayUtils';
import { useCallback, useEffect, useRef, useState } from 'react';
import useGetRoleDetail from 'hooks/role/model/useGetRoleDetail';
import { AccountBasicDto } from '@gamebase-ops/interfaces/account';
import { PermissionDto } from '@gamebase-ops/interfaces/permission';

export default function useRoleDetail(id?: string) {
    const [permissions, setPermissions] = useState<Pick<PermissionDto, 'id' | 'name'>[]>([]);
    const [accounts, setAccounts] = useState<Pick<AccountBasicDto, 'id' | 'userId' | 'name'>[]>([]);
    const roleNameRef = useRef<any>();
    const descriptionRef = useRef<any>();

    const { data: role } = useGetRoleDetail(id);

    const addAccounts = useCallback(async (param: Pick<AccountBasicDto, 'id' | 'name' | 'userId'>) => {
        add(setAccounts, param);
    }, []);

    const removeAccounts = useCallback(async (param: Pick<AccountBasicDto, 'id' | 'name' | 'userId'>) => {
        remove(setAccounts, param);
    }, []);

    const addPermissions = useCallback(async (param: Pick<PermissionDto, 'id' | 'name'>) => {
        add(setPermissions, param);
    }, []);

    const removePermissions = useCallback(async (param: Pick<PermissionDto, 'id' | 'name'>) => {
        remove(setPermissions, param);
    }, []);

    useEffect(() => {
        if (role) {
            setPermissions(role.result.permissions);
            setAccounts(role.result.accounts);
        }
    }, [role]);

    return {
        permissions,
        accounts,
        addAccounts,
        removeAccounts,
        addPermissions,
        removePermissions,
        roleNameRef,
        descriptionRef,
        setPermissions,
        role: role?.result,
    };
}
