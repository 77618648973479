import moment from 'moment';
import { DatePicker, Form, TimePicker } from 'antd';
import classNames from 'classnames/bind';
import styles from './SendMailDatePicker.module.scss';
import { useFormatMessage } from 'hooks';

interface IProp {
    defaultStartDate?: Date;
    defaultEndDate?: Date;
    defaultStoreDay?: number;
}

const cx = classNames.bind(styles);

const SendMailDatePicker: React.FC<IProp> = ({ defaultEndDate, defaultStartDate }) => {
    const { messageFormatting } = useFormatMessage();

    return (
        <>
            <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('START_AT')}>
                <section className={cx('dateTimeWrapper')}>
                    <Form.Item>
                        <DatePicker
                            name={'sendTime.date'}
                            defaultValue={defaultStartDate ? moment(defaultStartDate) : undefined}
                        />
                    </Form.Item>
                    <Form.Item>
                        <TimePicker
                            name="sendTime.time"
                            defaultValue={defaultStartDate ? moment(defaultStartDate) : undefined}
                        />
                    </Form.Item>
                </section>
            </Form.Item>
            <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('END_AT')}>
                <section className={cx('dateTimeWrapper')}>
                    <Form.Item>
                        <DatePicker
                            name="endTime.date"
                            defaultValue={defaultStartDate ? moment(defaultEndDate) : undefined}
                        />
                    </Form.Item>
                    <Form.Item>
                        <TimePicker
                            name="endTime.time"
                            defaultValue={defaultStartDate ? moment(defaultEndDate) : undefined}
                        />
                    </Form.Item>
                </section>
            </Form.Item>
        </>
    );
};

export default SendMailDatePicker;
