import { css } from '@emotion/react';
import { Button, Modal } from 'antd';
import { Header } from 'components';
import { useFormatMessage, useQueries, useRestoreAccount } from 'hooks';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';
import AccountInfo from './components/AccountInfo';

const types: ['src', 'dst'] = ['src', 'dst'];
export default function RandomdiceWarsAccountMerge() {
    const { projectId } = useQueries();
    const { messageFormatting } = useFormatMessage();

    const { getUserData, restoreAccount, setUserDetailById, setUserListByName, getNameSearchList } =
        useRestoreAccount(projectId);

    const onSubmit = () => {
        Modal.confirm({
            title: messageFormatting('RESTORE_ACCOUNT_CONFIRM'),
            onOk: async () => {
                try {
                    await restoreAccount();
                    notificationPush({ status: 'success', message: messageFormatting('RESTORE_ACCOUNT_SUCCESSS') });
                } catch (e: any) {
                    notificationPush({ status: 'error', message: messageFormatting(e.message) });
                }
            },
        });
    };

    const onUserSearch = useCallback(
        (target: 'dst' | 'src') => (name: string, value: string) => {
            if (name === 'name') {
                setUserListByName(target, value);
            }

            if (name === 'pid') {
                setUserDetailById(target, value);
            }
        },
        [setUserDetailById, setUserListByName],
    );

    const onUserListTableClick = useCallback(
        (target: 'src' | 'dst') => (id: string) => {
            setUserDetailById(target, id);
        },
        [setUserDetailById],
    );

    return (
        <div>
            <Header title={messageFormatting('ACCOUNT_RESTORE')} />
            <div css={accountMergeWrapper}>
                {types.map((type: 'src' | 'dst') => (
                    <AccountInfo
                        key={type}
                        userList={getNameSearchList(type)}
                        userDetail={getUserData(type)}
                        onUserSearch={onUserSearch(type)}
                        onUserListTableClick={onUserListTableClick(type)}
                        type={type}
                    />
                ))}
            </div>
            <footer>
                <Button css={submitButton} onClick={onSubmit}>
                    {messageFormatting('ACCOUNT_RESTORE')}
                </Button>
            </footer>
        </div>
    );
}

const accountMergeWrapper = css`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 32px;
`;

const submitButton = css`
    width: 100%;
    height: 48px;
`;
