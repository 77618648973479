import { lazy } from 'react';
import { css } from '@emotion/react';
import { Button } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import { ConditinalWrapper, Header, ModalWrapper, OsIcon } from 'components';
import UserDescription from 'components/utils/Description';
import { USER_PLATFORM_TYPE_NAME, USER_STATUS } from 'constants/constants';
import { useFormatMessage, useModal } from 'hooks';
import moment from 'moment';
import UserNameButton from './UserNameButton';
import clipboardCopy from 'utils/clipboardCopy';
import UserTrophyButton from './UserTrophyButton';
import { IUser } from '@gamebase-ops/interfaces/gameUser';
const ReactJson = lazy(() => import('react-json-view'));

interface IProp {
    userDetail?: IUser;
    onNickNameChange?: (nickName: string) => void;
    hasWritePermission: boolean;
    onTrophyChange?: (trophy: number) => void;
}

/**
 * 유저 상세에서, 띠 리스트를 보여주는 컴포넌트
 */
export default function UserInfoDescription({
    userDetail,
    onNickNameChange,
    hasWritePermission,
    onTrophyChange,
}: IProp): JSX.Element {
    const { messageFormatting } = useFormatMessage();
    const { openModal, visible, closeModal } = useModal();
    return (
        <ConditinalWrapper condition={userDetail}>
            {userDetail && (
                <section>
                    <Header title={messageFormatting('USER_INFO')}>
                        <Button onClick={openModal}>JSON</Button>
                    </Header>

                    <section css={userDescriptionWrapper}>
                        <UserDescription title={messageFormatting('USER_NICKNAME')}>
                            <UserNameButton
                                hasWritePermission={hasWritePermission}
                                username={userDetail.user_name}
                                onNickNameChange={onNickNameChange}
                            />
                        </UserDescription>
                        <UserDescription title="AccountId">
                            <div>
                                {userDetail.account_id}
                                <CopyTwoTone
                                    css={clipboardButton}
                                    onClick={() => clipboardCopy(userDetail.account_id, messageFormatting)}
                                />
                            </div>
                        </UserDescription>
                        <UserDescription title="PID">
                            <div>
                                {userDetail.user_id}
                                <CopyTwoTone
                                    css={clipboardButton}
                                    onClick={() => clipboardCopy(userDetail.user_id, messageFormatting)}
                                />
                            </div>
                        </UserDescription>
                        <UserDescription title={messageFormatting('GUID')}>
                            <div>
                                {userDetail.device_guid}
                                <CopyTwoTone
                                    css={clipboardButton}
                                    onClick={() => clipboardCopy(userDetail.device_guid, messageFormatting)}
                                />
                            </div>
                        </UserDescription>
                        <UserDescription title="OS">
                            <OsIcon os={userDetail.os as OS} />
                        </UserDescription>
                        <UserDescription title="OS_Version">{userDetail.os_version}</UserDescription>
                        <UserDescription title="Version">{userDetail.version}</UserDescription>
                        <UserDescription title={messageFormatting('ACCOUNT_INTERLOCK')}>
                            {USER_PLATFORM_TYPE_NAME[userDetail.platformType]}
                        </UserDescription>
                        <UserDescription title={messageFormatting('USER_CREATED_AT')}>
                            {moment(userDetail.create_date).format('YYYY-MM-DD HH:mm:ss')}
                        </UserDescription>
                        <UserDescription title={messageFormatting('USER_LAST_LOGIN')}>
                            {moment(userDetail.login_date).format('YYYY-MM-DD HH:mm:ss')}
                        </UserDescription>
                        <UserDescription title={messageFormatting('USER_SANCTIONS_STATUS')}>
                            {userDetail.status === USER_STATUS.Block ? 'O' : 'X'}
                        </UserDescription>
                        <UserDescription title={messageFormatting('USER_LOGIN_TOTAL_COUNT')}>
                            {userDetail.accumulate_login_count}
                        </UserDescription>
                        <UserDescription title={messageFormatting('USER_LOGIN_CONTIUOUS_COUNT')}>
                            {userDetail.continuous_login_conut}
                        </UserDescription>
                        <UserDescription title="트로피">
                            <UserTrophyButton
                                trophy={userDetail.trophy || 0}
                                onTrophyChange={onTrophyChange}
                                hasWritePermission={hasWritePermission}
                            />
                        </UserDescription>
                        <UserDescription title="국가">{userDetail.country}</UserDescription>
                    </section>

                    <ModalWrapper visible={visible} onCancel={closeModal} title={messageFormatting('USER_INFO')}>
                        <ReactJson src={userDetail} collapsed />
                    </ModalWrapper>
                </section>
            )}
        </ConditinalWrapper>
    );
}

const userDescriptionWrapper = css`
    display: flex;
    flex-wrap: wrap;
`;

const clipboardButton = css`
    cursor: pointer;
`;
