import { Form, Input, Button } from 'antd';
import { IPasswordChangeDto } from 'dto/account.dto';
import { useFormatMessage, useFormRules } from 'hooks';
import { useCallback } from 'react';
interface IProp {
    onFinish: (arg: IPasswordChangeDto) => void;
    onCancel?: () => void;
}

const PasswordChangeForm: React.FC<IProp> = ({ onFinish, onCancel }) => {
    const { messageFormatting } = useFormatMessage();
    const { formValidator } = useFormRules();
    const submitListener = useCallback(
        (param: IPasswordChangeDto) => {
            onFinish(param);
        },
        [onFinish],
    );

    return (
        <Form onFinish={submitListener} labelCol={{ span: 8 }}>
            <Form.Item
                name="currentPassword"
                label={messageFormatting('PRE_PASSWORD')}
                rules={formValidator.getRules('account_password')}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item
                name="newPassword"
                label={messageFormatting('NEW_PASSWORD')}
                rules={formValidator.getRules('account_password')}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                label={messageFormatting('CONFIRM_PASSWORD')}
                rules={formValidator.getRules('account_confirm_password')}
            >
                <Input type="password" />
            </Form.Item>

            <Button danger onClick={onCancel}>
                {messageFormatting('CANCEL')}
            </Button>
            <Button htmlType="submit" type="primary" ghost style={{ marginLeft: '16px' }}>
                {messageFormatting('CHANGE')}
            </Button>
        </Form>
    );
};

export default PasswordChangeForm;
