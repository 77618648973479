import { css } from '@emotion/react';
import { Button, Input } from 'antd';
import { useCallback, useState } from 'react';
import { onSubmitCapture } from 'utils/form';
import { notificationPush } from 'utils/notificationUtils';

interface IProp {
    trophy: number;
    onTrophyChange?: (trophy: number) => void;
    hasWritePermission: boolean;
}

export default function UserTrophyButton({ trophy, onTrophyChange, hasWritePermission }: IProp) {
    const [isEditing, setIsEditing] = useState(false);
    const onFinishEditing = useCallback(
        async ({ trophy }) => {
            if (onTrophyChange && window.confirm('정말로 트로피를 변경하시겠습니까?')) {
                await onTrophyChange(Number(trophy));
                notificationPush({
                    status: 'success',
                    title: '성공적으로 변경했습니다.',
                });
                setIsEditing(false);
            }
        },
        [onTrophyChange],
    );
    if (!hasWritePermission || !onTrophyChange) return <div>{trophy}</div>;
    return (
        <div css={formWrapper}>
            {isEditing ? (
                <form onSubmit={onSubmitCapture(onFinishEditing)} css={formStyle}>
                    <Input type="number" name="trophy" defaultValue={trophy} />
                    <nav css={formNavStyle}>
                        <Button htmlType="submit" type="primary">
                            확인
                        </Button>
                        <Button htmlType="submit" onClick={() => setIsEditing(false)}>
                            취소
                        </Button>
                    </nav>
                </form>
            ) : (
                <>
                    <span css={tropyText}>{trophy}</span> <Button onClick={() => setIsEditing(true)}>변경</Button>
                </>
            )}
        </div>
    );
}

const formWrapper = css`
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
`;

const formStyle = css`
    display: flex;
`;

const formNavStyle = css`
    display: flex;
    button {
        margin-left: 4px;
    }
`;

const tropyText = css`
    padding: 6px;
`;
