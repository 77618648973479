import {
    IProjectRepository,
    ProjectCreateRequest,
    ProjectDeleteRequest,
    ProjectInfoRequest,
    ProjectInfoResponse,
    ProjectIpWhiteListAddRequest,
    ProjectIpWhiteListRemoveRequest,
    ProjectIpWhiteListRequest,
    ProjectIpWhiteListResponse,
    ProjectListRequest,
    ProjectListResponse,
    ProjectUpdateRequest,
} from '@gamebase-ops/interfaces/project';
import { Auth, Body, Query } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils/response';

import { del, get, patch, post } from 'utils/api/client';

const baseUrl = '/api/v1/projects';
class ProjectRepository implements IProjectRepository {
    delete(param: Query<ProjectDeleteRequest>): Promise<BaseResponse> {
        return del(baseUrl, new ProjectDeleteRequest(param.query));
    }
    ipWhiteList(param: Query<ProjectIpWhiteListRequest>): Promise<ProjectIpWhiteListResponse> {
        return get(baseUrl + '/ip-whitelist', new ProjectIpWhiteListRequest(param.query));
    }
    addToIpWhiteList(param: Query<Auth> & Body<ProjectIpWhiteListAddRequest>): Promise<BaseResponse> {
        return patch(
            baseUrl + '/ip-whitelist/add',
            new ProjectIpWhiteListAddRequest(param.body),
            new Auth(param.query),
        );
    }
    removeFromIpWhiteList(param: Query<Auth> & Body<ProjectIpWhiteListRemoveRequest>): Promise<BaseResponse> {
        return patch(
            baseUrl + '/ip-whitelist/remove',
            new ProjectIpWhiteListRemoveRequest(param.body),
            new Auth(param.query),
        );
    }
    create(param: Body<ProjectCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl, new ProjectCreateRequest(param.body));
    }
    update(param: Body<ProjectUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new ProjectUpdateRequest(param.body));
    }
    info(param: Query<ProjectInfoRequest>): Promise<ProjectInfoResponse> {
        return get(baseUrl + '/info', new ProjectInfoRequest(param.query));
    }
    list(param: Query<ProjectListRequest>): Promise<ProjectListResponse> {
        return get(baseUrl + '/list', new ProjectListRequest(param.query));
    }
}

export default new ProjectRepository();
