import { Key } from 'antd/lib/table/interface';
import { notificationPush } from 'utils/notificationUtils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { PermissionRepository } from 'repository';
import { EditPermissionRequestDto } from 'dto/permission.dto';
import { PermissionDto } from '@gamebase-ops/interfaces/permission';

export default function usePermission() {
    const [page, setPage] = useState(1);

    const [totalCount, setTotalCount] = useState(0);
    const [permissionList, setPermissionList] = useState<PermissionDto[]>([]);
    const [selectedRow, setSelectedRow] = useState<Key[]>([]);

    const rowSelection = useMemo(
        () => ({
            selectedRowKeys: selectedRow,
            onChange: (val: Key[]) => setSelectedRow(val),
        }),
        [selectedRow],
    );

    const requestPermissionList = useCallback(async (page: number) => {
        const { result } = await PermissionRepository.list({ query: { page, limit: 15 } });
        setPermissionList(result.data.map((permission) => ({ ...permission, key: permission.id })));
        setTotalCount(result.totalCount);
    }, []);

    const requestByProjectOid = useCallback(async (projectOid: string) => {
        const result = await PermissionRepository.findBy(projectOid);

        setPermissionList(result);
    }, []);

    const postPendingRef = useRef(false);

    const postRequestPermission = useCallback(async (param: IPostPermission) => {
        try {
            if (postPendingRef.current) {
                notificationPush({ status: 'warning', message: '잠시 기다려 주세요' });
                return;
            }
            postPendingRef.current = true;
            await PermissionRepository.create({
                body: { projectId: param.project, serviceAuthId: param.serviceAuth, ...param },
            });
            postPendingRef.current = false;
            return true;
        } catch (e) {
            postPendingRef.current = false;

            throw e;
        }
    }, []);

    const softDeletePermissionRequest = useCallback(async (oid: string) => {
        await PermissionRepository.delete({ query: { id: oid, projectId: 'COMMON' } });
    }, []);

    const updatePermissionRequest = useCallback(async (oid: string, data: EditPermissionRequestDto) => {
        await PermissionRepository.update({ body: { id: oid, ...new EditPermissionRequestDto(data) } });
    }, []);

    return {
        permissionList,
        requestPermissionList,
        page,
        setPage,
        totalCount,
        rowSelection,
        setSelectedRow,
        postRequestPermission,
        updatePermissionRequest,
        requestByProjectOid,
        softDeletePermissionRequest,
    };
}
