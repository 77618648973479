import moment from 'moment';
import { Form, Input, Button, Tooltip, Select } from 'antd';
import { ModalWrapper } from 'components';
import { useCallback } from 'react';

import classNames from 'classnames/bind';
import styles from './ProjectModal.module.scss';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import ProjectPermissionEdit from './ProjectPermissionEdit';
import { ProjectCreateRequest, ProjectUpdateRequest } from '@gamebase-ops/interfaces/project';
import { MyProjectDto } from '@gamebase-ops/interfaces/authorization';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';
import { useFormRules } from 'hooks';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    onSubmit?: (arg: ProjectCreateRequest | ProjectUpdateRequest) => void;
    projectValues: MyProjectDto | null;
    serviceAuthList?: ServiceAuthDto[];
}

const ProjectCreateModal: React.FC<IProp> = ({
    visible,
    onCancel,
    onSubmit,
    title,
    projectValues,
    serviceAuthList,
}) => {
    const { projectDetail, refetch } = useProjectDetail(projectValues?.id);
    const { formValidator } = useFormRules();

    const onFinish = useCallback(
        async (arg) => {
            if (onSubmit) {
                await onSubmit({
                    ...arg,
                    globalId: parseInt(arg.globalId, 10),
                    oid: arg.id,
                });
                refetch();
            }
        },
        [onSubmit, refetch],
    );
    if (projectValues && !projectDetail) return null;

    return (
        <ModalWrapper title={title} onCancel={onCancel} visible={visible} width="80%">
            <section className={cx('wrapper')}>
                <EnterIgnoreForm
                    className={cx('formWrapper')}
                    onFinish={onFinish}
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 5 }}
                    initialValues={{
                        ...projectDetail,
                    }}
                >
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item label="게임명" name="name" rules={formValidator.getRules('create_project_game')}>
                        <Input />
                    </Form.Item>

                    <Tooltip title="실제 게임이 배포 되어있는 게임의 고유 번호입니다. 로그 DB나 게임별로 분기를 태울때, 사용하게 됩니다.">
                        <Form.Item
                            label="GameId"
                            name="globalId"
                            rules={formValidator.getRules('create_project_game_id')}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title="프로젝트가 바라보는 게임의 환경을 나타나게 됩니다.">
                        <Form.Item label="환경" name="environment" rules={formValidator.getRules('create_project_env')}>
                            <Select>
                                <Select.Option value="prod">prod</Select.Option>
                                <Select.Option value="qa">qa</Select.Option>
                                <Select.Option value="dev">dev</Select.Option>
                            </Select>
                        </Form.Item>
                    </Tooltip>
                    <Form.Item label="시트ID" name="driveUrl">
                        <Input />
                    </Form.Item>
                    <Form.Item label="서버URL" name="serverUrl">
                        <Input />
                    </Form.Item>

                    {projectDetail && (
                        <div className={cx('footer')}>
                            <div className={cx('dateString')}>
                                <p>Created: {moment(projectDetail.createdAt).format('YYYY-MM-DD')} </p>
                                <p>{projectDetail?.createdBy && `(${projectDetail.createdBy})`}</p>
                            </div>

                            <div className={cx('dateString')}>
                                <p>Updated: {moment(projectDetail.updatedAt).format('YYYY-MM-DD')} </p>
                                <p>{projectDetail?.updatedBy && `(${projectDetail.updatedBy})`}</p>
                            </div>
                        </div>
                    )}
                    <div className={cx('buttonWrapper')}>
                        <Form.Item>
                            <Button className={cx('button')} type="primary" htmlType="submit">
                                제출
                            </Button>
                        </Form.Item>
                    </div>
                </EnterIgnoreForm>
                {projectDetail && (
                    <article className={cx('permissionWrapper')}>
                        <ProjectPermissionEdit
                            project={projectDetail}
                            serviceAuthList={serviceAuthList}
                            refetch={refetch}
                        />
                    </article>
                )}
            </section>
        </ModalWrapper>
    );
};

export default ProjectCreateModal;
