import { css } from '@emotion/react';
import { WebviewNoticeDto } from '@gamebase-ops/interfaces/notice/dto';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NOTICE_STATE, NOTICE_STATE_TEXT } from 'constants/constants';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { findTargetElementByType } from 'utils/eventListener';
import { getLanguage } from 'utils/getLanguage';

interface IProp {
    data: WebviewNoticeDto[];
    totalCount: number;
    currentPage: number;
    setPage: (page: number) => void;
    onAction?: (action: string, id: string) => void;
    hasWritePermission: boolean;
}

export default function WebviewTableView({
    data,
    totalCount,
    currentPage,
    setPage,
    onAction,
    hasWritePermission,
}: IProp) {
    const [lang, country] = getLanguage();
    const columns = useMemo<ColumnsType<WebviewNoticeDto>>(() => {
        return [
            {
                title: '제목',
                dataIndex: 'titles',
                render(values: Record<string, string>) {
                    return values[lang] || values[country];
                },
            },
            {
                title: '대문이미지',
                dataIndex: 'mainImages',
                render(values: Record<string, string>) {
                    if (values) return <img css={mainImageStyle} src={values[lang] || values[country]} />;
                },
            },
            {
                title: '상태',
                dataIndex: 'state',
                render(state: NOTICE_STATE) {
                    return NOTICE_STATE_TEXT[state];
                },
            },
            {
                title: '기간',
                render(_, { startTime, endTime }) {
                    return `${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(endTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )}`;
                },
            },
            {
                title: '마지막 업데이트',
                render(_, { updatedAt, updatedBy }) {
                    return `${moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')} (${updatedBy})`;
                },
            },
            {
                title: '',
                render(_, { id, state }) {
                    if (!hasWritePermission) return null;
                    return (
                        <section css={actionWrapper}>
                            {state === NOTICE_STATE.WRITE_SUCCESS ? (
                                <Button data-type="action" data-value="unpublish" data-id={id} danger>
                                    중지
                                </Button>
                            ) : (
                                <Button data-type="action" data-value="publish" data-id={id} type="primary">
                                    배포
                                </Button>
                            )}
                            <Button data-type="action" data-value="confirm" data-id={id}>
                                확인
                            </Button>
                            <Button data-type="action" data-value="copy" data-id={id}>
                                복사
                            </Button>
                        </section>
                    );
                },
            },
        ];
    }, [lang, country, hasWritePermission]);

    const tableClickListener = useCallback(
        (e) => {
            const target = findTargetElementByType(e, 'action');

            if (target) {
                onAction && onAction(target.dataset.value as string, target.dataset.id as string);
            }
        },
        [onAction],
    );

    return (
        <section onClick={tableClickListener}>
            <Table
                columns={columns}
                dataSource={data.map((data) => ({
                    ...data,
                    key: data.id,
                }))}
                pagination={{ total: totalCount, current: currentPage, onChange: setPage, pageSize: 15 }}
            />
        </section>
    );
}

const mainImageStyle = css`
    max-width: 330px;
`;

const actionWrapper = css`
    display: flex;
    button + button {
        margin-left: 8px;
    }
`;
