import { UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button } from 'antd';
import { InputHTMLAttributes, useCallback, useRef, useState } from 'react';

export default function FileUpload(props: Omit<InputHTMLAttributes<HTMLInputElement>, 'style' | 'className' | 'type'>) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [names, setNames] = useState<string[]>([]);

    const buttonClickListener = useCallback(() => {
        inputRef.current?.click();
    }, []);

    return (
        <section>
            <Button onClick={buttonClickListener} icon={<UploadOutlined />}>
                File Upload
            </Button>
            <input
                ref={inputRef}
                css={inputHidden}
                {...props}
                onChange={(e) => {
                    if (e.target.files) {
                        const files = [...e.target.files];
                        setNames(files.map((file) => file.name));
                        if (props.onChange) props.onChange(e);
                    }
                }}
                type="file"
            />
            <ul>
                {names.map((name, index) => (
                    <li key={index}>{name}</li>
                ))}
            </ul>
        </section>
    );
}

const inputHidden = css`
    display: none;
`;
