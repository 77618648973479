import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import { RolePage, MyPage } from 'pages';
import { AUTH_LEVEL, COMMON_PROJECT_ID } from 'constants/constants';

const MyPageRoute = () => {
    return (
        <Switch>
            <Route
                path="/myPage/roles"
                render={() => (
                    <PageAuthViewController projectId={COMMON_PROJECT_ID} level={AUTH_LEVEL.WRITE} service="role">
                        <RolePage />
                    </PageAuthViewController>
                )}
            />
            <Route
                path="/myPage"
                exact
                render={() => (
                    <PageAuthViewController>
                        <MyPage />
                    </PageAuthViewController>
                )}
            />
        </Switch>
    );
};

export default MyPageRoute;
