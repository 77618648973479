import SideBar from './SideBar';
import classNames from 'classnames/bind';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

const sideBarMenu: ILinkProps[] = [
    {
        title: '프로젝트, 서비스',
        link: '/admin/projects/serviceAuth',
    },
];

const AdminLayout: React.FC = ({ children }) => {
    return (
        <section className={cx('mainWrapper')}>
            <SideBar sideBarMenu={sideBarMenu} />
            <section className={cx('contentMain')}>{children}</section>
        </section>
    );
};

export default AdminLayout;
