import { useCallback } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { googleLoginClientId } from 'constants/constants';

interface IProps {
    onSuccessGoogleLogin?: (payload: GoogleLoginPayload) => void;
}

const GoogleLoginButton: React.FC<IProps> = ({ onSuccessGoogleLogin }) => {
    const onSuccess = useCallback(
        (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
            const {
                accessToken,
                profileObj: { email },
            } = response as GoogleLoginResponse;
            if (onSuccessGoogleLogin && accessToken) {
                onSuccessGoogleLogin({ accessToken, email });
            }
        },
        [onSuccessGoogleLogin],
    );

    return <GoogleLogin clientId={googleLoginClientId} onSuccess={onSuccess} buttonText="Sign In 111% Email" />;
};

export default GoogleLoginButton;
