import { Form, Input, Button, List, Avatar, InputNumber } from 'antd';
import classNames from 'classnames/bind';
import { notImage } from 'constants/constants';
import { ISendMailItem } from 'dto/mail.dto';
import { useAllItems, useQueries } from 'hooks';
import { getItemName } from 'utils/item';
import styles from './ItemFormList.module.scss';

import ItemSearch from './ItemSearch';

const cx = classNames.bind(styles);

interface IProp {
    items: ISendMailItem[];
    addItems: (param: ISendMailItem) => void;
    deleteItem: (index: number) => void;
}

const ItemFormList: React.FC<IProp> = ({ items, addItems, deleteItem }) => {
    const { projectId } = useQueries();
    const { itemHash } = useAllItems(projectId);

    return (
        <div>
            <ItemSearch
                addItems={(item) => {
                    if (items.find((it) => it.itemId === item.itemId)) {
                        alert('같은 아이템을 두 개 보낼 수 없습니다.');
                        return;
                    }
                    addItems(item);
                }}
            />

            {items.map((item, index) => (
                <div key={`${item.itemId}`} className={cx('wrapper')}>
                    <div style={{ display: 'none' }}>
                        <Form.Item>
                            <Input type="hidden" name={`items.${index}.itemId`} defaultValue={item.itemId} />
                        </Form.Item>
                    </div>
                    <List.Item className={cx('itemWrapper')}>
                        <List.Item.Meta
                            avatar={<Avatar src={notImage} />}
                            title={
                                <div className={cx('itemInputWrapper')}>
                                    <div className={cx('itemName')}>
                                        No.{item.itemId}({itemHash[item.itemId] && getItemName(itemHash[item.itemId])})
                                    </div>

                                    <Form.Item className={cx('itemInputWrapper')} style={{ marginBottom: 0 }}>
                                        <InputNumber name={`items.${index}.amount`} defaultValue={item.amount} />
                                    </Form.Item>
                                </div>
                            }
                        />

                        <Button type="primary" danger onClick={() => deleteItem(index)}>
                            삭제
                        </Button>
                    </List.Item>
                </div>
            ))}
        </div>
    );
};

export default ItemFormList;
