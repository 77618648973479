import { useQuery } from 'react-query';
import { MaintenanceRepository } from 'repository';

export default function useMaintenanceListModel(projectId: string) {
    const queries = useQuery(['maintenanceList', projectId], () =>
        projectId ? MaintenanceRepository.listSchedule({ query: { projectId, page: 1, limit: 50 } }) : null,
    );

    return {
        ...queries,
    };
}
