import { ProjectPassDto } from 'dto/projectPass.dto';
import { get } from 'utils/api/client';

const url = '/api/v1/projectPass';

class ProjectPassRepository {
    find(param: ProjectPassDto) {
        return get<IProjectPass[]>(url, new ProjectPassDto(param));
    }
}

export default new ProjectPassRepository();
