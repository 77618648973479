import { useCallback } from 'react';
import { GoogleLoginButton, LoginForm } from 'components';
import { useAuth, useFormatMessage } from 'hooks';

import classNames from 'classnames/bind';
import styles from './Login.module.scss';

const cx = classNames.bind(styles);

const Login: React.FC = () => {
    const { messageFormatting } = useFormatMessage();
    const { googleLoginRequest } = useAuth();

    const onSuccessGoogleLogin = useCallback(
        (params: GoogleLoginPayload) => {
            googleLoginRequest(params);
        },
        [googleLoginRequest],
    );

    return (
        <div className={cx('wrapper')}>
            <div className={cx('content')}>
                <h1 className={cx('title')}>{messageFormatting('GAME_TOOL_LOGIN')}</h1>
                <LoginForm />
                <div className={cx('staff')}>{messageFormatting('DID_YOU_111PERCENT')}</div>
                <GoogleLoginButton onSuccessGoogleLogin={onSuccessGoogleLogin} />
            </div>
        </div>
    );
};

export default Login;
