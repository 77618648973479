import { Result } from 'antd';

import { useQueries } from 'hooks';

import useProjectDetail from 'hooks/project/useProjectDetail';
import games from './games';

function AccountMerge() {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    if (!projectId || !projectDetail || !games[projectDetail.globalId])
        return <Result title="계정이전을 지원하지 않는 게임입니다." />;

    const Component = games[projectDetail.globalId];
    return (
        <div>
            <Component />
        </div>
    );
}

export default AccountMerge;
