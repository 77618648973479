import useFormatMessage from 'hooks/lang/useFormatMessage';
import React, { useMemo } from 'react';
import FormValidator from 'utils/FormValidator.class';

interface UseFormRules {
    formValidator: FormValidator;
}
const useFormRules = (): UseFormRules => {
    const { messageFormatting } = useFormatMessage();
    const formValidator = useMemo<FormValidator>(() => {
        const validator = new FormValidator();
        const rules = [
            {
                key: 'account_id',
                rules: [
                    { required: true, message: 'Id is required' },
                    { max: 255, message: 'Id must be maximum 255 charaters.' },
                ],
            },
            {
                key: 'account_name',
                rules: [{ required: true, message: 'Name is required.' }],
            },
            {
                key: 'account_description',
                rules: [{ required: true, message: 'Description is required.' }],
            },
            {
                key: 'account_password',
                rules: [
                    { required: true, message: messageFormatting('PLEASE_INPUT_PASSWORD') },
                    { max: 255, message: 'Password must be maximum 255 charaters.' },
                ],
            },
            {
                key: 'account_confirm_password',
                rules: [
                    { required: true, message: messageFormatting('PLEASE_INPUT_CONFIRM') },
                    { max: 255, message: 'Password must be maximum 255 charaters.' },
                ],
            },
            {
                key: 'notice_title',
                rules: [{ required: true, message: messageFormatting('PLEASE_INPUT_TITLE') }],
            },
            {
                key: 'notice_language',
                rules: [{ required: true, message: messageFormatting('NOTICE_FIRST_LANGUAGE_MESSAGE') }],
            },
            {
                key: 'create_project_game',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'create_project_game_id',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'create_project_env',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'create_service_name',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'create_service_value',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'create_service_auth',
                rules: [{ required: true, message: '필수 입니다.' }],
            },
            {
                key: 'picker_date',
                rules: [{ required: true, message: 'Date is required.' }],
            },
            {
                key: 'picker_time',
                rules: [{ required: true, message: 'Time is required.' }],
            },
        ];

        rules.forEach((rule) => {
            validator.setRules(rule.key, rule.rules);
        });

        return validator;
    }, [messageFormatting]);

    return { formValidator };
};

export default useFormRules;
