import { Dropdown, Menu, Select } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './ManageMentMenu.module.scss';
import { useAuth, useLang } from 'hooks';
import { PageAuthViewController } from 'provider';
import { AUTH_LEVEL, COMMON_PROJECT_ID } from 'constants/constants';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProp extends IAuthPageProps {
    isAdmin: boolean;
    username: string;
    auths?: IAuthCheck[];
}

const cx = classNames.bind(styles);

const menuNameKey: { [key: string]: string } = {
    account: '사이트 계정관리',
    role: '역할 관리',
};

const ManageMentMenu: React.FC<IProp> = ({ auths, isAdmin, username }) => {
    const { logoutRequest } = useAuth();
    const { lang, langChange } = useLang();

    return (
        <section className={cx('infoWrapper')}>
            <span className={cx('hellow')}>
                <FormattedMessage
                    id="HEADER_HELLO"
                    values={{
                        name: username,
                    }}
                />
            </span>
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="mypage">
                            <Link to="/myPage" className={cx('settingOption')}>
                                <FormattedMessage id="MYPAGE" />
                            </Link>
                        </Menu.Item>
                        {auths
                            ?.filter((auth) => auth.level === AUTH_LEVEL.READ)
                            .map((auth) => (
                                <Menu.Item key={auth.service}>
                                    <Link to={`/${auth.service}s`} className={cx('settingOption')}>
                                        {menuNameKey[auth.service as string]}
                                    </Link>
                                </Menu.Item>
                            ))}

                        {isAdmin && (
                            <Menu.Item key="site-admin">
                                <Link to="/admin/projects/ServiceAuth" className={cx('settingOption')}>
                                    사이트 관리
                                </Link>
                            </Menu.Item>
                        )}
                        <Menu.Item key="logout" onClick={logoutRequest} className={cx('settingOption')}>
                            <FormattedMessage id="LOGOUT" />
                        </Menu.Item>
                    </Menu>
                }
            >
                <div className={cx('settings')}>
                    <FormattedMessage id="MANAGEMENT" />
                </div>
            </Dropdown>
            <div style={{ marginLeft: '16px' }}>
                <Select value={lang} onChange={(language) => langChange(language)}>
                    <Select.Option key="ko" value="ko">
                        KOREA
                    </Select.Option>
                    <Select.Option key="ja" value="ja">
                        JAPAN
                    </Select.Option>
                </Select>
            </div>
        </section>
    );
};

const ManageMentInject = (props: IProp) => {
    return (
        <PageAuthViewController service="account" level={AUTH_LEVEL.READ} projectId={COMMON_PROJECT_ID}>
            <PageAuthViewController service="role" level={AUTH_LEVEL.READ} projectId={COMMON_PROJECT_ID}>
                <ManageMentMenu {...props} />
            </PageAuthViewController>
        </PageAuthViewController>
    );
};

export default memo(ManageMentInject);
