import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';
import { Form, Input, Select, Button } from 'antd';
import { ModalWrapper } from 'components';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import { useFormRules } from 'hooks';
import { useCallback } from 'react';

interface IProp extends ModalWrapperProps {
    onSubmit?: (arg: IChangeServiceAuthDto | ICreateServiceAuthDto) => void;
    initialValues?: ServiceAuthDto;
}

const ServiceAuthModal: React.FC<IProp> = ({ visible, onCancel, onSubmit, title, initialValues }) => {
    const { formValidator } = useFormRules();
    const finishListener = useCallback(
        (arg: ServiceAuthDto) => {
            if (onSubmit) {
                onSubmit(arg);
            }
        },
        [onSubmit],
    );

    return (
        <ModalWrapper visible={visible} onCancel={onCancel} title={title}>
            <EnterIgnoreForm initialValues={initialValues} onFinish={finishListener}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input type="hidden" style={{ display: 'none' }} />
                </Form.Item>
                <Form.Item name="description" label="서비스명" rules={formValidator.getRules('create_service_name')}>
                    <Input />
                </Form.Item>
                <Form.Item name="service" label="서비스" rules={formValidator.getRules('create_service_value')}>
                    <Input />
                </Form.Item>

                <Form.Item name="level" label="권한" rules={formValidator.getRules('create_service_auth')}>
                    <Select>
                        <Select.Option value={1}>Read</Select.Option>
                        <Select.Option value={2}>Write</Select.Option>
                    </Select>
                </Form.Item>
                <Button htmlType="submit" type="primary">
                    제출
                </Button>
            </EnterIgnoreForm>
        </ModalWrapper>
    );
};

export default ServiceAuthModal;
