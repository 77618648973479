import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';
import { List, Button } from 'antd';

import classNames from 'classnames/bind';
import { useModal } from 'hooks';
import { useCallback } from 'react';
import { useState } from 'react';
import lStyle from './ListItem.module.scss';
import ServiceAuthModal from './ServiceAuthModal';

const lx = classNames.bind(lStyle);

interface IProp {
    serviceAuthList?: ServiceAuthDto[];
    createServiceAuth: (param: ICreateServiceAuthDto) => Promise<void>;
    changeServiceAuth: (param: IChangeServiceAuthDto) => Promise<void>;
    deleteServiceAuth: (param: string) => Promise<boolean>;
}

const ServiceAuthList: React.FC<IProp> = ({
    serviceAuthList,
    createServiceAuth,
    changeServiceAuth,
    deleteServiceAuth,
}) => {
    const [selectValue, setSelectValue] = useState<ServiceAuthDto>();

    const { visible, openModal, closeModal } = useModal();

    const closeListener = useCallback(() => {
        setSelectValue(undefined);
        closeModal();
    }, [closeModal]);

    const deleteClickListener = useCallback(
        async (param: ServiceAuthDto) => {
            if (param.id) await deleteServiceAuth(param.id);
        },
        [deleteServiceAuth],
    );

    const itemClickListener = useCallback(
        (item: ServiceAuthDto) => {
            openModal();
            setSelectValue(item);
        },
        [openModal],
    );

    const modalSubmitListener = useCallback(
        async (param: IChangeServiceAuthDto | ICreateServiceAuthDto) => {
            if (param.id) {
                await changeServiceAuth({ ...param, oid: param.id });
            } else {
                await createServiceAuth({ ...param });
            }
            closeListener();
        },
        [changeServiceAuth, createServiceAuth, closeListener],
    );

    return (
        <div className={lx('wrapper')}>
            {visible && (
                <ServiceAuthModal
                    visible={visible}
                    onCancel={closeListener}
                    onSubmit={modalSubmitListener}
                    title={selectValue ? '서비스 수정' : '서비스 추가'}
                    initialValues={selectValue}
                />
            )}
            <Button onClick={openModal}>CreateService</Button>
            <List
                dataSource={serviceAuthList}
                renderItem={(item) => (
                    <List.Item className={lx('item')} key={item.id}>
                        <section onClick={() => itemClickListener(item)}>
                            <span>{item.description} </span>
                            <Button
                                type="primary"
                                danger
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteClickListener(item);
                                }}
                            >
                                삭제
                            </Button>
                        </section>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ServiceAuthList;
