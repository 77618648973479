import { css } from '@emotion/react';
import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';
import { MailTemplateDto } from '@gamebase-ops/interfaces/mailTemplate/dto';
import { Input, Tabs } from 'antd';
import { useFormatMessage } from 'hooks';
import { useCallback } from 'react';
import { findMailTextCanInput } from 'utils/regExp';

interface IProp {
    mailTemplate: MailTemplateDto | null;
    defaultValue?: SystemMailDto | MailDto;
    languages?: string[];
}

const MailTextFormList: React.FC<IProp> = ({ mailTemplate, defaultValue, languages }) => {
    const { messageFormatting } = useFormatMessage();
    const getTitle = useCallback(
        (lang: string) => {
            return mailTemplate?.titles[lang];
        },
        [mailTemplate],
    );

    const getText = useCallback(
        (lang: string) => {
            return mailTemplate?.texts[lang];
        },
        [mailTemplate],
    );

    const getSender = useCallback(
        (lang: string) => {
            return mailTemplate?.senders[lang];
        },
        [mailTemplate],
    );
    return (
        <Tabs>
            {languages?.map((lang) => (
                <Tabs.TabPane key={lang} tab={lang} tabKey={lang} forceRender>
                    <section css={mailTextSection}>
                        <h3>{messageFormatting('TITLE')}</h3>
                        {getTitle(lang) && (
                            <>
                                <header css={mailPreviewText}>{getTitle(lang)}</header>
                                {findMailTextCanInput.test(getTitle(lang) || '') && (
                                    <Input
                                        name={`titles.${lang}`}
                                        defaultValue={defaultValue?.titles && (defaultValue?.titles[lang] as string)}
                                    />
                                )}
                            </>
                        )}
                    </section>
                    <section css={mailTextSection}>
                        <h3>{messageFormatting('SENDER')}</h3>
                        {getSender(lang) && (
                            <>
                                <header css={mailPreviewText}>{getSender(lang)}</header>
                                {findMailTextCanInput.test(getSender(lang) || '') && (
                                    <Input
                                        name={`senders.${lang}`}
                                        defaultValue={defaultValue?.senders && (defaultValue?.senders[lang] as string)}
                                    />
                                )}
                            </>
                        )}
                    </section>
                    <section css={mailTextSection}>
                        <h3>{messageFormatting('CONTENT')}</h3>
                        {getText(lang) && (
                            <>
                                <header css={mailPreviewText}>{getText(lang)}</header>
                                {findMailTextCanInput.test(getText(lang) || '') && (
                                    <Input
                                        name={`texts.${lang}`}
                                        defaultValue={defaultValue?.texts && (defaultValue?.texts[lang] as string)}
                                    />
                                )}
                            </>
                        )}
                    </section>
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
};

const mailPreviewText = css`
    margin-bottom: 8px;
    font-size: 14px;
`;

const mailTextSection = css`
    margin-bottom: 64px;
`;

export default MailTextFormList;
