import { failIcon } from 'icons';
import classNames from 'classnames/bind';
import styles from './UserBadge.module.scss';
import { useAccount } from 'hooks';
import { useCallback } from 'react';
import { AccountBasicDto } from '@gamebase-ops/interfaces/account';

interface IProp {
    account: Pick<AccountBasicDto, 'id' | 'name' | 'userId'>;
    onClick?: (account: Pick<AccountBasicDto, 'id' | 'name' | 'userId'>) => void;
}

const cx = classNames.bind(styles);

const UserBadge: React.FC<IProp> = ({ account, onClick }) => {
    const { account: myAccount } = useAccount();

    const badgeClickListener = useCallback(
        (account: Pick<AccountBasicDto, 'id' | 'name' | 'userId'>) => {
            if (myAccount.account.id === account.id) {
                if (!window.confirm('본인의 계정을 이 권한에서 지우시겠습니까?')) return;
            }
            onClick && onClick(account);
        },
        [myAccount, onClick],
    );

    return (
        <div onClick={() => badgeClickListener(account)} className={cx('wrapper')}>
            {account.name}
            <div className={cx('badge')}>
                <img className={cx('icon')} src={failIcon} />
            </div>
        </div>
    );
};

export default UserBadge;
