import { del, get, patch, post } from 'utils/api/client';

import { IPermissionRepository } from '@gamebase-ops/interfaces/permission/interface';
import {
    PermissionCreateRequest,
    PermissionUpdateRequest,
    PermissionInfoRequest,
    PermissionInfoResponse,
    PermissionListRequest,
    PermissionListResponse,
    PermissionDeleteRequest,
} from '@gamebase-ops/interfaces/permission';
import { Body, BaseResponse, Query } from '@gamebase-ops/interfaces/utils';

const baseUrl = '/api/v1/permissions';
class PermissionRepository implements IPermissionRepository {
    delete(param: Query<PermissionDeleteRequest>): Promise<BaseResponse> {
        return del(baseUrl, new PermissionDeleteRequest(param.query));
    }
    create(param: Body<PermissionCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl, new PermissionCreateRequest(param.body));
    }
    update(param: Body<PermissionUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new PermissionUpdateRequest(param.body));
    }
    info(param: Query<PermissionInfoRequest>): Promise<PermissionInfoResponse> {
        return get('/info', new PermissionInfoRequest(param.query));
    }
    list(param: Query<PermissionListRequest>): Promise<PermissionListResponse> {
        return get('/list', new PermissionListRequest(param.query));
    }

    findBy(...params: any) {
        return [];
    }
}

export default new PermissionRepository();
