import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { OS } from 'constants/constants';

interface IProp {
    os: OS;
    style?: React.CSSProperties;
}

const OsIcon: React.FC<IProp> = ({ os, style }) => {
    if (os === OS.iOS) return <AppleFilled style={style} />;
    if (os === OS.Android) return <AndroidFilled style={style} />;
    return null;
};

export default OsIcon;
