import { css } from '@emotion/react';
import { INoticeProp, NoticeEditor } from '@gamebase-ops/editor';

import { useWebviewRender } from 'hooks';
import { useEffect } from 'react';

interface IProp extends Omit<INoticeProp, 'defaultValue'> {
    baseName: string;
    defaultValueUrl?: string;
    isLinkMode?: boolean;
    onChange: (lang: string) => (text: string) => void;
    lang: string;
}

export default function WebviewEditor({ defaultValueUrl, onChange, onImageUpload, lang }: IProp) {
    const { contents: defaultValue } = useWebviewRender(defaultValueUrl);

    useEffect(() => {
        if (defaultValue) {
            onChange?.(lang)(defaultValue);
        } else {
            onChange?.(lang)('');
        }
    }, [defaultValue, onChange, lang]);

    if (defaultValueUrl && !defaultValue) return null;

    return (
        <section css={wrapperStyle}>
            <NoticeEditor onChange={onChange(lang)} onImageUpload={onImageUpload} defaultValue={defaultValue} />
        </section>
    );
}

const wrapperStyle = css`
    border: 1px solid #aaa;
    position: relative;
    z-index: 1001;
`;
