import {
    IServiceRepository,
    ServiceAuthAllRequest,
    ServiceAuthAllResponse,
    ServiceAuthCreateRequest,
    ServiceAuthDeleteRequest,
    ServiceAuthInfoRequest,
    ServiceAuthInfoResponse,
    ServiceAuthListRequest,
    ServiceAuthListResponse,
    ServiceAuthUpdateRequest,
} from '@gamebase-ops/interfaces/serviceAuth';
import { Body, Query } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils/response';
import { patch, post, get, del } from 'utils/api/client';

const baseUrl = '/api/v1/service-auths';

class ServiceRepository implements IServiceRepository {
    delete(param: Query<ServiceAuthDeleteRequest>): Promise<BaseResponse> {
        return del(baseUrl, new ServiceAuthDeleteRequest(param.query));
    }
    all(param: Query<ServiceAuthAllRequest>): Promise<ServiceAuthAllResponse> {
        return get(baseUrl + '/all', new ServiceAuthAllRequest(param.query));
    }
    create(param: Body<ServiceAuthCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl, new ServiceAuthCreateRequest(param.body));
    }
    update(param: Body<ServiceAuthUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new ServiceAuthUpdateRequest(param.body));
    }
    info(param: Query<ServiceAuthInfoRequest>): Promise<ServiceAuthInfoResponse> {
        return get('/info', new ServiceAuthInfoRequest(param.query));
    }
    list(param: Query<ServiceAuthListRequest>): Promise<ServiceAuthListResponse> {
        return get('/list', new ServiceAuthListRequest(param.query));
    }
}

export default new ServiceRepository();
