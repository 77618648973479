import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import { AccountDetail, Account } from 'pages';
import { AUTH_LEVEL, COMMON_PROJECT_ID } from 'constants/constants';

const AccountRoute = () => {
    return (
        <Switch>
            <Route
                path="/accounts/:id"
                render={() => (
                    <PageAuthViewController projectId={COMMON_PROJECT_ID} service="account" level={AUTH_LEVEL.WRITE}>
                        <AccountDetail />
                    </PageAuthViewController>
                )}
            />
            <Route
                exact
                path="/accounts"
                render={() => (
                    <PageAuthViewController projectId={COMMON_PROJECT_ID} service="account" level={AUTH_LEVEL.WRITE}>
                        <Account />
                    </PageAuthViewController>
                )}
            />
        </Switch>
    );
};

export default AccountRoute;
