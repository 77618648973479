import { useQuery } from 'react-query';
import { NoticeRepository } from 'repository';

export default function usePublishedWebviewNoticeListModel(projectId: string) {
    const queries = useQuery(['publishedWebviewNotice', projectId], () =>
        NoticeRepository.listOfDeployedWebviewNotice({ query: { projectId } }),
    );

    return {
        ...queries,
    };
}
