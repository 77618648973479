import {
    ArcadeScheduleListRequest,
    ArcadeScheduleListResponse,
    ArcadeRewardListRequest,
    ArcadeRewardListResponse,
} from '@gamebase-ops/interfaces/gameArcade';
import { IGameArcadeRepository } from '@gamebase-ops/interfaces/gameArcade/interface';
import { Query } from '@gamebase-ops/interfaces/utils';
import { ArcadePassRewardQueryDto } from 'dto/arcade.dto';
import { get } from 'utils/api/client';

const baseURL = '/api/v1/arcade';
const baseUrl = '/api/v1/gamearcades';

class ArcadeRepository implements IGameArcadeRepository {
    schedules(request: Query<ArcadeScheduleListRequest>): Promise<ArcadeScheduleListResponse> {
        return get(baseUrl + '/schedules', new ArcadeScheduleListRequest(request.query));
    }
    rewards(request: Query<ArcadeRewardListRequest>): Promise<ArcadeRewardListResponse> {
        return get(baseUrl + '/rewards', new ArcadeRewardListRequest(request.query));
    }

    getArcadeBase(projectId: number) {
        return get<any>(`${baseURL}/base`, { projectId });
    }

    getArcadePassReward(param: ArcadePassRewardQueryDto) {
        return get<any>(`${baseURL}/reward`, new ArcadePassRewardQueryDto(param));
    }
}

export default new ArcadeRepository();
