import { css } from '@emotion/react';
import DescriptionHeader from 'components/atoms/DescriptionHeader';

interface IProp {
    children?: string | JSX.Element | number;
    title: string;
}

export default function UserDescription({ title, children }: IProp) {
    return (
        <article>
            <DescriptionHeader>{title}</DescriptionHeader>
            <div css={childrenWrapper}>{children}</div>
        </article>
    );
}

const childrenWrapper = css`
    padding: 8px 8px;
    text-align: center;
    margin-bottom: 16px;
`;
