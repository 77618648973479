import { get } from 'utils/api/client';
import { IVoidedPurchaseLogRepository } from '@gamebase-ops/interfaces/voidedPurchaseLog/interface';
import { Query, BaseResponse } from '@gamebase-ops/interfaces/utils';
import {
    VoidedPurchaseLogSyncRequest,
    VoidedPurchaseLogListRequest,
} from '@gamebase-ops/interfaces/voidedPurchaseLog/request';
import { VoidedPurchaseLogListResponse } from '@gamebase-ops/interfaces/voidedPurchaseLog/response';

const baseUrl = '/api/v1/voidedpurchase-logs';

class VoidedPurchasesRepository implements IVoidedPurchaseLogRepository {
    sync(param: Query<VoidedPurchaseLogSyncRequest>): Promise<BaseResponse> {
        throw new Error('Method not implemented.');
    }
    list(param: Query<VoidedPurchaseLogListRequest>): Promise<VoidedPurchaseLogListResponse> {
        return get(baseUrl + '/list', new VoidedPurchaseLogListRequest(param.query));
    }
}

export default new VoidedPurchasesRepository();
