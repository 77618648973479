import useAccountListModel from './model/useAccountList.model';

export default function useAccounts() {
    const PAGE_SIZE = 15;

    const { data, setFirstPageOrRefetch, setPage, query, requestAccountsByKeyword } = useAccountListModel(PAGE_SIZE);

    return {
        accounts: data?.result.data || [],
        page: query?.page,
        setPage,
        totalCount: data?.result.totalCount,
        setFirstPageOrRefetch,
        requestAccountsByKeyword,
        PAGE_SIZE,
    };
}
