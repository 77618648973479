import { get, post } from 'utils/api/client';
import { IBlockUserRepository } from '@gamebase-ops/interfaces/blockUser/interface';
import { BlockUserBanRequest, BlockUserListRequest, BlockUserListResponse } from '@gamebase-ops/interfaces/blockUser';
import { Query, Auth, Body, BaseResponse } from '@gamebase-ops/interfaces/utils';

const baseURL = `/api/v1/gameblockusers`;

class BlockUserRepository implements IBlockUserRepository {
    upload(param: Query<Auth> & Body<FormData>): Promise<BaseResponse> {
        return post(baseURL + '/upload', param.body, new Auth(param.query));
    }
    ban(param: Query<BlockUserBanRequest>): Promise<BaseResponse> {
        throw new Error('Method not implemented.');
    }
    list(param: Query<BlockUserListRequest>): Promise<BlockUserListResponse> {
        return get(baseURL + '/list', new BlockUserListRequest(param.query));
    }
}

export default new BlockUserRepository();
