export const Lang = {
    set: '@lang/set',
};

export const setLang = (payload: string) => {
    return {
        type: Lang.set,
        payload,
    };
};
