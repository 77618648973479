import { IsNotEmpty, IsString } from 'class-validator';

export class EditPermissionRequestDto {
    constructor(prop: EditPermissionRequestDto) {
        this.name = prop.name;
        this.description = prop.description;
        this.project = prop.project;
        this.serviceAuth = prop.serviceAuth;
    }

    @IsString()
    @IsNotEmpty()
    name: string;

    @IsString()
    @IsNotEmpty()
    description: string;

    @IsString()
    @IsNotEmpty()
    project: string;

    @IsString()
    @IsNotEmpty()
    serviceAuth: string;
}
