import { notificationPush } from './notificationUtils';

export default function clipboardCopy(text: string, formatter: Function) {
    const tmpElement = document.createElement('textarea');
    tmpElement.value = text;
    document.body.append(tmpElement);
    tmpElement.select();

    document.execCommand('copy');
    document.body.removeChild(tmpElement);
    notificationPush({
        status: 'info',
        message: formatter('CLIPBOARD_COPY_COMPLETE'),
    });
}
