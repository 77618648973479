import { Table, Button, Modal, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NOTICE_STATE_TEXT, NOTICE_STATE, NOTICE_KEY } from 'constants/constants';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';
import { NoticeRepository } from 'repository';
import { css } from '@emotion/react';
interface INoticeTable {
    data?: any[];
    openStep: (data: any, stepType: 'detail' | 'edit' | 'copy') => void;
    getNotice: () => void;
    projectId: string;
    pagination: TablePaginationConfig | undefined;
    type: string;
    hasWritePermission: boolean;
}

const getPublishDisabled = (state: number, endDay: string) => {
    if (state === NOTICE_STATE.WRITE_SUCCESS) return true;

    const endDate = new Date(endDay);
    const nowDate = new Date();

    if (endDate.valueOf() < nowDate.valueOf()) return true;

    return false;
};

function NoticeTable({ data, openStep, getNotice, projectId, pagination, type, hasWritePermission }: INoticeTable) {
    const { messageFormatting } = useFormatMessage();

    const columns: ColumnsType<INotice> = [
        {
            title: messageFormatting('CREATED_AT'),
            dataIndex: 'expouseData',
            key: 'expouseData',
            render(_, { createdAt }) {
                return `${moment(createdAt).format('YYYY-MM-DD HH:mm')}`;
            },
        },
        {
            title: messageFormatting('TITLE'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: messageFormatting('AUTHOR'),
            dataIndex: 'createdBy',

            render(_, { createdBy }) {
                return createdBy;
            },
        },
        {
            title: messageFormatting('EXPOSURE_PERIOD'),
            dataIndex: 'endTime',
            key: 'exposureDate',
            render(_, { startTime, endTime }) {
                return (
                    <div>
                        <div>{moment(startTime).format('YYYY-MM-DD HH:mm')}</div>
                        <div>{moment(endTime).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                );
            },
        },

        {
            title: messageFormatting('LANGUAGE'),
            dataIndex: 'language',
            key: 'language',
            render(_, { messages = {} }) {
                return Object.keys(messages).join(' / ');
            },
        },
        {
            title: messageFormatting('STATUS'),
            dataIndex: 'state',
            key: 'state',
            render: (state: number) => NOTICE_STATE_TEXT[state as NOTICE_STATE],
        },
        {
            key: 'publish',
            render(_, { id, state, endTime }) {
                return (
                    <Button
                        type="primary"
                        onClick={() => publishConfirm(id)}
                        disabled={getPublishDisabled(state, endTime) || !hasWritePermission}
                    >
                        {messageFormatting('PUBLISH')}
                    </Button>
                );
            },
        },
        {
            key: 'delete',
            render(_, { state, projectId, id }) {
                if (!hasWritePermission) return null;
                return (
                    <Button
                        type="primary"
                        danger
                        data-type="delete"
                        onClick={() => unpublishNotice(projectId, id)}
                        disabled={state !== NOTICE_STATE.WRITE_SUCCESS}
                    >
                        {messageFormatting('STOP')}
                    </Button>
                );
            },
        },
        {
            key: 'edit',
            render(_, data) {
                if (!hasWritePermission) return null;
                const handleClick = () => {
                    const { id, state, messages, startTime, endTime, title, defaultLanguage = 'en', titles } = data;
                    openStep(
                        {
                            ...data,
                            id: id,
                            noticeState: state,
                            messages,
                            startTime,
                            endTime,
                            title,
                            defaultLanguage,
                            titles,
                        },
                        'edit',
                    );
                };
                return (
                    <Button
                        onClick={() => handleClick()}
                        disabled={data.type !== 1 && data.state !== NOTICE_STATE.PENDING}
                    >
                        {messageFormatting('EDIT')}
                    </Button>
                );
            },
        },
        {
            key: 'check',
            render(_, data) {
                return <Button onClick={() => openStep({ ...data }, 'detail')}>{messageFormatting('CHECK')}</Button>;
            },
        },
        {
            key: 'copy',
            render(_, data) {
                if (!hasWritePermission) return null;
                return <Button onClick={() => openStep({ ...data }, 'copy')}>COPY</Button>;
            },
        },
    ];

    const unpublishNotice = useCallback(
        (projectId: string, oid: string) => {
            Modal.confirm({
                title: messageFormatting('NOTICE_UNPUBLISH_CONFIRM'),
                okText: messageFormatting('UNPUBLISH'),
                cancelText: messageFormatting('CANCEL'),
                onOk: async () => {
                    try {
                        if (type === 'emergency') {
                            await NoticeRepository.undeployUrgentNotice({ query: { projectId }, body: { id: oid } });
                        }

                        if (type === 'ingame') {
                            await NoticeRepository.undeployIngameNotice({ query: { projectId }, body: { id: oid } });
                        }

                        if (type === 'normal') {
                            await NoticeRepository.undeployNormalNotice({ query: { projectId }, body: { id: oid } });
                        }

                        getNotice();
                        notificationPush({ status: 'success', message: messageFormatting('SUCCESS_UNPUBLISH') });
                    } catch (e: any) {
                        notificationPush({ status: 'error', message: messageFormatting(e.message) });
                    }
                },
            });
        },
        [messageFormatting, type, getNotice],
    );

    const publishConfirm = (id: string) => {
        Modal.confirm({
            title: (
                <span css={modalConfirmColoe(type)}>
                    {messageFormatting(NOTICE_KEY[type])} 배포 {type === 'emergency' && '⚠️'}
                </span>
            ),
            content:
                type === 'emergency'
                    ? '배포를 누르면 공지사항만 게임에 노출됩니다. 서버 점검이 필요할 경우 꼭 상태를 점검으로 변경해주세요.'
                    : messageFormatting('NOTICE_CONFIRM_WARNING'),
            okText: messageFormatting('PUBLISH'),
            cancelText: messageFormatting('CANCEL'),
            onOk: async () => {
                // await NoticeRepository.publish(id, projectId);
                if (type === 'emergency') {
                    await NoticeRepository.deployUrgentNotice({ query: { projectId }, body: { id } });
                }

                if (type === 'ingame') {
                    await NoticeRepository.deployIngameNotice({ query: { projectId }, body: { id } });
                }
                if (type === 'normal') {
                    await NoticeRepository.deployNormalNotice({ query: { projectId }, body: { id } });
                }
                getNotice();
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_PUBLISH') });
            },
        });
    };

    return <Table dataSource={data} columns={columns} pagination={pagination} />;
}

export default NoticeTable;

const modalConfirmColoe = (type: string) => css`
    font-size: 24px;
    color: ${type === 'emergency' ? 'red' : 'black'};
`;
