import qs from 'qs';
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { classValidate } from 'utils/validation';
import { API_URL } from 'constants/constants';
import RequestAccess from './ReqeustAccess';

const instance = axios.create({
    baseURL: API_URL.origin,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    withCredentials: true,
});

const interceptorsRequestFulfilled = (config: AxiosRequestConfig) => {
    const token = RequestAccess.getInstance().getAccessToken();
    return {
        ...config,
        headers: {
            Authorization: token && `Bearer ${token}`,
            'x-site-lang': localStorage.getItem('lang') || 'kr',
        },
    };
};

const interceptorsResponseFulfilled = async (res: AxiosResponse) => {
    if (res.status >= 200 && res.status < 300) {
        if (res.headers['x-percent-token']) {
            const token = res.headers['x-percent-token'];
            RequestAccess.getInstance().setAccessToken(token);
            if (res.status === 204) {
                let data = undefined;

                try {
                    if (res.config.data) {
                        if (res.config.data instanceof FormData) {
                            data = res.config.data;
                        } else {
                            data = JSON.parse(res.config.data);
                        }
                    }
                } catch {
                    data = undefined;
                }
                const result = await instance.request({ ...res.config, data });
                return result;
            }
        }

        return res.data;
    }

    return Promise.reject(res.data);
};

const interceptorsResponseRejected = async (error: AxiosError) => {
    if (error.response?.data?.message !== null) {
        throw new Error(error.response?.data?.message);
    }

    throw new Error(error.response?.data?.message ?? error);
};

instance.interceptors.request.use(interceptorsRequestFulfilled);
instance.interceptors.response.use(interceptorsResponseFulfilled, interceptorsResponseRejected);

function validation(data?: object) {
    if (data) {
        const result = classValidate(data);
        if (!result.isSuccess) {
            throw Promise.reject(result);
        }
        return true;
    }
}

export async function get<T>(url: string, queryParam?: object) {
    if (queryParam) {
        await validation(queryParam);
    }
    const result = await instance.get<T, T>(url, { params: queryParam });

    return result;
}

export async function post<T>(...args: any) {
    const [url, body, params] = args;
    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.post<T, T>(url, body, { params });

    return result;
}

export async function put<T>(...args: any) {
    const [url, body, params] = args;

    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.put<T, T>(url, body, { params });

    return result;
}

export async function del<T>(...args: any) {
    const [url, params] = args;

    if (params) {
        await validation(params);
    }

    const result = await instance.delete<T, T>(url, { params });

    return result;
}

export async function patch<T>(...args: any) {
    const [url, body, params] = args;
    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.patch<T, T>(url, body, { params });

    return result;
}
