import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class ProjectIdQueryDto {
    constructor(projectId: string) {
        this.projectId = projectId;
    }

    @IsNotEmpty()
    @IsString()
    projectId: string;
}

export class PaginationQuery {
    constructor({ page, limit }: PaginationQuery) {
        this.page = page || 1;
        this.limit = limit;
    }

    @IsNotEmpty()
    @IsNumber()
    page?: number;

    @IsNotEmpty()
    @IsNumber()
    limit: number;
}

export class ProjectListQuery {
    constructor({ projectId, page, limit }: ProjectListQuery) {
        this.projectId = projectId;
        this.page = page;
        this.limit = limit;
    }

    @IsNotEmpty()
    @IsNumber()
    projectId: number;

    @IsNotEmpty()
    @IsNumber()
    page: number;

    @IsNotEmpty()
    @IsNumber()
    limit: number;
}

export class IGlobalInput {
    [key: string]: string | null;
}

export class PaginationResponse {
    totalCount: number;
    lastPage: number;
}
