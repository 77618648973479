import { BlockUserListQueryDto } from 'dto/blockUser.dto';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { BlockUserRepository } from 'repository';

export default function useBlockUserModel(
    projectId: string,
    limit = 1,
    defaultListQuery?: Omit<BlockUserListQueryDto, 'projectId'>,
) {
    const [listQuery, setListQuery] = useState<Omit<BlockUserListQueryDto, 'projectId'> | undefined>(defaultListQuery);

    const queries = useQuery(['blockUserData', projectId, listQuery], () =>
        listQuery
            ? BlockUserRepository.list({
                  query: {
                      page: listQuery.page || 1,
                      projectId,
                      limit,
                  },
              })
            : undefined,
    );

    return {
        ...queries,
        setListQuery,
        listQuery,
    };
}
