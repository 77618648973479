import { MAIL_STATE, MAIL_STATE_TEXT, NOTICE_STATE, NOTICE_STATE_TEXT } from 'constants/constants';

export const mailStatusColor = {
    [MAIL_STATE_TEXT[MAIL_STATE.SUCCESS]]: '#00aa11',
    [MAIL_STATE_TEXT[MAIL_STATE.FAIL]]: '#aa0011',
    [MAIL_STATE_TEXT[MAIL_STATE.REQUEST]]: '#aaaa11',
};

export const noticeStatusColor = {
    [NOTICE_STATE_TEXT[NOTICE_STATE.WRITE_SUCCESS]]: '#00aa11',
    [NOTICE_STATE_TEXT[NOTICE_STATE.WRITE_FAIL]]: '#aa0011',
    [NOTICE_STATE_TEXT[NOTICE_STATE.WRITE_REQUEST]]: '#aaaa11',
};
