import { css } from '@emotion/react';
import { Button, DatePicker, Form, Input, Modal, Radio, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EnterIgnoreForm, OsIcon } from 'components';
import {
    ENoticePlatform,
    MAINTENANCE_STATE_TEXT,
    NOTICE_STATE,
    URGENT_NOTICE_STATE,
    URGENT_NOTICE_STATE_TEXT,
} from 'constants/constants';
import { IMaintenanceSchedule } from 'dto/notice.dto';
import { useMaintenance, useScreenLock } from 'hooks';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { findTargetElementByType } from 'utils/eventListener';
import { notificationPush } from 'utils/notificationUtils';

interface IProp {
    visible: boolean;
    onCancel: () => void;

    projectId: string;
}

export default function MaintenanceModal({ visible, onCancel, projectId }: IProp) {
    const { maintenance, maintanceReservationList, createReservation, deleteReservation } = useMaintenance(projectId);
    const [isReservation, setIsReservation] = useState(false);
    const { withScreenLockCall } = useScreenLock();

    const getModalContents = useCallback((status: number) => {
        if (status === URGENT_NOTICE_STATE.REPAIRING || status === URGENT_NOTICE_STATE.REPARING_TEST) {
            return (
                <div>
                    <div>확인을 누르면 즉시 혹은 예약 시간에 점검이 진행됩니다.</div>
                    <div>현재 배포된 긴급 공지가 없을 경우</div>
                    <div>공지사항 없이 점검만 진행되니 주의하세요!</div>
                </div>
            );
        }

        if (status === URGENT_NOTICE_STATE.OPERATING) {
            return (
                <div>
                    <div>확인을 누르면 즉시 혹은 예약 시간에 운영이 진행됩니다.</div>
                    <div>현재 배포된 긴급 공지가 있을 경우</div>
                    <div>유저가 접속할 때, 공지사항을 노출하게 됩니다.</div>
                </div>
            );
        }
    }, []);

    const submitListener = useCallback(
        (arg: Record<string, any>) => {
            Modal.confirm({
                title: arg.scheduleTime ? '정말로 예약을 거시겠습니까?' : '정말로 상태를 변경하시겠습니까?',
                content: getModalContents(Number(arg.status)),
                onOk: async () => {
                    try {
                        if (arg.scheduleTime) {
                            await withScreenLockCall(createReservation, {
                                serverState: Number(arg.status),
                                schedule: new Date(arg.scheduleTime).toISOString(),
                                platform: Number(arg.platform),
                                description: arg.description,
                            });
                        } else {
                            await withScreenLockCall(maintenance, {
                                platform: Number(arg.platform),
                                status: Number(arg.status),
                            });
                        }

                        notificationPush({
                            status: 'success',
                            title: '성공적으로 반영했습니다.',
                        });

                        if (!arg.scheduleTime) onCancel();
                    } catch (e: any) {
                        notificationPush({
                            status: 'error',
                            title: e.message,
                        });
                    }
                },
            });
        },
        [maintenance, withScreenLockCall, onCancel, createReservation, getModalContents],
    );

    const columns = useMemo<ColumnsType<IMaintenanceSchedule>>(
        () => [
            {
                title: '플랫폼',
                dataIndex: 'platform',
                render(value) {
                    if (value === ENoticePlatform.All) {
                        return (
                            <>
                                <OsIcon os="Android" />
                                <OsIcon os="iOS" />
                            </>
                        );
                    }
                    if (value === ENoticePlatform.AOS) return <OsIcon os="Android" />;
                    if (value === ENoticePlatform.IOS) return <OsIcon os="iOS" />;
                },
            },
            {
                title: '요청 상태',
                dataIndex: 'serverState',
                render(value) {
                    return URGENT_NOTICE_STATE_TEXT[value];
                },
            },
            {
                title: '진행 상태',
                dataIndex: 'requestState',
                render(value) {
                    return MAINTENANCE_STATE_TEXT[value];
                },
            },
            {
                title: '예약 시간',
                dataIndex: 'schedule',
                render(value) {
                    return moment(value).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                title: '',
                dataIndex: 'id',
                render(value, { requestState }) {
                    if (requestState !== NOTICE_STATE.PENDING) return null;
                    return (
                        <Button data-id={value} data-type="delete" danger>
                            삭제
                        </Button>
                    );
                },
            },
        ],
        [],
    );

    const reservationWrapperClickListener = useCallback(
        (e) => {
            const target = findTargetElementByType(e, 'delete');
            if (target) {
                const id = target.dataset.id;
                if (id) {
                    Modal.confirm({
                        title: '정말로 예약을 삭제하시겠습니까?',
                        onOk: async () => {
                            await withScreenLockCall(deleteReservation, id);
                            notificationPush({
                                status: 'success',
                                title: '성공적으로 삭제 했습니다.',
                            });
                        },
                    });
                }
            }
        },
        [withScreenLockCall, deleteReservation],
    );

    return (
        <Modal visible={visible} onCancel={onCancel} footer={null} title="서버 상태 설정" width="80%">
            <section css={serverControlWrapper}>
                <div css={serverControleFormWrapper}>
                    <EnterIgnoreForm onSubmit={submitListener}>
                        <section>
                            <Form.Item label="설명">
                                <Input name="description" defaultValue="" />
                            </Form.Item>
                        </section>
                        <section>
                            <Form.Item label="운영체제">
                                <Radio.Group name="platform" defaultValue={ENoticePlatform.All}>
                                    <Radio value={ENoticePlatform.AOS}>
                                        <OsIcon os="Android" />
                                    </Radio>
                                    <Radio value={ENoticePlatform.IOS}>
                                        <OsIcon os="iOS" />
                                    </Radio>
                                    <Radio value={ENoticePlatform.All}>
                                        <OsIcon os="Android" />
                                        <OsIcon os="iOS" />
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </section>
                        <section>
                            <Form.Item label="상태설정">
                                <Radio.Group name="status">
                                    <Radio value={URGENT_NOTICE_STATE.OPERATING}>라이브</Radio>
                                    <Radio value={URGENT_NOTICE_STATE.REPAIRING}>점검</Radio>
                                    <Radio value={URGENT_NOTICE_STATE.REPARING_TEST}>내부 오픈</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </section>
                        <section>
                            <details
                                css={detailStyle}
                                onToggle={(e) => {
                                    const target = e.currentTarget as HTMLDetailsElement;
                                    setIsReservation(target.open);
                                }}
                            >
                                <summary>예약 설정</summary>
                                {isReservation && (
                                    <Form.Item label="예약 시간">
                                        <DatePicker name="scheduleTime" showTime />
                                    </Form.Item>
                                )}
                            </details>
                        </section>
                        <footer>
                            <Button htmlType="submit" type="primary">
                                확인
                            </Button>
                        </footer>
                    </EnterIgnoreForm>
                </div>
                <div css={reservationListWrapper} onClick={reservationWrapperClickListener}>
                    <h2>예약 리스트</h2>
                    <Table
                        dataSource={maintanceReservationList?.data.map((reservation: any) => ({
                            ...reservation,
                            key: reservation._id,
                        }))}
                        columns={columns}
                    />
                </div>
            </section>
        </Modal>
    );
}

const serverControlWrapper = css`
    display: flex;
`;

const reservationListWrapper = css`
    padding-left: 30px;
`;

const serverControleFormWrapper = css`
    border-right: 1px solid black;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const detailStyle = css`
    margin-bottom: 24px;
    summary {
        cursor: pointer;
        margin-bottom: 8px;
    }
`;
