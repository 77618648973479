import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import { ProjectServiceAuth } from 'pages';
import { AUTH_LEVEL, COMMON_PROJECT_ID } from 'constants/constants';

const AdminRoute = () => {
    return (
        <Switch>
            <Route
                path="/admin/projects/ServiceAuth"
                render={() => (
                    <PageAuthViewController
                        projectId={COMMON_PROJECT_ID}
                        service="serviceAuth"
                        level={AUTH_LEVEL.WRITE}
                    >
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service="project"
                            level={AUTH_LEVEL.WRITE}
                        >
                            <ProjectServiceAuth />
                        </PageAuthViewController>
                    </PageAuthViewController>
                )}
            />
        </Switch>
    );
};

export default AdminRoute;
