import { ItemDto } from '@gamebase-ops/interfaces/item/dto';
import { Descriptions } from 'antd';
import { notImage } from 'constants/constants';
import { useFormatMessage } from 'hooks';
interface IProp {
    item: ItemDto;
}

const ItemDetail: React.FC<IProp> = ({ item }) => {
    const { messageFormatting } = useFormatMessage();

    return (
        <Descriptions
            title={`${messageFormatting('ITEM_NUMBER')}: ${item.itemId}`}
            bordered
            size="default"
            contentStyle={{ background: '#ffffff' }}
            column={1}
        >
            <div>
                <img src={notImage} />
            </div>

            <Descriptions.Item label={'itemId'}>{item.itemId}</Descriptions.Item>
            <Descriptions.Item label={'type'}>{item.type}</Descriptions.Item>
            <Descriptions.Item label={'subType'}>{item.subType}</Descriptions.Item>
        </Descriptions>
    );
};

export default ItemDetail;
