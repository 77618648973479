import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController, WithIntlProvider } from 'provider';
import { LoginPage, Home, RolePage } from 'pages';
import { useAccount, useError } from 'hooks';
import { Suspense, useEffect } from 'react';
import ProjectRoute from 'routes/ProjectRoute';
import AdminRoute from 'routes/AdminRoute';
import { MainLayout, ScreenLockPending } from 'components';
import MyPageRoute from 'routes/MyPageRoute';
import AccountRoute from 'routes/AccountRoute';
import { AUTH_LEVEL, COMMON_PROJECT_ID } from 'constants/constants';

const MustLoginUsePage = () => {
    return (
        <Suspense fallback={<div>로딩중</div>}>
            <ScreenLockPending />
            <Switch>
                <Route path="/login" component={LoginPage} />
                <MainLayout>
                    <Route path="/accounts" component={AccountRoute} />
                    <Route path="/admin" component={AdminRoute} />
                    <Route path="/project" component={ProjectRoute} />
                    <Route path="/myPage" component={MyPageRoute} />
                    <Route
                        exact
                        path="/roles"
                        render={() => (
                            <PageAuthViewController
                                projectId={COMMON_PROJECT_ID}
                                level={AUTH_LEVEL.WRITE}
                                service="role"
                            >
                                <RolePage />
                            </PageAuthViewController>
                        )}
                    />
                    <Route
                        path="/"
                        exact
                        render={() => (
                            <PageAuthViewController>
                                <Home />
                            </PageAuthViewController>
                        )}
                    />
                </MainLayout>
            </Switch>
        </Suspense>
    );
};

function App(): JSX.Element {
    const { getMyDataRequest, account } = useAccount();

    const { errorHandle } = useError();

    useEffect(() => {
        getMyDataRequest();
    }, [getMyDataRequest]);

    useEffect(() => {
        window.addEventListener('unhandledrejection', errorHandle);
        return () => {
            window.removeEventListener('unhandledrejection', errorHandle);
        };
    }, [errorHandle]);

    return !account?.account?.id ? (
        <Switch>
            <Route path="/login" component={LoginPage} />
        </Switch>
    ) : (
        <MustLoginUsePage />
    );
}

export default WithIntlProvider(App);
