export const Login = {
    Email: {
        Request: '@auth/email/login/request',
        Failure: '@auth/email/login/failure',
        Success: '@auth/email/login/success',
    },
    Google: {
        Request: '@auth/google/login/request',
        Failure: '@auth/google/login/failure',
        Success: '@auth/google/login/success',
    },
};

export const Logout = {
    Request: '@auth/logout/request',
    Failure: '@auth/logout/failure',
    Success: '@auth/logout/success',
};

export const emailLoginSuccessAction = (payload: { email: string }) => ({
    type: Login.Email.Success,
    payload,
});

export const emailLoginAction = (payload: EmailLogin): { type: string; payload: EmailLogin } => ({
    type: Login.Email.Request,
    payload,
});

export const googleLoginAction = (payload: GoogleLoginPayload): { type: string; payload: GoogleLoginPayload } => ({
    type: Login.Google.Request,
    payload,
});

export const logoutAction = (payload: LogoutPayload) => ({
    type: Logout.Request,
    payload,
});
