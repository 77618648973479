import { css } from '@emotion/react';
import { EGameServerState } from '@gamebase-ops/interfaces/consts';
import { URGENT_NOTICE_STATE } from 'constants/constants';

interface IProp {
    serverStatus: EGameServerState;
}

const serverStatusColor = {
    [URGENT_NOTICE_STATE.OPERATING]: '#00FF00',
    [URGENT_NOTICE_STATE.REPAIRING]: '#FF0000',
    [URGENT_NOTICE_STATE.REPARING_TEST]: '#FFFF00',
};

export default function ServerStatusIcon({ serverStatus }: IProp) {
    return (
        <div css={wrapper} style={{ backgroundColor: serverStatusColor[serverStatus] }}>
            {' '}
        </div>
    );
}

const wrapper = css`
    width: 16px;
    height: 16px;
    border-radius: 100%;
`;
