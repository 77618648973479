import { Form, Input, Tabs, Button, Select, InputNumber, Radio } from 'antd';
import DateTimePicker from 'components/utils/formItems/DateTimePicker';
import { useMemo, useState } from 'react';
import { dateTimeMerge } from 'utils/dateTime';
import { EnterIgnoreForm, OsIcon } from 'components';
import { useFormatMessage, useFormRules } from 'hooks';
import { EMERGENCY_NOTICE_PLATFORM } from 'constants/constants';

interface IFirstStep {
    onSubmit?: (data: any) => void;
    publishData: ICreateNotice | null;
    type: string;
    languages?: string[];
}

function FirstStep({ onSubmit, publishData, type: NoticeType, languages }: IFirstStep) {
    const [startTime, setStartTime] = useState(publishData?.startTime);
    const [endTime, setEndTime] = useState(publishData?.endTime);
    const { formValidator } = useFormRules();

    const { messageFormatting } = useFormatMessage();
    const onFinish = async (values: any) => {
        const startTime = dateTimeMerge(values.startTime.date._d, values.startTime.time._d);
        const endTime = dateTimeMerge(values.endTime.date._d, values.endTime.time._d);
        const defualtLanguage = values.defaultLanguage;

        const newMessages = fillMessages(defualtLanguage, values.messages);
        const newTitles = fillMessages(defualtLanguage, values.titles);

        if (onSubmit) {
            onSubmit({
                ...values,
                ...{
                    messages: { ...values.messages, ...newMessages },
                    startTime: startTime,
                    endTime: endTime,
                    titles: { ...values.titles, ...newTitles },
                },
            });
        }
    };

    const publishDataMessages = useMemo(() => {
        return publishData?.messages;
    }, [publishData]);

    const publishDataTitles = useMemo(() => {
        return publishData?.titles;
    }, [publishData]);

    const fillMessages = (defaultValue: string, messages: IMessage) => {
        const newMessages = {};

        if (messages === undefined) {
            return newMessages;
        }

        languages?.map((lang) => {
            if (!messages[lang]) {
                Object.assign(newMessages, { [lang]: messages[defaultValue as keyof IMessage] });
            }
        });

        return newMessages;
    };

    return (
        <EnterIgnoreForm name="control-hooks" onFinish={onFinish} layout="vertical">
            <Form.Item
                name="description"
                label={messageFormatting('TITLE')}
                rules={formValidator.getRules('notice_title')}
                initialValue={publishData?.description}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="defaultLanguage"
                label={messageFormatting('NOTICE_FIRST_LANGUAGE')}
                rules={formValidator.getRules('notice_language')}
                initialValue={publishData?.defaultLanguage}
            >
                <Select placeholder={messageFormatting('NOTICE_FIRST_LANGUAGE_MESSAGE')}>
                    {languages?.map((lang) => (
                        <Select.Option key={lang} value={lang}>
                            {lang}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={messageFormatting('LANGUAGE')} initialValue={publishData?.messages}>
                <Tabs type="card">
                    {languages?.map((lang) => {
                        return (
                            <Tabs.TabPane tab={lang} key={lang} forceRender>
                                <>
                                    {NoticeType === 'normal' && (
                                        <Form.Item
                                            name={['titles', lang]}
                                            initialValue={
                                                publishDataTitles && publishDataTitles[lang as keyof IMessage]
                                            }
                                        >
                                            <Input />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        name={['messages', lang]}
                                        initialValue={
                                            publishDataMessages && publishDataMessages[lang as keyof IMessage]
                                        }
                                    >
                                        <Input.TextArea
                                            showCount
                                            maxLength={10000}
                                            style={{ height: '40rem' }}
                                            onKeyDown={(e) => {
                                                if (e.key.toLowerCase() === 'enter') e.stopPropagation();
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            </Form.Item>
            {NoticeType === 'ingame' && (
                <Form.Item
                    label={messageFormatting('REPEAT_NUM')}
                    name="repeatNum"
                    initialValue={publishData?.repeatNum || 1}
                >
                    <InputNumber min={1} />
                </Form.Item>
            )}
            <section>
                <DateTimePicker
                    baseName="startTime"
                    title={messageFormatting('START_AT')}
                    layout="vertical"
                    defaultValue={publishData?.startTime}
                    onChange={(dateString) => setStartTime(dateString)}
                />
                {startTime && new Date(startTime).toUTCString()}
            </section>
            <section>
                <DateTimePicker
                    baseName="endTime"
                    title={messageFormatting('END_AT')}
                    layout="vertical"
                    defaultValue={publishData?.endTime}
                    onChange={(dateString) => setEndTime(dateString)}
                />
                {endTime && new Date(endTime).toUTCString()}
            </section>
            {NoticeType === 'emergency' && (
                <Form.Item
                    name="platform"
                    label="platform"
                    style={{ marginTop: '24px' }}
                    initialValue={publishData?.platform || EMERGENCY_NOTICE_PLATFORM.BOTH}
                >
                    <Radio.Group>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.ANDROID}>
                            <OsIcon os="Android" />
                        </Radio>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.iOS}>
                            <OsIcon os="iOS" />
                        </Radio>
                        <Radio value={EMERGENCY_NOTICE_PLATFORM.BOTH}>
                            <OsIcon os="Android" />
                            <OsIcon os="iOS" />
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            )}
            <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                    {messageFormatting('NEXT')}
                </Button>
            </Form.Item>
        </EnterIgnoreForm>
    );
}

export default FirstStep;
