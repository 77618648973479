import React, { useCallback, useMemo, useRef } from 'react';
import { DatePicker, Form, TimePicker } from 'antd';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './DateTimePicker.module.scss';
import { useFormRules } from 'hooks';

interface IProp {
    defaultValue?: string;
    baseName: string;
    title: string;
    layout?: 'horizontal' | 'vertical';
    onChange?: (date: string) => void;
}

const cx = classNames.bind(styles);

const DateTimePicker: React.FC<IProp> = ({ defaultValue, baseName, title, layout = 'horizontal', onChange }) => {
    const dateRef = useRef<Date>(new Date(defaultValue || ''));
    const { formValidator } = useFormRules();

    const pickerOnChange = useCallback(() => {
        onChange?.(dateRef.current.toUTCString());
    }, [onChange]);

    return (
        <div
            className={cx('dateTimePickerWrapper')}
            style={
                layout === 'horizontal' ? { flexDirection: 'row' } : { flexDirection: 'column', alignItems: 'inherit' }
            }
        >
            <label className={cx('pickerTitle')}>{title}</label>
            <div className={cx('pickerContainer')}>
                <Form.Item
                    className={cx('pickerWrapper')}
                    name={[baseName, 'date']}
                    rules={formValidator.getRules('picker_date')}
                    initialValue={defaultValue && moment(defaultValue)}
                >
                    <DatePicker
                        onChange={(e) => {
                            const date = e ? e.date() : 0;
                            dateRef.current.setFullYear(e?.year() || 0);
                            dateRef.current.setMonth(e?.month() || 0);
                            dateRef.current.setDate(date);
                            pickerOnChange();
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className={cx('pickerWrapper')}
                    name={[baseName, 'time']}
                    rules={formValidator.getRules('picker_time')}
                    initialValue={defaultValue && moment(defaultValue)}
                >
                    <TimePicker
                        onChange={(e) => {
                            dateRef.current.setHours(e?.hour() || 0);
                            dateRef.current.setMinutes(e?.minutes() || 0);
                            dateRef.current.setSeconds(e?.seconds() || 0);
                            pickerOnChange();
                        }}
                    />
                </Form.Item>
            </div>
        </div>
    );
};

export default DateTimePicker;
