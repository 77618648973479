import QueryString from 'qs';
import { useLocation } from 'react-router-dom';

type IQueryTypes<T extends {}> = T & {
    projectId: string;
};

export default function useQueries<T>() {
    const { search } = useLocation();

    const queries = QueryString.parse(search, {
        ignoreQueryPrefix: true,
    }) as IQueryTypes<T>;

    const projectIdNumber = queries.projectId || null;

    return {
        ...queries,
        projectId: projectIdNumber,
    };
}
