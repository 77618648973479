import { css } from '@emotion/react';
import { Button } from 'antd';
import { usePublishedWebviewNotice } from 'hooks';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';

import WebviewPreviewList from './WebviewPreviewList';

interface IProp {
    projectId: string;
    onClose?: () => void;
    hasWritePermission: boolean;
}

export default function WebviewPreview({ projectId, onClose, hasWritePermission }: IProp) {
    const { sortedWebviewData, removedAndInsertWebviewData, sortedPublishedNotice } =
        usePublishedWebviewNotice(projectId);

    const onSubmitListener = useCallback(async () => {
        if (window.confirm('정말로 적용하시겠습니까?')) {
            await sortedPublishedNotice();
            notificationPush({
                status: 'success',
                title: '성공적으로 정렬을 완료 했습니다.',
            });
        }
    }, [sortedPublishedNotice]);

    return (
        <section css={previewWrapper}>
            {sortedWebviewData && (
                <WebviewPreviewList
                    webviewDatas={sortedWebviewData}
                    onDrop={removedAndInsertWebviewData}
                    hasWritePermission={hasWritePermission}
                />
            )}

            <footer css={footerStyle}>
                <section css={footerContentsStyle}>
                    <Button type="primary" onClick={onSubmitListener}>
                        적용
                    </Button>
                    <Button onClick={onClose}>닫기</Button>
                </section>
            </footer>
        </section>
    );
}

const footerStyle = css`
    width: 100%;
    height: 32px;
`;

const footerContentsStyle = css`
    width: 100%;
    text-align: right;
    background: white;
    padding: 4px;
    margin-top: 24px;
    button {
        margin-left: 8px;
    }
`;

const previewWrapper = css`
    padding-bottom: 42px;
`;
