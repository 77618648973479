import { useCallback, useState } from 'react';
import { VoidedPurchasesRepository } from 'repository';
import { useQuery } from 'react-query';
import { VoidedPurchaseLogListRequest } from '@gamebase-ops/interfaces/voidedPurchaseLog/request';
import { arrayToCsvDownload } from 'utils/arrayUtils';

export default function useVoidedPurchases(projectId: string) {
    const [listQuery, setListQuery] = useState<VoidedPurchaseLogListRequest>();

    const { data } = useQuery(['voidedPurchaseList', listQuery], () =>
        listQuery ? VoidedPurchasesRepository.list({ query: listQuery }) : undefined,
    );

    const setQuery = useCallback(
        (param: Omit<VoidedPurchaseLogListRequest, 'projectId'>) => {
            setListQuery({
                ...param,
                projectId,
            });
        },
        [projectId],
    );

    const setPageNumber = useCallback((page: number) => {
        setListQuery((prev) => {
            if (prev) {
                return { ...prev, page };
            }
        });
    }, []);

    return {
        listQuery,
        setQuery,
        voidedPurchaseList: data?.result.data,
        download: () => {
            arrayToCsvDownload(data?.result.data || []);
        },
        setPageNumber,
        totalCount: data?.result.totalCount,
    };
}
