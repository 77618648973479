import { get, patch, post } from 'utils/api/client';
import { INoticeRepository } from '@gamebase-ops/interfaces/notice/interface';
import {
    NormalNoticeCreateRequest,
    NormalNoticeUpdateRequest,
    NormalNoticeInfoRequest,
    NormalNoticeListRequest,
    NormalNoticeDeployRequest,
    NormalNoticeUndeployRequest,
    IngameNoticeCreateRequest,
    IngameNoticeUpdateRequest,
    IngameNoticeInfoRequest,
    IngameNoticeListRequest,
    IngameNoticeDeployRequest,
    IngameNoticeUndeployRequest,
    UrgentNoticeCreateRequest,
    UrgentNoticeUpdateRequest,
    UrgentNoticeInfoRequest,
    UrgentNoticeListRequest,
    UrgentNoticeDeployRequest,
    UrgentNoticeUndeployRequest,
    WebviewNoticeCreateRequest,
    WebviewNoticeDeployRequest,
    WebviewNoticeInfoRequest,
    WebviewNoticeListRequest,
    WebviewNoticeSortRequest,
    WebviewNoticeUndeployRequest,
    WebviewNoticeUpdateRequest,
    WebviewNoticeListOfDeployedRequest,
    WebviewNoticeHtmlUploadRequest,
} from '@gamebase-ops/interfaces/notice/request';
import {
    NormalNoticeInfoResponse,
    NormalNoticeListResponse,
    IngameNoticeInfoResponse,
    IngameNoticeListResponse,
    UrgentNoticeInfoResponse,
    UrgentNoticeListResponse,
    WebviewNoticeInfoResponse,
    WebviewNoticeListResponse,
    WebviewNoticeListOfDeployedResponse,
    WebviewFileUploadResponse,
} from '@gamebase-ops/interfaces/notice/response';
import { Query, Auth, Body, BaseResponse } from '@gamebase-ops/interfaces/utils';

const baseUrl = '/api/v1/notices';

class NoticeRepository implements INoticeRepository {
    uploadImage(param: Query<Auth> & Body<FormData>): Promise<WebviewFileUploadResponse> {
        return post(baseUrl + '/webview/upload/image', param.body, new Auth(param.query));
    }
    uploadHtml(param: Query<Auth> & Body<WebviewNoticeHtmlUploadRequest>): Promise<WebviewFileUploadResponse> {
        return post(
            baseUrl + '/webview/upload/html',
            new WebviewNoticeHtmlUploadRequest(param.body),
            new Auth(param.query),
        );
    }
    listOfDeployedWebviewNotice(
        param: Query<WebviewNoticeListOfDeployedRequest>,
    ): Promise<WebviewNoticeListOfDeployedResponse> {
        return get(baseUrl + '/webview/list/deployed', new WebviewNoticeListOfDeployedRequest(param.query));
    }
    createWebviewNotice(param: Query<Auth> & Body<WebviewNoticeCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/webview', new WebviewNoticeCreateRequest(param.body), new Auth(param.query));
    }
    updateWebviewNotice(param: Query<Auth> & Body<WebviewNoticeUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview', new WebviewNoticeUpdateRequest(param.body), new Auth(param.query));
    }
    infoWebviewNotice(param: Query<WebviewNoticeInfoRequest>): Promise<WebviewNoticeInfoResponse> {
        return get(baseUrl + '/webview/info', new WebviewNoticeInfoRequest(param.query));
    }
    listWebviewNotice(param: Query<WebviewNoticeListRequest>): Promise<WebviewNoticeListResponse> {
        return get(baseUrl + '/webview/list', new WebviewNoticeListRequest(param.query));
    }
    deployWebviewNotice(param: Query<Auth> & Body<WebviewNoticeDeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview/deploy', new WebviewNoticeDeployRequest(param.body), new Auth(param.query));
    }
    undeployWebviewNotice(param: Query<Auth> & Body<WebviewNoticeUndeployRequest>): Promise<BaseResponse> {
        return patch(
            baseUrl + '/webview/undeploy',
            new WebviewNoticeUndeployRequest(param.body),
            new Auth(param.query),
        );
    }
    sortWebviewNotice(param: Query<Auth> & Body<WebviewNoticeSortRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/webview/sort', new WebviewNoticeSortRequest(param.body), new Auth(param.query));
    }
    createNormalNotice(param: Query<Auth> & Body<NormalNoticeCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/normal', new NormalNoticeCreateRequest(param.body), new Auth(param.query));
    }
    updateNormalNotice(param: Query<Auth> & Body<NormalNoticeUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/normal', new NormalNoticeUpdateRequest(param.body), new Auth(param.query));
    }
    infoNormalNotice(param: Query<NormalNoticeInfoRequest>): Promise<NormalNoticeInfoResponse> {
        return get(baseUrl + '/normal/info', new NormalNoticeInfoRequest(param.query));
    }
    listNormalNotice(param: Query<NormalNoticeListRequest>): Promise<NormalNoticeListResponse> {
        return get(baseUrl + '/normal/list', new NormalNoticeListRequest(param.query));
    }
    deployNormalNotice(param: Query<Auth> & Body<NormalNoticeDeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/normal/deploy', new NormalNoticeDeployRequest(param.body), new Auth(param.query));
    }
    undeployNormalNotice(param: Query<Auth> & Body<NormalNoticeUndeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/normal/undeploy', new NormalNoticeUndeployRequest(param.body), new Auth(param.query));
    }
    createIngameNotice(param: Query<Auth> & Body<IngameNoticeCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/ingame', new IngameNoticeCreateRequest(param.body), new Auth(param.query));
    }
    updateIngameNotice(param: Query<Auth> & Body<IngameNoticeUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ingame', new IngameNoticeUpdateRequest(param.body), new Auth(param.query));
    }
    infoIngameNotice(param: Query<IngameNoticeInfoRequest>): Promise<IngameNoticeInfoResponse> {
        return get(baseUrl + '/ingame/info', new IngameNoticeInfoRequest(param.query));
    }
    listIngameNotice(param: Query<IngameNoticeListRequest>): Promise<IngameNoticeListResponse> {
        return get(baseUrl + '/ingame/list', new IngameNoticeListRequest(param.query));
    }
    deployIngameNotice(param: Query<Auth> & Body<IngameNoticeDeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ingame/deploy', new IngameNoticeDeployRequest(param.body), new Auth(param.query));
    }
    undeployIngameNotice(param: Query<Auth> & Body<IngameNoticeUndeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ingame/undeploy', new IngameNoticeUndeployRequest(param.body), new Auth(param.query));
    }
    createUrgentNotice(param: Query<Auth> & Body<UrgentNoticeCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/urgent', new UrgentNoticeCreateRequest(param.body), new Auth(param.query));
    }
    updateUrgentNotice(param: Query<Auth> & Body<UrgentNoticeUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/urgent', new UrgentNoticeUpdateRequest(param.body), new Auth(param.query));
    }
    infoUrgentNotice(param: Query<UrgentNoticeInfoRequest>): Promise<UrgentNoticeInfoResponse> {
        return get(baseUrl + '/urgent/info', new UrgentNoticeInfoRequest(param.query));
    }
    listUrgentNotice(param: Query<UrgentNoticeListRequest>): Promise<UrgentNoticeListResponse> {
        return get(baseUrl + '/urgent/list', new UrgentNoticeListRequest(param.query));
    }
    deployUrgentNotice(param: Query<Auth> & Body<UrgentNoticeDeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/urgent/deploy', new UrgentNoticeDeployRequest(param.body), new Auth(param.query));
    }
    undeployUrgentNotice(param: Query<Auth> & Body<UrgentNoticeUndeployRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/urgent/undeploy', new UrgentNoticeUndeployRequest(param.body), new Auth(param.query));
    }
}

export default new NoticeRepository();
