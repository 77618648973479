import { ScheduledCreateRequest } from '@gamebase-ops/interfaces/maintenance/v2';
import { MaintenanceRequestDto } from 'dto/notice.dto';

import { useCallback } from 'react';
import { MaintenanceRepository } from 'repository';
import useMaintenanceInfoModel from './model/useMaintenanceInfo.model';
import useMaintenanceListModel from './model/useMaintenanceList.model';

export default function useMaintenance(projectId: string) {
    const { data, refetch: maintenanceRefetch } = useMaintenanceListModel(projectId);
    const { data: maintenanceInfo, refetch: maintenanceInfoRefetch } = useMaintenanceInfoModel(projectId);

    const excuteMaintenance = useCallback(
        async (param: MaintenanceRequestDto) => {
            await MaintenanceRepository.maintenace({
                query: { projectId },
                body: { platform: param.platform, serverState: param.status },
            });

            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [projectId, maintenanceRefetch, maintenanceInfoRefetch],
    );

    const createReservation = useCallback(
        async (param: ScheduledCreateRequest) => {
            await MaintenanceRepository.schedule({ query: { projectId }, body: param });
            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [maintenanceRefetch, maintenanceInfoRefetch, projectId],
    );

    const deleteReservation = useCallback(
        async (id: string) => {
            await MaintenanceRepository.deleteSchedule({ query: { id, projectId } });
            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [maintenanceInfoRefetch, maintenanceRefetch, projectId],
    );

    return {
        maintenance: excuteMaintenance,
        maintanceReservationList: data?.result,
        maintenanceInfo: maintenanceInfo?.result,
        createReservation,
        deleteReservation,
    };
}
