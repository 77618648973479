import { IAccountEditDto } from 'dto/account.dto';
import { useCallback, useState } from 'react';
import { AccountRepository } from 'repository';
import { AccountDetailDto } from '@gamebase-ops/interfaces/account';

export default function useEditAccount() {
    const [account, setAccount] = useState<AccountDetailDto>();
    // const [roles, setRoles] = useState<IRole[]>([]);

    const requestAccountDetailById = useCallback(async (id: string) => {
        const { result } = await AccountRepository.info({ query: { id, projectId: 'COMMON' } });
        setAccount(result);
    }, []);

    const accountPasswordInitializeById = useCallback(
        async (id: string, password: string) => {
            await AccountRepository.update({ body: { id, password }, query: { projectId: '' } });
            requestAccountDetailById(id);
        },
        [requestAccountDetailById],
    );

    const patchAccount = useCallback(
        async (id: string, data: IAccountEditDto) => {
            await AccountRepository.update({
                body: { id, activeState: data.isActive, name: data.username },
                query: { projectId: '' },
            });

            requestAccountDetailById(id);
        },
        [requestAccountDetailById],
    );

    return { account, requestAccountDetailById, accountPasswordInitializeById, patchAccount };
}
