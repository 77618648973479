import { call, ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AccountRepository, AuthenticationRepository } from 'repository';

import { emailLoginSuccessAction, Login, Logout } from './action';

interface EmailLoginAction {
    type: typeof Login.Email.Request;
    payload: EmailLogin;
}

export function* emailLoginSaga(action: EmailLoginAction) {
    try {
        yield call(AccountRepository.login, { body: action.payload });
        if (action.payload.callback) action.payload.callback();
        yield put(emailLoginSuccessAction({ email: action.payload.userId }));
    } catch (e) {
        return Promise.reject(e);
    }
}

interface GoogleLoginAction {
    type: typeof Login.Google.Request;
    payload: GoogleLoginPayload;
}

export function* googleLoginSaga(action: GoogleLoginAction) {
    try {
        yield call(AccountRepository.loginStaff, {
            body: { accessToken: action.payload.accessToken },
        });

        const {
            result: { userId },
        } = yield call(AuthenticationRepository.profile);
        if (action.payload.callback) action.payload.callback();
        yield put(emailLoginSuccessAction({ email: userId }));
    } catch (e) {
        return Promise.reject(e);
    }
}

interface LogoutAction {
    type: typeof Logout.Request;
    payload: LogoutPayload;
}

function* logoutSaga({ payload }: LogoutAction) {
    try {
        yield call(AccountRepository.logout);
        if (payload.callback) payload.callback(encodeURIComponent(window.location.href));
        yield put({ type: Logout.Success });
    } catch {
        if (payload.callback) payload.callback(encodeURIComponent(window.location.href));
        yield put({ type: Logout.Success });
    }
}

export default function* authSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(Login.Email.Request, emailLoginSaga);
    yield takeEvery(Login.Google.Request, googleLoginSaga);
    yield takeLatest(Logout.Request, logoutSaga);
}
