import { CodeAllRequest, CodeAllResponse } from '@gamebase-ops/interfaces/codes';
import { ICodeRepository } from '@gamebase-ops/interfaces/codes/interface';
import { Query } from '@gamebase-ops/interfaces/utils';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/codes';
class CodeRepository implements ICodeRepository {
    all(param: Query<CodeAllRequest>): Promise<CodeAllResponse> {
        return get(baseUrl + '/all', new CodeAllRequest(param.query));
    }
}

export default new CodeRepository();
