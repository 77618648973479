import { css } from '@emotion/react';
import { Button } from 'antd';
import { useState } from 'react';

interface IProp {
    title: string;
    children?: any;
}

export default function Dropdown({ title, children }: IProp) {
    const [isShow, setIsShow] = useState<boolean>(false);

    return (
        <Button
            css={wrapper}
            onMouseEnter={() => {
                setIsShow(true);
            }}
            onMouseLeave={() => setIsShow(false)}
        >
            {title} {isShow && <div css={contentWrapper}>{children}</div>}
        </Button>
    );
}

const wrapper = css`
    position: relative;
`;

const contentWrapper = css`
    color: black;
    position: absolute;
    margin-top: 2px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
    border-radius: 4px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5), -2px -2px 6px rgba(0, 0, 0, 0.5);
`;
