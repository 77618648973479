import { css } from '@emotion/react';
import { Table } from 'antd';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { LoadingSpin, QueryLink } from 'components';
import { IUserIdentifier } from '@gamebase-ops/interfaces/gameUser';

interface IProp {
    userList?: IUserIdentifier[];
    onUserIdClick: (id: string) => void;
    linkDisabled?: boolean;
}

const UserListTable: React.FC<IProp> = ({ userList, onUserIdClick, linkDisabled }) => {
    const columns = useMemo<ColumnsType<IUserIdentifier>>(() => {
        return [
            {
                key: 'userId',
                title: 'PID',
                dataIndex: 'userId',
                render(value) {
                    return linkDisabled ? (
                        <span css={userIdStyle} onClick={() => onUserIdClick(value)}>
                            {value}
                        </span>
                    ) : (
                        <QueryLink to={`/project/user/${value}`} css={userId} onClick={() => onUserIdClick(value)}>
                            {value}
                        </QueryLink>
                    );
                },
            },
            {
                key: 'userName',
                title: 'userName',
                render(_, row) {
                    return row.userName;
                },
            },
        ];
    }, [onUserIdClick, linkDisabled]);

    return (
        <>
            {userList ? (
                <Table
                    dataSource={userList.map((user) => ({
                        ...user,
                        key: user.userId,
                    }))}
                    columns={columns}
                />
            ) : (
                <LoadingSpin />
            )}
        </>
    );
};

const userId = css`
    color: #3c3cf8;
    cursor: pointer;
    text-decoration: underline;
`;

const userIdStyle = css`
    color: #3c3cf8;
    cursor: pointer;
`;

export default UserListTable;
