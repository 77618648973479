import { useCallback } from 'react';
import { BlockUserRepository } from 'repository';
import useBlockUserModel from './model/useBlockUser.model';

export default function useBlockUser(projectId: string, limit = 1) {
    const {
        setListQuery: setCurrentQuery,
        data: blockUserResponse,
        listQuery: currentQuery,
    } = useBlockUserModel(projectId, limit);

    const uploadFile = useCallback(
        async (param: FormData) => {
            await BlockUserRepository.upload({ query: { projectId }, body: param });
        },
        [projectId],
    );

    const setPage = useCallback(
        (page: number) => {
            setCurrentQuery((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        page,
                    };
                } else {
                    throw new Error('query의 내용이 채워져야 합니다.');
                }
            });
        },
        [setCurrentQuery],
    );

    const setQueryUserId = useCallback(
        (userId: string | null, limit: number) => {
            setCurrentQuery((prev) => {
                if (prev)
                    return {
                        ...prev,
                        userId: userId || undefined,
                        limit,
                    };
                return {
                    userId: userId || undefined,
                    limit,
                    page: 1,
                    projectId,
                };
            });
        },
        [projectId, setCurrentQuery],
    );

    const setQueryPage = useCallback(
        (param: { page: number; limit: number }) => {
            setCurrentQuery({
                ...param,
            });
        },
        [setCurrentQuery],
    );

    return {
        uploadFile,
        blockUsers: blockUserResponse?.result.data || [],
        totalCount: blockUserResponse?.result.totalCount || 0,
        currentQuery,
        setPage,
        setQueryUserId,
        setQueryPage,
        requestBlockUser: setCurrentQuery,
    };
}
