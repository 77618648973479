import { ProfileDto } from '@gamebase-ops/interfaces/authentication/dto';
import { put, takeLatest, retry } from 'redux-saga/effects';
import { AuthRepository } from 'repository';
import AccountRepository from 'repository/Account.repository';
import AuthenticationRepository from 'repository/Authentication.repository';
import AuthorizationRepository from 'repository/Authorization.repository';

import { MyData } from './action';
interface IGetAccount {
    type: typeof MyData.Request;
    payload: IStoreRequest;
}

function* getAccountSaga({ payload }: IGetAccount) {
    try {
        const { result } = yield retry(2, 500, AuthenticationRepository.profile);
        yield put({ type: MyData.Success, payload: { account: result } });
        if (payload.callback) payload.callback();
    } catch (e) {
        yield put({ type: MyData.Failure });
        if (payload.fallback) payload.fallback();
    }
}

export default function* accountSaga() {
    yield takeLatest(MyData.Request, getAccountSaga);
}
