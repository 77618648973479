import { Layout } from 'antd';
import HeaderMenu from './HeaderMenu';
import classNames from 'classnames/bind';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

interface IProp {
    children: any;
}

const MainLayout = ({ children }: IProp) => {
    return (
        <Layout style={{ flexDirection: 'column' }}>
            <HeaderMenu />
            <section className={cx('main')}>{children}</section>
        </Layout>
    );
};

export default MainLayout;
