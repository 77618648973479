import { Descriptions } from 'antd';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './MailDetailDescription.module.scss';
import { ESystemMailTypeName, MAIL_STATE } from 'constants/constants';
import ItemCard from 'components/item/ItemCard';
import MailContentPreview from './MailContentPreview';
import { mailStatusColor } from 'utils/color';
import { useAllItems, useFormatMessage } from 'hooks';

import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';

const cx = classNames.bind(styles);

interface IProp {
    mailDetail: SystemMailDto | MailDto;
    projectId: string;
}

const MailDetailDescription: React.FC<IProp> = ({ mailDetail, projectId }) => {
    const { messageFormatting } = useFormatMessage();
    const { itemHash } = useAllItems(projectId);

    return (
        <Descriptions bordered style={{ background: 'white' }} contentStyle={{ width: '75%' }} column={1}>
            <Descriptions.Item label={messageFormatting('TITLE')}>{mailDetail.description}</Descriptions.Item>
            <Descriptions.Item label={messageFormatting('USER_CREATED_AT')}>
                {moment(mailDetail.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="전송 시간">
                {mailDetail.state === MAIL_STATE.SUCCESS &&
                    mailDetail.updatedAt &&
                    moment(mailDetail.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label={messageFormatting('TARGET_USER')}>
                <div className={cx('userWrapper')}>
                    {mailDetail instanceof MailDto
                        ? mailDetail.userIds?.map((userPid) => (
                              <div key={userPid} className={cx('userItem')}>
                                  {userPid}
                              </div>
                          ))
                        : messageFormatting('ALL_USER')}
                </div>
            </Descriptions.Item>
            <Descriptions.Item label={messageFormatting('STATUS')}>
                <span style={{ color: mailStatusColor[MAIL_STATE[mailDetail.state]] }}>
                    {MAIL_STATE[mailDetail.state]}
                </span>
            </Descriptions.Item>
            <Descriptions.Item label={messageFormatting('MAIL_PREVIEW')}>
                <MailContentPreview templateId={mailDetail.template.templateId} mailDetail={mailDetail} />
            </Descriptions.Item>
            <Descriptions.Item label="수령 후 만료일">{mailDetail.storeDay} 일</Descriptions.Item>

            <Descriptions.Item label={messageFormatting('ITEM')}>
                <div className={cx('itemListWrapper')}>
                    {mailDetail.items.map(({ amount, itemId }, index) => (
                        <div key={index} className={cx('item')}>
                            {itemHash?.[itemId] && <ItemCard item={itemHash[itemId]} count={amount} />}
                        </div>
                    ))}
                </div>
            </Descriptions.Item>
            <Descriptions.Item label={messageFormatting('SEND_PERIOD')}>
                {mailDetail instanceof SystemMailDto ? (
                    <>
                        {moment(mailDetail.startTime).format('YYYY-MM-DD HH:mm:SS')} ~{' '}
                        {moment(mailDetail.endTime).format('YYYY-MM-DD HH:mm:SS')}
                    </>
                ) : (
                    messageFormatting('SEND_RIGHT_OFF')
                )}
            </Descriptions.Item>
            <Descriptions.Item label="password">
                {mailDetail instanceof SystemMailDto && <div>{mailDetail.eventCode}</div>}
            </Descriptions.Item>
            {mailDetail instanceof SystemMailDto && (
                <>
                    <Descriptions.Item label={messageFormatting('SYSTEM_MAIL_TYPE')}>
                        {messageFormatting(ESystemMailTypeName[mailDetail.conditionType || 0])}
                    </Descriptions.Item>
                    <Descriptions.Item label={messageFormatting('CHECK_DATA')}>
                        {mailDetail.conditionData}
                    </Descriptions.Item>
                </>
            )}
            {/* <Descriptions.Item label={messageFormatting('SEND_URL')}>{mailDetail}</Descriptions.Item> */}
        </Descriptions>
    );
};

export default MailDetailDescription;
