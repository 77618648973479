import { patch } from 'utils/api/client';

const baseUrl = '/api/v1/sheetdatas';

class SheetDataRepository {
    sync(param: { query: { projectId: string } }): Promise<{ url: string }> {
        return patch(baseUrl + '/sync', {}, param.query);
    }
}

export default new SheetDataRepository();
