import { Tabs } from 'antd';
import { findMailTextCanInput } from 'utils/regExp';
import classNames from 'classnames/bind';
import styles from './MailContentPreview.module.scss';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useQueries } from 'hooks';
import useMailTemplateModel from 'hooks/mail/model/useMailTemplate.model';
import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';

const { TabPane } = Tabs;

interface IProp {
    mailDetail?: SystemMailDto | MailDto;

    templateId: number;
}

const cx = classNames.bind(styles);

const MailContentPreview: React.FC<IProp> = ({ templateId, mailDetail }) => {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    const { getByTemplateId } = useMailTemplateModel(projectId);

    return (
        <div>
            <Tabs type="card">
                {projectDetail?.languages?.map((country) => {
                    const key = country.toLowerCase() as keyof IGlobalText;
                    const titleText = getByTemplateId(templateId)?.senders[key];
                    const senderText = getByTemplateId(templateId)?.texts[key];
                    const contentText = getByTemplateId(templateId)?.titles[key];

                    const customTextTitle = mailDetail?.titles ? mailDetail.titles[key] : '';

                    const customTextSender = mailDetail?.senders ? mailDetail.senders[key] : '';

                    const customTextContent = mailDetail?.texts ? mailDetail.texts[key] : '';

                    return (
                        <TabPane key={country} tab={country}>
                            <div className={cx('textSection')}>
                                제목: {titleText && titleText.replace(findMailTextCanInput, customTextTitle || '')}
                            </div>
                            <div className={cx('textSection')}>
                                발송인: {senderText && senderText.replace(findMailTextCanInput, customTextSender || '')}
                            </div>
                            <div className={cx('textSection')}>
                                내용:{' '}
                                {contentText && contentText.replace(findMailTextCanInput, customTextContent || '')}
                            </div>
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default MailContentPreview;
