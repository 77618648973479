import { MyPermissionDto } from '@gamebase-ops/interfaces/authorization';
import { useCallback, useState } from 'react';
import { AuthorizationRepository } from 'repository';

export default function useMyPermissions() {
    const [permissions, setPermissions] = useState<MyPermissionDto[]>([]);

    const requestMyPermissions = useCallback(async () => {
        const result = await AuthorizationRepository.permissionList();

        setPermissions(result.result);
    }, []);

    return { permissions, requestMyPermissions };
}
