import { CodeDto } from '@gamebase-ops/interfaces/codes';
import { MAJOR_CODE } from 'constants/constants';
import { useCallback, useState } from 'react';
import { CodeRepository } from 'repository';

export default function useEventCode(projectId: string) {
    const [eventCodes, setEventCodes] = useState<CodeDto[]>();

    const requestByMajorCode = useCallback(
        async (majorCode: MAJOR_CODE) => {
            const result = await CodeRepository.all({
                query: { projectId, majorCode },
            });
            setEventCodes(result.result);
        },
        [projectId],
    );

    return {
        eventCodes,
        requestByMajorCode,
    };
}
