import { Menu, Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import QueryLink from 'components/utils/QueryLink';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './SideBar.module.scss';
import classNames from 'classnames/bind';
import { icon } from 'constants/constants';
import { useFormatMessage } from 'hooks';

interface IProp {
    sideBarMenu: Array<ILinkProps>;
}

const cx = classNames.bind(styles);

const SideBar: React.FC<IProp> = ({ sideBarMenu }) => {
    const { pathname } = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { messageFormatting } = useFormatMessage();

    return (
        <>
            <div className={cx('dummy', { isCollapsed })} />
            <div className={cx('fixedSection')}>
                <Menu className={cx('menu')} theme="dark" selectedKeys={[pathname]} inlineCollapsed={isCollapsed}>
                    {sideBarMenu.map(({ title, link }) => (
                        <Menu.Item
                            key={link}
                            icon={
                                icon[title] && <img className={cx('icon')} width="32" height="32" src={icon[title]} />
                            }
                        >
                            <QueryLink to={link}>{messageFormatting(title)}</QueryLink>
                        </Menu.Item>
                    ))}
                </Menu>
                <Button type="primary" onClick={() => setIsCollapsed((prev) => !prev)}>
                    {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
            </div>
        </>
    );
};

export default SideBar;
