import { useRef } from 'react';
import { Input, Form, List, Button } from 'antd';
import { ModalWrapper } from 'components';

import classNames from 'classnames/bind';
import styles from './PermissionCreateModal.module.scss';
import { useState } from 'react';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';

import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';
import { MyProjectDto } from '@gamebase-ops/interfaces/authorization';

const cx = classNames.bind(styles);

interface IProp {
    visible: boolean;
    onClose: () => void;
    onSubmit?: (param: IPostPermission) => void;
    projects: MyProjectDto[];
    serviceAuths: ServiceAuthDto[];
}

const PermissionCreateModal: React.FC<IProp> = ({ visible, onClose, onSubmit, projects, serviceAuths }) => {
    const nameRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);

    const [selectProject, setSelectProject] = useState<string>('');
    const [selectServiceAuth, setSelectServiceAuth] = useState<string>('');

    const [filterProjectStr, setFilterProjectStr] = useState('');
    const [filterServiceAuthStr, setFilterServiceAuthStr] = useState('');

    const submitButtonClickListener = useCallback(() => {
        if (nameRef.current && descriptionRef.current) {
            if (!nameRef.current.state.value) {
                notificationPush({ status: 'error', title: 'input error', message: '이름을 입력해주세요' });
                return;
            }

            if (!selectProject) {
                notificationPush({ status: 'error', title: 'input error', message: '프로젝트를 선택해주세요' });
                return;
            }

            if (!selectServiceAuth) {
                notificationPush({ status: 'error', title: 'input error', message: '서비스를 선택해주세요' });
                return;
            }

            if (onSubmit)
                onSubmit({
                    name: nameRef.current.state.value,
                    description: descriptionRef.current.state.value || '',
                    project: selectProject,
                    serviceAuth: selectServiceAuth,
                });
        }
    }, [selectProject, selectServiceAuth, onSubmit]);

    return (
        <ModalWrapper visible={visible} title="권한 생성" onCancel={onClose} width="70%">
            <section className={cx('modalWrapper')}>
                <Form.Item label="권한 명" className={cx('formItem')}>
                    <Input ref={nameRef} className={cx('input')} placeholder="권한 명을 입력해주세요" />
                </Form.Item>
                <Form.Item label="권한 설명" className={cx('formItem')}>
                    <Input ref={descriptionRef} className={cx('input')} placeholder="간단한 권한 설명을 넣어주세요" />
                </Form.Item>
                <section className={cx('selectContainer')}>
                    <section className={cx('selectWrapper')}>
                        <section className={cx('selectHeader')}>
                            <h2>프로젝트</h2>
                            <Input
                                className={cx('headerInput')}
                                placeholder="필터링 할 텍스트를 입력해주세요"
                                onChange={(e) => setFilterProjectStr(e.target.value)}
                            />
                        </section>
                        <div className={cx('listWrapper')}>
                            <List
                                dataSource={projects.filter((project) => project.name.match(filterProjectStr))}
                                renderItem={(item) => (
                                    <List.Item
                                        key={item.id}
                                        className={cx({ selected: selectProject === item.id }, 'item')}
                                    >
                                        <section onClick={() => setSelectProject(item.id)}>{item.name}</section>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </section>

                    <section className={cx('selectWrapper')}>
                        <section className={cx('selectHeader')}>
                            <h2>서비스</h2>
                            <Input
                                className={cx('headerInput')}
                                placeholder="필터링 할 텍스트를 입력해주세요"
                                onChange={(e) => setFilterServiceAuthStr(e.target.value)}
                            />
                        </section>
                        <div className={cx('listWrapper')}>
                            <List
                                dataSource={serviceAuths.filter((service) =>
                                    service.service.match(filterServiceAuthStr),
                                )}
                                renderItem={(item) => (
                                    <List.Item
                                        key={item.id}
                                        className={cx({ selected: selectServiceAuth === item.id }, 'item')}
                                    >
                                        <section onClick={() => setSelectServiceAuth(item.id)}>{item.service}</section>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </section>
                </section>
                <footer className={cx('footer')}>
                    <Button type="primary" onClick={submitButtonClickListener} className={cx('button')}>
                        생성
                    </Button>
                    <Button onClick={onClose}>취소</Button>
                </footer>
            </section>
        </ModalWrapper>
    );
};

export default PermissionCreateModal;
