import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from 'store/modules/lang/action';

export default function useLang() {
    const dispatch = useDispatch();

    const { lang } = useSelector<RootStore, LangStore>(({ lang }) => lang);

    const langChange = useCallback(
        (payload: string) => {
            const root = document.querySelector('#root') as HTMLBodyElement;
            const classList = [...root.classList.values()];
            classList.map((className) => root.classList.remove(className));
            root.classList.add(payload.toUpperCase());

            const html = document.querySelector('html') as HTMLElement;
            html.lang = payload;

            dispatch(setLang(payload));
        },
        [dispatch],
    );

    return { lang, langChange };
}
