import { notificationPush } from 'utils/notificationUtils';
import { useCallback } from 'react';
import useGetServiceList from 'hooks/serviceAuth/model/useGetServiceList';
import { ServiceRepository } from 'repository';
import { COMMON_PROJECT_ID } from 'constants/constants';

export default function useServiceAuth() {
    const { data: serviceAuths, refetch } = useGetServiceList();

    const createServiceAuth = useCallback(
        async (param: ICreateServiceAuthDto) => {
            await ServiceRepository.create({ body: param });
            notificationPush({ status: 'success', message: '서비스가 추가 되었습니다.' });
            refetch();
        },
        [refetch],
    );

    const changeServiceAuth = useCallback(
        async (param: IChangeServiceAuthDto) => {
            await ServiceRepository.update({ body: { id: param.oid, ...param } });
            notificationPush({ status: 'success', message: '서비스가 수정 되었습니다.' });
            refetch();
        },
        [refetch],
    );

    const deleteServiceAuth = useCallback(
        async (param: string) => {
            if (param && window.confirm('정말로 삭제하시겠습니까?')) {
                await ServiceRepository.delete({ query: { id: param, projectId: COMMON_PROJECT_ID } });
                notificationPush({ status: 'success', message: '삭제 완료했습니다.' });
                refetch();
                return true;
            } else {
                return false;
            }
        },
        [refetch],
    );

    return {
        serviceAuths: serviceAuths?.result,
        createServiceAuth,
        changeServiceAuth,
        deleteServiceAuth,
    };
}
