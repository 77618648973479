import { IsIn, IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class ProjectPassDto {
    constructor(param: ProjectPassDto) {
        this.projectId = param.projectId;
        this.type = param.type;
    }
    @IsNotEmpty()
    @IsString()
    projectId: string;

    @IsNotEmpty()
    @IsIn(['season', 'class'])
    type: 'season' | 'class';
}
