import { notificationPush } from 'utils/notificationUtils';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

class ErrorHandler {
    errorHandle?: Function;

    setErrorHandler(handler: any) {
        this.errorHandle = handler;
    }
}

export const errorHandler = new ErrorHandler();

export default function useError() {
    const intl = useIntl();

    const isObjectString = useCallback((str: string) => {
        try {
            const result = JSON.parse(str);
            return result;
        } catch (e) {
            return false;
        }
    }, []);

    const errorHandle = useCallback(
        (ev: PromiseRejectionEvent) => {
            if (!ev.reason.ignoreAlert) {
                const messageObject = isObjectString(ev.reason.message);

                notificationPush({
                    status: 'error',
                    message: intl.formatMessage(
                        {
                            id: messageObject ? messageObject.id : ev.reason.message,
                        },
                        messageObject && messageObject.values,
                    ),
                    title: ev.reason.code || 500,
                });
            }
        },
        [intl, isObjectString],
    );

    useEffect(() => {
        errorHandler.setErrorHandler(errorHandle);
    }, [errorHandle]);

    return { errorHandle };
}
