import { Input, Form, Button, Transfer } from 'antd';
import { useState, useCallback, useRef } from 'react';
import { useAccounts, useRoleDetail } from 'hooks';
import { ModalWrapper } from 'components';
import classNames from 'classnames/bind';
import AccountDropDown from './AccountDropDown';

import styles from './RoleEditModal.module.scss';
import UserBadge from './UserBadge';
import { AccountBasicDto } from '@gamebase-ops/interfaces/account';
import { MyPermissionDto } from '@gamebase-ops/interfaces/authorization';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    roleId?: string;
    onSubmit?: (arg: ICreateRoleDto) => void;
    onDelete?: (roleId: string) => void;
    permissions: MyPermissionDto[];
}

const { Search } = Input;

const RoleEditModal: React.FC<IProp> = ({ onSubmit, roleId, onCancel, visible, title, permissions, onDelete }) => {
    const [dropDownVisible, setDropDownVisible] = useState(false);

    const { accounts: searchResultAccounts, requestAccountsByKeyword } = useAccounts();
    const searchRef = useRef<any>();

    const {
        permissions: rolePermissions,
        accounts: roleAccounts,
        roleNameRef,
        descriptionRef,
        addAccounts,
        removeAccounts,
        setPermissions: setRolePermissions,
        role,
    } = useRoleDetail(roleId);

    const onSearch = useCallback(
        (keyword: string) => {
            setDropDownVisible(true);
            requestAccountsByKeyword(keyword);
        },
        [requestAccountsByKeyword],
    );

    const onAccountItemClick = useCallback(
        (param: AccountBasicDto) => {
            setDropDownVisible(false);
            addAccounts(param);
        },
        [addAccounts],
    );

    const removeClickListener = useCallback(async () => {
        if (
            roleId &&
            window.confirm('정말로 삭제 하시겠습니까? \r\n삭제하시면 역할을 가지고 있는 모든 유저로부터 삭제 됩니다.')
        ) {
            onDelete && onDelete(roleId);
        }
    }, [onDelete, roleId]);

    const submitClickListener = useCallback(() => {
        if (onSubmit && window.confirm('정말로 제출 하시겠습니까?')) {
            if (roleNameRef.current && descriptionRef.current) {
                if (roleAccounts && rolePermissions) {
                    onSubmit({
                        oid: roleId,
                        name: roleNameRef.current.input.value,
                        description: descriptionRef.current.input.value,
                        accounts: roleAccounts.map(({ id }) => id),
                        permissions: rolePermissions.map(({ id }) => id),
                    });
                }
            }
        }
    }, [onSubmit, roleAccounts, rolePermissions, roleNameRef, descriptionRef, roleId]);
    if (roleId && !role) return null;
    return (
        <ModalWrapper
            visible={visible}
            title={title}
            onCancel={onCancel}
            onClick={() => setDropDownVisible(false)}
            width="800px"
        >
            <Form.Item label="역할명">
                <Input ref={roleNameRef as never} placeholder="역할명" defaultValue={role?.name} />
            </Form.Item>
            <Form.Item label="역할 설명">
                <Input ref={descriptionRef as never} placeholder="설명" defaultValue={role?.description} />
            </Form.Item>
            <div className={cx('section')}>
                <h1>계정</h1>
                <div className={cx('userBadgeWrapper')}>
                    {roleAccounts?.map((account) => (
                        <UserBadge key={account.id} account={account} onClick={removeAccounts} />
                    ))}
                </div>
                <Search
                    placeholder="아이디나 별명을 입력해주세요"
                    onSearch={onSearch}
                    onFocusCapture={() => setDropDownVisible(false)}
                    ref={searchRef as never}
                />
                {dropDownVisible && <AccountDropDown accounts={searchResultAccounts} onClick={onAccountItemClick} />}
            </div>

            <section className={cx('permissionHeader')}>
                <h1>권한</h1>
            </section>
            <div className={cx('section', 'permission')}>
                <Transfer
                    style={{ width: '100%' }}
                    dataSource={permissions.map((permission) => ({ ...permission, key: permission.id }))}
                    showSearch
                    render={(item) => item.name}
                    targetKeys={rolePermissions?.map(({ id }) => id)}
                    filterOption={(value, option) => option.name.match(value) !== null}
                    listStyle={{
                        width: 400,
                        height: 300,
                    }}
                    operations={['추가', '삭제']}
                    onChange={(targetKeys) => {
                        const nextRolePermissions = permissions.filter(({ id }) =>
                            targetKeys.find((key) => key === id),
                        );
                        setRolePermissions(nextRolePermissions);
                    }}
                />
            </div>
            <footer className={cx('footer')}>
                <Button type="primary" onClick={submitClickListener} size="large">
                    제출
                </Button>

                {roleId && (
                    <Button type="primary" danger size="large" onClick={removeClickListener}>
                        삭제
                    </Button>
                )}
            </footer>
        </ModalWrapper>
    );
};

export default RoleEditModal;
