import { PackageCreateDto, PackageItem, PackageListQuery, PackageUpdateDto } from 'dto/package.dto';
import { useScreenLock } from 'hooks';
import { useCallback, useState } from 'react';
import { PackageRepository } from 'repository';
import { useQuery } from 'react-query';
import { notificationPush } from 'utils/notificationUtils';

export default function useItemPackage(projectId: string) {
    const [packageListQuery, setPackageListQuery] = useState<PackageListQuery | null>(null);
    const { data: packageList, refetch: packageListMutate } = useQuery(['pacakgesList', packageListQuery], () =>
        packageListQuery ? PackageRepository.getAll(packageListQuery) : null,
    );

    const [packageDetail, setPackageDetail] = useState<PackageItem>();

    const { withScreenLockCall } = useScreenLock();

    const setPageNumber = useCallback((page: number) => {
        setPackageListQuery((prev) => {
            if (prev) {
                return {
                    ...prev,
                    page,
                };
            }
            return null;
        });
    }, []);

    const createPackage = useCallback(
        async (param: Omit<PackageCreateDto, 'projectId'>) => {
            await withScreenLockCall(PackageRepository.create, { ...param, projectId });
            notificationPush({ status: 'info', title: '아이템 패키지가 생성 되었습니다.' });
            if (packageListQuery?.page === 1) packageListMutate();
            else setPageNumber(1);
        },
        [projectId, withScreenLockCall, packageListMutate, packageListQuery?.page, setPageNumber],
    );

    const deletePackage = useCallback(
        async (oid: string) => {
            await withScreenLockCall(PackageRepository.delete, oid, projectId);
            notificationPush({ status: 'info', title: '아이템 패키지가 삭제 되었습니다.' });
            if (packageListQuery?.page === 1) packageListMutate();
            else setPageNumber(1);
        },
        [projectId, withScreenLockCall, packageListMutate, packageListQuery?.page, setPageNumber],
    );

    const patchPackage = useCallback(
        async (oid: string, params: PackageUpdateDto) => {
            await withScreenLockCall(PackageRepository.patch, oid, params, projectId);
            notificationPush({ status: 'info', title: '아이템 패키지가 수정 되었습니다.' });
            if (packageListQuery?.page === 1) packageListMutate();
            else setPageNumber(1);
        },
        [projectId, withScreenLockCall, packageListMutate, packageListQuery?.page, setPageNumber],
    );

    const packageDetailFetch = useCallback(
        async (packageOid?: string) => {
            if (packageOid) {
                const result = await PackageRepository.getDetail(packageOid, projectId);
                setPackageDetail(result);
            } else {
                setPackageDetail(undefined);
            }
        },
        [projectId],
    );

    return {
        packageList,
        packageDetail,
        createPackage,
        deletePackage,
        patchPackage,
        setPageNumber,
        packageListMutate,
        setPackageDetail,
        currentPage: packageListQuery?.page,
        packageDetailFetch,
        setPackageListQuery,
    };
}
