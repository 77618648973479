import { css } from '@emotion/react';
import React from 'react';

interface IProp {
    children?: string | JSX.Element;
}

export default function DescriptionHeader({ children }: IProp): JSX.Element {
    return <div css={wrapper}>{children}</div>;
}

const wrapper = css`
    padding: 8px 16px;
    background: #151e96;
    color: white;
    text-align: center;
    min-height: 14px;
`;
