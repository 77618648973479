import { handleActions } from 'redux-actions';
import { Lang } from './action';

const initState: LangStore = {
    lang: '',
};

export default handleActions(
    {
        [Lang.set]: (_, { payload }: { payload: string }) => {
            return { lang: payload };
        },
    },
    initState,
);
