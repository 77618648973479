import { IWebviewNotice, WebviewNoticeCreateDto } from 'dto/webviewNotice.dto';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useCallback, useRef, useState } from 'react';
import { NoticeRepository } from 'repository';

/**
 *
 * 웹뷰 공지를 작성할 때, 필요한 비즈니스 로직들을 담은 hook
 */
export default function useWebviewNoticeEdit(projectId: string, noticeWebviewData?: IWebviewNotice) {
    const { projectDetail } = useProjectDetail(projectId);

    // 국가 항목 선택과 변경 즉시 화면에 재렌더 해야하는 텍스트만 state로 관리합니다.
    const [selectedLang, setSelectedLang] = useState<string>('ko');

    // request를 보내야하는 객체들에 대해서는 state가 아닌 Ref로 관리하여 재렌더를 최소화합니다.
    const webviewNoticeCreateDto = useRef<WebviewNoticeCreateDto>(
        new WebviewNoticeCreateDto({
            contents: noticeWebviewData?.contents ? { ...noticeWebviewData?.contents } : {},
            mainImages: noticeWebviewData?.mainImages ? { ...noticeWebviewData?.mainImages } : {},
            titles: noticeWebviewData?.titles ? { ...noticeWebviewData?.titles } : {},
            defaultLanguage: '',
            startTime: new Date(),
            endTime: new Date(),
        }),
    );
    const currentNoticeValues = useRef<Record<string, string>>({});
    const titleImages = useRef<Record<string, File>>({});

    const setNoticeEditorValues = useCallback(
        (lang: string) => (text: string) => {
            currentNoticeValues.current[lang] = text;
        },
        [],
    );

    const uploadImage = useCallback(
        async (file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            const { result } = await NoticeRepository.uploadImage({
                query: {
                    projectId,
                },
                body: formData,
            });
            return result;
        },
        [projectId],
    );

    // 타이틀 이미지 순회 돌면서 업데이트 하고 언어별 타이틀 이미지를 리턴
    const uploadTitleImage = useCallback(async () => {
        const languages = Object.keys(titleImages.current);
        const titleImageUrls: Record<string, string> = {};
        for (let i = 0; i < languages.length; i++) {
            const lang = languages[i];

            const targetImage = titleImages.current[lang];
            if (targetImage) {
                const result = await uploadImage(targetImage);
                titleImageUrls[lang] = result;
                webviewNoticeCreateDto.current.mainImages[lang] = result;
            }
        }

        return titleImageUrls;
    }, [uploadImage]);

    /**
     * html을 업로드하고 url을 저장합니다.
     * 만약 link인 경우 url만 저장합니다.
     */
    const uploadHTML = useCallback(async () => {
        const languages = projectDetail?.languages || [];

        for (let i = 0; i < languages.length; i++) {
            const lang = languages[i];
            const htmlString = currentNoticeValues.current[lang];
            const { result } = await NoticeRepository.uploadHtml({
                query: {
                    projectId,
                },
                body: {
                    htmlString: htmlString || '<div></div>',
                },
            });
            webviewNoticeCreateDto.current.contents[lang] = result;
        }
    }, [projectDetail, projectId]);

    const setTitleImages = useCallback(
        (lang: string) => (file: File) => {
            if (file) {
                titleImages.current[lang] = file;
            }
        },
        [],
    );

    const setPreferLanguage = useCallback(
        (preferLang: string, targetLang: string) => {
            if (!titleImages.current[targetLang] && !webviewNoticeCreateDto.current.mainImages[targetLang]) {
                titleImages.current[targetLang] = titleImages.current[preferLang];
            }
            if (!currentNoticeValues.current[targetLang]) {
                setNoticeEditorValues(targetLang)(currentNoticeValues.current[preferLang]);
            }
        },
        [setNoticeEditorValues],
    );

    return {
        setNoticeEditorValues,
        uploadTitleImage,
        uploadHTML,
        webviewNoticeCreateDto: webviewNoticeCreateDto.current,
        setTitleImages,
        uploadImage,
        selectedLang,
        setSelectedLang,
        setPreferLanguage,
    };
}
