/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import { useCallback } from 'react';
import { useMemo } from 'react';

interface IOptions {
    auths?: IAuthCheck[];
    isAllConditionCheck?: boolean;
}

interface IProp {
    auths?: IAuthCheck[];
}

/**
 * 페이지에서 최소로 가지고 있어야하는 권한을 체크하는 Hoc
 */
const PageViewAuthHoc =
    (Component: React.FC<IProp>, options: IOptions) =>
    ({ auths }: IProp) => {
        const authDiffCheck = useCallback((srcAuth, destAuth) => {
            let result = true;
            if (srcAuth.service !== destAuth.service) result = false;
            if (srcAuth.level < destAuth.level) result = false;
            if (destAuth.projectId !== undefined) {
                if (srcAuth.projectId !== destAuth.projectId) result = false;
            }
            return result;
        }, []);

        const condition = useMemo(() => {
            if (!options.auths || options.auths.length === 0) return true;
            return options.auths.reduce((acc, auth) => {
                const findResult = auths?.find((canAuth) => authDiffCheck(canAuth, auth)) ? true : false;
                const nextVal = options.isAllConditionCheck ? findResult && acc : findResult || acc;
                return nextVal;
            }, options.isAllConditionCheck || false);
        }, [auths, authDiffCheck]);

        if (condition) return <Component auths={auths} />;
        return <div>권한이 없습니다.</div>;
    };

export default PageViewAuthHoc;
