/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import { Input, Menu } from 'antd';
import { useFormatMessage } from 'hooks';
import { useCallback, useState } from 'react';
import { notificationPush } from 'utils/notificationUtils';

interface IProp {
    onSearch: (key: string, value: string) => void;
    isRemoveGuid?: boolean;
}

const UserSearchForm: React.FC<IProp> = ({ onSearch, isRemoveGuid }) => {
    const { messageFormatting } = useFormatMessage();
    const [key, setKey] = useState('pid');

    const onMenuClick = useCallback(({ key }: { key: string }) => {
        setKey(key);
    }, []);

    const onInputSearch = useCallback(
        (value: string) => {
            if (value) {
                onSearch(key, value);
            } else {
                notificationPush({ status: 'error', message: '입력을 확인해주세요.' });
            }
        },
        [key, onSearch],
    );

    return (
        <article css={wrapper}>
            <Menu onClick={onMenuClick} mode="horizontal" defaultSelectedKeys={['pid']}>
                <Menu.Item key="pid">PID</Menu.Item>
                <Menu.Item key="name">NickName</Menu.Item>
                {!isRemoveGuid && <Menu.Item key="guid">{messageFormatting('GUID')}</Menu.Item>}
            </Menu>
            <Input.Search onSearch={onInputSearch} />
        </article>
    );
};

const wrapper = css`
    width: 40%;
`;

export default UserSearchForm;
