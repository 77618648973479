import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';
import { MAIL_TYPE } from 'constants/constants';
import { useCallback, useState } from 'react';
import { MailRepository } from 'repository';

export default function useMailDetail(projectId: string) {
    const [mailDetail, setMailDetail] = useState<SystemMailDto | MailDto>();

    const requestMailDetail = useCallback(
        async (oid?: string) => {
            if (oid) {
                let result = null;
                try {
                    const data = await MailRepository.infoMail({ query: { id: oid, projectId } });
                    result = new MailDto(data.result);
                } catch {
                    const data = await MailRepository.infoSystemMail({ query: { id: oid, projectId } });
                    result = new SystemMailDto(data.result);
                }

                setMailDetail(result);
                return result;
            } else {
                setMailDetail(undefined);
            }
        },
        [projectId],
    );

    const patchTargetSendInMailDetail = useCallback(
        async (oid: string) => {
            await MailRepository.sendMail({ query: { projectId }, body: { id: oid } });
        },
        [projectId],
    );

    const patchSystemSendInMailDetail = useCallback(
        async (oid: string) => {
            await MailRepository.sendSystemMail({ query: { projectId }, body: { id: oid } });
        },
        [projectId],
    );

    const mailDetailSendApiCall = useCallback(
        async (type: MAIL_TYPE, oid: string) => {
            if (type === MAIL_TYPE.SYSTEM) {
                await patchSystemSendInMailDetail(oid);
            } else {
                await patchTargetSendInMailDetail(oid);
            }
        },
        [patchSystemSendInMailDetail, patchTargetSendInMailDetail],
    );

    return { mailDetail, mailDetailSendApiCall, requestMailDetail };
}
