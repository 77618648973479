import { AccountBasicDto } from '@gamebase-ops/interfaces/account';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames/bind';
import styles from './AccountDropDown.module.scss';
interface IProp {
    accounts: AccountBasicDto[];
    onClick?: (arg: AccountBasicDto) => void;
}

const cx = classNames.bind(styles);

const AccountDropDown: React.FC<IProp> = ({ accounts, onClick }) => {
    return (
        <Dropdown
            visible
            overlay={
                <Menu className={cx('wrapper')}>
                    {accounts.map((account, index) => (
                        <Menu.Item
                            key={account.id || index}
                            onClick={() => {
                                onClick && onClick(account);
                            }}
                        >
                            <div>{account.name}</div>
                            <div>{account.userId}</div>
                        </Menu.Item>
                    ))}
                </Menu>
            }
        >
            <div></div>
        </Dropdown>
    );
};

export default AccountDropDown;
