import { Layout } from 'antd';
import classNames from 'classnames/bind';
import { useFormatMessage } from 'hooks';
import styles from './Layout.module.scss';
import SideBar from './SideBar';

const cx = classNames.bind(styles);

const MyPageLayout: React.FC = ({ children }) => {
    const { messageFormatting } = useFormatMessage();
    return (
        <section className={cx('mainWrapper')}>
            <SideBar sideBarMenu={[{ link: '/myPage', title: messageFormatting('MYPAGE') }]} />
            <Layout.Content className={cx('contentMain')}>{children}</Layout.Content>
        </section>
    );
};

export default MyPageLayout;
