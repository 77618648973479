import { IPurchaseLogRepository } from '@gamebase-ops/interfaces/purchaseLog/interface';
import { PurchaseLogAllRequest } from '@gamebase-ops/interfaces/purchaseLog/request';
import { PurchaseLogAllResponse } from '@gamebase-ops/interfaces/purchaseLog/response';
import { Query } from '@gamebase-ops/interfaces/utils';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/purchase-logs';

class PurchaseLogRepository implements IPurchaseLogRepository {
    all(param: Query<PurchaseLogAllRequest>): Promise<PurchaseLogAllResponse> {
        return get(baseUrl + '/all', new PurchaseLogAllRequest(param.query));
    }
}

export default new PurchaseLogRepository();
