import { Form } from 'antd';
import { ESystemMailType } from 'constants/constants';
import { useFormatMessage } from 'hooks';
import { useState } from 'react';
import SystemMailTypeCheckData from './SystemMailTypeCheckData';

interface IProp {
    systemMailType?: ESystemMailType;
    checkData?: string | number;
}

export default function SystemMailTypeEdit({ systemMailType, checkData }: IProp) {
    const { messageFormatting } = useFormatMessage();

    const [selectedSystemMailType, setSelectedSystemMailType] = useState(systemMailType);

    return (
        <>
            <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('SYSTEM_MAIL_TYPE')}>
                <select
                    value={selectedSystemMailType}
                    onChange={(e) => setSelectedSystemMailType(parseInt(e.target.value))}
                    name="conditionType"
                >
                    <option value={ESystemMailType.None}>{messageFormatting('NO_SELECT')}</option>
                    <option value={ESystemMailType.AppVersion}>{messageFormatting('APP_VERSION')}</option>
                    <option value={ESystemMailType.EqualCreateUser}>{messageFormatting('EQUAL_CREATE_USER')}</option>
                    <option value={ESystemMailType.LessThanCreateUser}>
                        {messageFormatting('LESS_THAN_CREATE_USER')}
                    </option>
                    <option value={ESystemMailType.GreateThanCreateUser}>
                        {messageFormatting('GREATE_THAN_CREATE_USER')}
                    </option>
                    <option value={ESystemMailType.EqualClassId}>{messageFormatting('EQUAL_CLASS_ID')}</option>
                    <option value={ESystemMailType.LessThanClassId}>{messageFormatting('LESS_TAN_CLASS_ID')}</option>
                    <option value={ESystemMailType.GreaterThanClassId}>
                        {messageFormatting('GREATER_THAN_CLASS_ID')}
                    </option>
                </select>
            </Form.Item>
            <Form.Item label={messageFormatting('CHECK_DATA')}>
                <SystemMailTypeCheckData systemMailType={selectedSystemMailType} defaultValue={checkData} />
            </Form.Item>
        </>
    );
}
