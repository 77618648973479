import { ItemDto } from '@gamebase-ops/interfaces/item/dto';
import classNames from 'classnames/bind';
import { notImage } from 'constants/constants';
import { useCallback } from 'react';
import { getItemName } from 'utils/item';
import styles from './ItemCard.module.scss';

interface IProp {
    item: ItemDto;
    onClick?: (item: ItemDto) => void;
    count?: number;
}

const cx = classNames.bind(styles);

const ItemCard: React.FC<IProp> = ({ item, count, onClick }) => {
    const onError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = notImage;
    }, []);

    return (
        <section className={cx('wrapper')} onClick={() => onClick && onClick(item)}>
            <header className={cx('header')}>
                <img className={cx('image')} src={notImage} onError={onError} alt="아이템 이미지" />
                <div className={cx('idTag')}>No.{item.itemId}</div>
            </header>
            <section className={cx('body')}>
                <div className={cx('name')}>{getItemName(item) || item.itemId}</div>
                {count && (
                    <div className={cx('description')}>
                        <span className={cx('title')}>count</span>
                        {count}
                    </div>
                )}
            </section>
        </section>
    );
};

export default ItemCard;
