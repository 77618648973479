import { ProjectIdQueryDto } from 'dto/utils.dto';
import {
    IWebviewNotice,
    WebviewNoticeCreateDto,
    WebviewNoticeQueryDto,
    WebviewNoticeSortDto,
} from 'dto/webviewNotice.dto';
import { get, patch, post } from 'utils/api/client';

const baseURL = '/api/v1/webviewNotice';

class WebviewNoticeRepository {
    find(param: WebviewNoticeQueryDto) {
        return get<{ webviewNotices: IWebviewNotice[]; totalCount: number; lastPage: number }>(
            baseURL,
            new WebviewNoticeQueryDto(param),
        );
    }

    findPublished(projectId: string) {
        return get<IWebviewNotice[]>(`${baseURL}/published`, new ProjectIdQueryDto(projectId));
    }

    create(param: WebviewNoticeCreateDto, projectId: string) {
        return post(`${baseURL}`, new WebviewNoticeCreateDto(param), new ProjectIdQueryDto(projectId));
    }

    update(oid: string, param: WebviewNoticeCreateDto, projectId: string) {
        return patch(`${baseURL}/${oid}`, new WebviewNoticeCreateDto(param), new ProjectIdQueryDto(projectId));
    }

    publish(oid: string, projectId: string) {
        return patch(`${baseURL}/${oid}/publish`, {}, new ProjectIdQueryDto(projectId));
    }

    unpublish(oid: string, projectId: string) {
        return patch(`${baseURL}/${oid}/unpublish`, {}, new ProjectIdQueryDto(projectId));
    }

    sortedPublishedNotice(param: WebviewNoticeSortDto, projectId: string) {
        return patch(`${baseURL}/publish/sort`, new WebviewNoticeSortDto(param), new ProjectIdQueryDto(projectId));
    }
}

export default new WebviewNoticeRepository();
