import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class HTMLUploadDto {
    constructor(param: HTMLUploadDto) {
        this.htmlString = param.htmlString;
        this.path = param.path;
        this.fileName = param.fileName;
    }
    @IsNotEmpty()
    @IsString()
    htmlString: string;

    @IsNotEmpty()
    @IsString()
    path: string;

    @IsOptional()
    @IsString()
    fileName?: string;
}
