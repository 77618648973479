import { useProjectCategory, useQueries } from 'hooks';

import { useEffect } from 'react';

import SideBar from './SideBar';
import styles from './Layout.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ProjectCategoryLayout: React.FC = ({ children }) => {
    const { projectId } = useQueries();

    const { requestProjectCategories, categories } = useProjectCategory();

    useEffect(() => {
        requestProjectCategories(projectId);
    }, [requestProjectCategories, projectId]);

    return (
        <section className={cx('mainWrapper')}>
            <SideBar sideBarMenu={categories} />

            <section className={cx('contentMain')}>{children}</section>
        </section>
    );
};

export default ProjectCategoryLayout;
