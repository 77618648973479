import { css } from '@emotion/react';
import { Modal, Steps } from 'antd';
import { NOTICE_KEY, NOTICE_UNION } from 'constants/constants';
import { ICreateNoticePostDto } from 'dto/notice.dto';
import { useFormatMessage, useQueries } from 'hooks';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useState } from 'react';
import { classValidate } from 'utils/validation';
import FirstStep from './steps/components/firstStep';
import SecondStep from './steps/components/secondStep';

interface IProp {
    type: string;
    defaultPublishData: ICreateNotice | null;
    onFinish: (publishData: ICreateNotice, type: string) => void;
    stepType: string;
    visible: boolean;
    onCancel: () => void;
}

export default function NoticeForm({ type, defaultPublishData, stepType, onFinish, visible, onCancel }: IProp) {
    const [publishData, setPublishData] = useState<ICreateNotice | null>(defaultPublishData);
    const [current, setCurrent] = useState(stepType === 'detail' ? 1 : 0);
    const { messageFormatting } = useFormatMessage();
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    const onSubmit = (stepData: any) => {
        const validateDto = new ICreateNoticePostDto({
            ...publishData,
            ...stepData,
            type: NOTICE_UNION[type.toUpperCase()],
        });

        const result = classValidate(validateDto);

        if (!result.isSuccess) throw result;

        setPublishData({ ...publishData, ...stepData, type: NOTICE_UNION[type.toUpperCase()] });
        setCurrent(current + 1);
    };

    const steps = [
        {
            step: 1,
            title: messageFormatting('NOTICE_CREATE'),
            content: (
                <FirstStep
                    languages={projectDetail?.languages}
                    publishData={publishData}
                    onSubmit={(stepData) => onSubmit(stepData)}
                    type={type}
                />
            ),
        },
        {
            step: 2,
            title: messageFormatting('NOTICE_REGIST'),
            content: publishData && <SecondStep stepData={publishData} />,
        },
    ];

    return (
        <Modal
            width="80%"
            visible={visible}
            onCancel={onCancel}
            footer={current === 0 || stepType === 'detail' ? null : undefined}
            onOk={() => {
                if (publishData) onFinish(publishData, stepType);
            }}
            title={messageFormatting(NOTICE_KEY[type])}
        >
            <section css={wrapper}>
                <Steps size="small" current={current} initial={0} style={{ marginBottom: '32px' }}>
                    {steps.map((item: any) => {
                        return <Steps.Step title={item.title} key={item.title} />;
                    })}
                </Steps>
                {steps[current].content}
            </section>
        </Modal>
    );
}

const wrapper = css`
    padding: 48px 24px 48px 24px;
`;
