import { IAccountEditDto, IPasswordChangeDto } from 'dto/account.dto';
import useAuth from 'hooks/auth/useAuth';
import { notificationPush } from 'utils/notificationUtils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationRepository } from 'repository';
import { getMyDataRequestAction } from 'store/modules/account/action';

export default function useAccount() {
    const dispatch = useDispatch();

    const account = useSelector<RootStore, RootStore['account']>(({ account }) => account);

    const { logoutRequest, originUrl } = useAuth();

    const getMyDataRequest = useCallback(() => {
        if (originUrl) return;
        dispatch(getMyDataRequestAction({ fallback: logoutRequest }));
    }, [originUrl, dispatch, logoutRequest]);

    const changeUserName = useCallback(
        async (param: IAccountEditDto) => {
            await AuthenticationRepository.updateProfile({
                body: {
                    name: param.username,
                    id: account.account.id,
                },
            });
            notificationPush({ status: 'success', message: '유저 이름 변경에 성공했습니다.' });
            getMyDataRequest();
        },
        [account.account.id, getMyDataRequest],
    );

    const changeUserPassword = useCallback(
        async (param: IPasswordChangeDto) => {
            await AuthenticationRepository.updateProfile({
                body: {
                    password: param.newPassword,
                    id: account.account.id,
                },
            });
            notificationPush({ status: 'success', message: '비밀번호 변경에 성공했습니다.' });
        },
        [account.account.id],
    );

    return {
        getMyDataRequest,
        account,
        changeUserName,
        changeUserPassword,
    };
}
