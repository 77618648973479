import { del, get, patch, post } from 'utils/api/client';
import { IMailRepository } from '@gamebase-ops/interfaces/mail/interface';
import {
    MailCreateRequest,
    MailUpdateRequest,
    MailSendRequest,
    MailCancelRequest,
    MailInfoRequest,
    MailListRequest,
    SystemMailCreateRequest,
    SystemMailUpdateRequest,
    SystemMailSendRequest,
    SystemMailCancelRequest,
    SystemMailInfoRequest,
    SystemMailListRequest,
} from '@gamebase-ops/interfaces/mail/request';
import {
    MailInfoResponse,
    MailListResponse,
    SystemMailInfoResponse,
    SystemMailListResponse,
} from '@gamebase-ops/interfaces/mail/response';
import { Query, Auth, Body } from '@gamebase-ops/interfaces/utils/request';
import { BaseResponse } from '@gamebase-ops/interfaces/utils/response';

const baseUrl = '/api/v1/mails';
class MailRepository implements IMailRepository {
    createMail(param: Query<Auth> & Body<MailCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl, new MailCreateRequest(param.body), new Auth(param.query));
    }
    updateMail(param: Query<Auth> & Body<MailUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl, new MailUpdateRequest(param.body), new Auth(param.query));
    }
    sendMail(param: Query<Auth> & Body<MailSendRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/send', new MailSendRequest(param.body), new Auth(param.query));
    }
    cancelMail(param: Query<MailCancelRequest>): Promise<BaseResponse> {
        return del(baseUrl + '/cancel', new MailCancelRequest(param.query));
    }
    infoMail(param: Query<MailInfoRequest>): Promise<MailInfoResponse> {
        return get(baseUrl + '/info', new MailInfoRequest(param.query));
    }
    listMail(param: Query<MailListRequest>): Promise<MailListResponse> {
        return get(baseUrl + '/list', new MailListRequest(param.query));
    }
    createSystemMail(param: Query<Auth> & Body<SystemMailCreateRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/system', new SystemMailCreateRequest(param.body), new Auth(param.query));
    }
    updateSystemMail(param: Query<Auth> & Body<SystemMailUpdateRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/system', new SystemMailUpdateRequest(param.body), new Auth(param.query));
    }
    sendSystemMail(param: Query<Auth> & Body<SystemMailSendRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/system/send', new SystemMailSendRequest(param.body), new Auth(param.query));
    }
    cancelSystemMail(param: Query<SystemMailCancelRequest>): Promise<BaseResponse> {
        return del(baseUrl + '/system/cancel', new SystemMailCancelRequest(param.query));
    }
    infoSystemMail(param: Query<SystemMailInfoRequest>): Promise<SystemMailInfoResponse> {
        return get(baseUrl + '/system/info', new SystemMailInfoRequest(param.query));
    }
    listSystemMail(param: Query<SystemMailListRequest>): Promise<SystemMailListResponse> {
        return get(baseUrl + '/system/list', new SystemMailListRequest(param.query));
    }
}

export default new MailRepository();
