import { IMaintenanceRepository } from '@gamebase-ops/interfaces/maintenance/v1/interface';
import {
    MaintenanceRequest,
    MaintenanceScheduleRequest,
    MaintenanceScheduleDeleteRequest,
    MaintenanceScheduleListRequest,
    MaintenanceInfoRequest,
} from '@gamebase-ops/interfaces/maintenance/v1/request';
import {
    MaintenanceInfoResponse,
    MaintenanceScheduleListResponse,
} from '@gamebase-ops/interfaces/maintenance/v1/response';

import { Query, Auth, Body, BaseResponse } from '@gamebase-ops/interfaces/utils';
import { del, get, post } from 'utils/api/client';

const baseUrl = '/api/v1/maintenances';

class MaintenanceRepository implements IMaintenanceRepository {
    maintenace(param: Query<Auth> & Body<MaintenanceRequest>): Promise<BaseResponse> {
        return post(baseUrl, new MaintenanceRequest(param.body), new Auth(param.query));
    }
    info(param: Query<MaintenanceInfoRequest>): Promise<MaintenanceInfoResponse> {
        return get(baseUrl + '/info', new MaintenanceInfoRequest(param.query));
    }
    schedule(param: Query<Auth> & Body<MaintenanceScheduleRequest>): Promise<BaseResponse> {
        return post(baseUrl + '/schedule', new MaintenanceScheduleRequest(param.body), new Auth(param.query));
    }
    deleteSchedule(param: Query<MaintenanceScheduleDeleteRequest>): Promise<BaseResponse> {
        return del(baseUrl + '/schedule', new MaintenanceScheduleDeleteRequest(param.query));
    }
    listSchedule(param: Query<MaintenanceScheduleListRequest>): Promise<MaintenanceScheduleListResponse> {
        return get(baseUrl + '/schedule/list', new MaintenanceScheduleListRequest(param.query));
    }
}

export default new MaintenanceRepository();
