import { css } from '@emotion/react';
import { IUser, IUserIdentifier } from '@gamebase-ops/interfaces/gameUser';
import UserInfoDescription from 'pages/project/user/games/randomdice-wars/components/UserInfoDescription';
import UserListTable from 'pages/project/user/games/randomdice-wars/components/UserListTable';
import UserSearchForm from 'pages/project/user/games/randomdice-wars/components/UserSearchForm';
import { useState } from 'react';

interface IProp {
    userList?: IUserIdentifier[];
    userDetail?: IUser;
    onUserSearch: (name: string, value: string) => void;
    onUserListTableClick: (id: string) => void;
    type: string;
}

export default function AccountInfo({ userList, userDetail, onUserSearch, onUserListTableClick, type }: IProp) {
    const [mode, setMode] = useState<string>();
    return (
        <div css={accountInfoWrapper}>
            <div>
                <h1>{type === 'src' ? '기존계정' : '새로운 계정'} PID</h1>
                <div css={inputWrapper}>
                    <UserSearchForm
                        onSearch={(key, value) => {
                            setMode(key);
                            onUserSearch(key, value);
                        }}
                        isRemoveGuid={true}
                    />
                    {userList && mode === 'name' && (
                        <UserListTable
                            linkDisabled
                            userList={userList}
                            onUserIdClick={(id) => {
                                onUserListTableClick(id);
                                setMode('pid');
                            }}
                        />
                    )}
                </div>
            </div>
            {userDetail && mode === 'pid' && <UserInfoDescription hasWritePermission={false} userDetail={userDetail} />}
        </div>
    );
}

const accountInfoWrapper = css`
    flex-basis: 50%;
`;

const inputWrapper = css`
    display: flex;

    input {
        margin-right: 20px;
    }
`;
