import { css } from '@emotion/react';
import { DatePicker, Form, Button } from 'antd';
import { useFormatMessage } from 'hooks';
import { useState } from 'react';
import ServerControl from './urgent/ServerControl';

interface IFilter {
    handleSearch: (args: INoticeFilter) => void;
    openStep: () => void;
    type: string;
    projectId: string;
    hasWritePermission: boolean;
}

function NoticeFilter({ handleSearch, openStep, type, projectId, hasWritePermission }: IFilter) {
    const { messageFormatting } = useFormatMessage();
    const [filterData, setFilterData] = useState<INoticeFilter>({
        startTime: undefined,
        endTime: undefined,
    });

    return (
        <div css={filterWrapper}>
            <section css={leftWrapper}>
                <Form css={searchWrapper}>
                    <div>
                        <DatePicker
                            placeholder={messageFormatting('START_AT')}
                            onChange={(e) => {
                                const date = e?.toDate();
                                date?.setHours(0);
                                date?.setMinutes(0);
                                date?.setSeconds(0);
                                date?.setMilliseconds(0);

                                setFilterData({ ...filterData, startTime: date });
                            }}
                            name="startDate"
                        />
                        <span
                            css={css`
                                margin: 0 7px;
                            `}
                        >
                            ~
                        </span>
                        <DatePicker
                            placeholder={messageFormatting('END_AT')}
                            onChange={(e) => {
                                const date = e?.toDate();
                                date?.setHours(23);
                                date?.setMinutes(59);
                                date?.setSeconds(59);
                                date?.setMilliseconds(999);
                                setFilterData({ ...filterData, endTime: date });
                            }}
                            name="endDate"
                        />
                    </div>
                    <div css={searchBar}>
                        {/* <Input
                            style={{
                                height: '32px',
                            }}
                            name="keyword"
                            placeholder={messageFormatting('PLEASE_INPUT_TITLE')}
                            value={filterData.title}
                            onChange={(e) => {
                                setFilterData({ ...filterData, title: e.target.value });
                            }}
                        /> */}
                        <Button type="primary" onClick={() => handleSearch(filterData)}>
                            {messageFormatting('SEARCH')}
                        </Button>
                    </div>
                </Form>
                {type === 'emergency' && (
                    <ServerControl projectId={projectId} hasWritePermission={hasWritePermission} />
                )}
            </section>
            {hasWritePermission && (
                <Button type="primary" onClick={() => openStep()}>
                    {messageFormatting('REGISTRATION')}
                </Button>
            )}
        </div>
    );
}

export default NoticeFilter;
const filterWrapper = css`
    display: flex;
    justify-content: space-between;

    margin-bottom: 20px;
`;

const searchWrapper = css`
    display: flex;
    margin-right: 32px;
`;

const searchBar = css`
    display: flex;
    margin-left: 10px;

    input {
        margin-right: 5px;
    }
`;

const leftWrapper = css`
    display: flex;
`;
