import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import { useRef } from 'react';
import { useCallback } from 'react';

const { Search } = Input;

const AutoClearSearch: React.FC<SearchProps> = (props) => {
    const searchRef = useRef<any>();

    const onSearch = useCallback(
        (val: string) => {
            props.onSearch && props.onSearch(val);
            searchRef.current?.setValue('');
        },
        [props],
    );

    return <Search {...props} onSearch={onSearch} ref={searchRef as React.Ref<any>} />;
};

export default AutoClearSearch;
