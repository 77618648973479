import { Input, Form, Button } from 'antd';
import { ModalWrapper } from 'components';
import classNames from 'classnames/bind';
import styles from './SignupModal.module.scss';
import { GuestRegisterRequest } from '@gamebase-ops/interfaces/account';
import { useFormRules } from 'hooks';

const cx = classNames.bind(styles);
interface IProp extends ModalWrapperProps {
    onSubmit?: (param: GuestRegisterRequest) => void;
}

const SignupModal: React.FC<IProp> = ({ visible, title, onCancel, onSubmit }) => {
    const { formValidator } = useFormRules();

    return (
        <ModalWrapper visible={visible} title={title} onCancel={onCancel}>
            <div className={cx('formWrapper')}>
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onSubmit}>
                    <Form.Item name="userId" label="UserId" rules={formValidator.getRules('account_id')}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={formValidator.getRules('account_name')}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="password" rules={formValidator.getRules('account_password')}>
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="description"
                        rules={formValidator.getRules('account_description')}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <div className={cx('footerWrapper')}>
                            <Button className={cx('button')} type="primary" htmlType="submit">
                                가입
                            </Button>
                            <Button className={cx('button')} type="text" onClick={onCancel}>
                                취소
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </ModalWrapper>
    );
};

export default SignupModal;
