import { Menu, Dropdown } from 'antd';
import classNames from 'classnames/bind';
import { useAccount, useFormatMessage, useProjectList, useQueries } from 'hooks';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { downIcon, logoImg } from 'icons';

import { memo } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderMenu.module.scss';
import ManageMentMenu from './ManageMentMenu';
const cx = classNames.bind(styles);

const envKeys = ['prod', 'qa', 'dev'];

const HeaderMenu = () => {
    const { account } = useAccount();
    const { projectId } = useQueries();
    const { projectList, groupByEnvProjectList } = useProjectList();
    const { projectDetail } = useProjectDetail(projectId);

    const { messageFormatting } = useFormatMessage();

    const selectedTitle = useMemo(() => {
        if (!projectList) {
            return messageFormatting('PROJECT_SELECT');
        }
        const index = projectList.findIndex((it) => it.id === projectId);
        if (index === -1) return messageFormatting('PROJECT_SELECT');
        return projectList[index].name;
    }, [projectList, projectId, messageFormatting]);

    return (
        <header className={cx('header', projectDetail && projectDetail.environment)}>
            <picture className={cx('logo')}>
                <Link to="/">
                    <img src={logoImg} width="168" height="58" alt="111% 로고" />
                </Link>
            </picture>
            <section className={cx('menuWrapper')}>
                <section className={cx('leftMenu')}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {envKeys?.map((envkey) => (
                                    <section key={envkey}>
                                        <h2 className={cx('envKeyTitle')}>{envkey.toUpperCase()}</h2>
                                        <Menu selectedKeys={[]}>
                                            {groupByEnvProjectList[envkey].map(({ name, id, environment }, index) => (
                                                <Menu.Item
                                                    key={`${name}_${id}_${environment}_${index}`}
                                                    style={{ margin: 0 }}
                                                >
                                                    <Link
                                                        to={`/project/dashboard?projectId=${id}`}
                                                        className={cx('settingOption')}
                                                    >
                                                        {name}
                                                    </Link>
                                                </Menu.Item>
                                            ))}
                                            <Menu.Divider />
                                        </Menu>
                                    </section>
                                ))}
                            </Menu>
                        }
                        forceRender
                    >
                        <div className={cx('dropDown')}>
                            <span>{selectedTitle}</span>
                            <i className={cx('icon')}>
                                <img src={downIcon} width="12" height="12" alt="아래 화살표" />
                            </i>
                        </div>
                    </Dropdown>
                </section>

                <ManageMentMenu username={account.account.name} isAdmin={account.account.isMaster || false} />
            </section>
        </header>
    );
};

export default memo(HeaderMenu);
