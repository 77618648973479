import { useCallback, useEffect } from 'react';
import { Form, Button, Input, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { UserFormList, ItemFormList, MailTextFormList, EnterIgnoreForm } from 'components';
import {
    useAccount,
    useAllItems,
    useFormatMessage,
    useMailSend,
    useMailTemplate,
    useMailTemplateSelected,
    useScreenLock,
} from 'hooks';
import { MAIL_TYPE, ESystemMailType } from 'constants/constants';
import styles from './MailSendForm.module.scss';
import SendMailDatePicker from './SendMailDatePicker';
import SystemMailTypeEdit from './SystemMailTypeEdit';
import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';

const cx = classNames.bind(styles);

interface IProp extends ProjectIdQuery {
    defaultValue?: MailDto | SystemMailDto;
    languages?: string[];
    onSuccess?: () => void;
    isCopyMode?: boolean;
    handleClose: () => void;
}

const MailSendForm: React.FC<IProp> = ({ defaultValue, languages, projectId, onSuccess, isCopyMode, handleClose }) => {
    const { messageFormatting } = useFormatMessage();
    const { mailTemplateList, getMailTemplateList } = useMailTemplate();
    const { itemHash } = useAllItems(projectId);

    useEffect(() => {
        getMailTemplateList(projectId);
    }, [getMailTemplateList, projectId]);

    const { setTemplateSelectedKey, isAll, setIsAll, selectedMailTemplate } = useMailTemplateSelected(
        mailTemplateList,
        defaultValue,
    );

    const { withScreenLockCall } = useScreenLock();
    const { account } = useAccount();

    const { users, setUsers, deleteUser, items, addItem, deleteItem, saveMailApiCall } = useMailSend(projectId, {
        defaultUsers: defaultValue instanceof MailDto ? defaultValue.userIds : undefined,
        defaultItems: defaultValue?.items.map((it) => ({
            ...it,
            item: itemHash ? itemHash[it.itemId] : undefined,
        })),
        isCopyMode,
    });

    const onTableIdChange = useCallback(
        (tableId) => {
            setTemplateSelectedKey(tableId);
        },
        [setTemplateSelectedKey],
    );

    const cancelButtonClickListener = useCallback(() => {
        if (window.confirm(messageFormatting('ALL_STOP_AND_BACK'))) {
            handleClose();
        }
    }, [messageFormatting, handleClose]);

    const submitMessageListener = useCallback(
        async (arg) => {
            const sendTime =
                arg.sendTime?.date && arg.sendTime?.time
                    ? new Date(`${arg.sendTime.date} ${arg.sendTime.time}`)
                    : undefined;
            const endTime =
                arg.endTime?.date && arg.endTime?.time
                    ? new Date(`${arg.endTime.date} ${arg.endTime.time}`)
                    : undefined;
            const systemMailType = arg.systemMailType && parseInt(arg.systemMailType, 10);

            const requestArg = {
                ...arg,
                mailType: isAll ? MAIL_TYPE.SYSTEM : MAIL_TYPE.TARGET,
                startTime: sendTime,
                endTime,
                templateId: parseInt(arg.templateId, 10),
                storeDay: parseInt(arg.storeDay, 10),
                systemMailType,
                conditionType: parseInt(arg.conditionType, 10),
                conditionData: arg.conditionData,
            };

            const isDateType = [
                ESystemMailType.EqualCreateUser,
                ESystemMailType.LessThanCreateUser,
                ESystemMailType.GreateThanCreateUser,
            ].includes(systemMailType);

            const isNumberType = [
                ESystemMailType.EqualClassId,
                ESystemMailType.LessThanClassId,
                ESystemMailType.GreaterThanClassId,
            ].includes(systemMailType);

            if (isDateType) {
                requestArg.checkData = `${arg.checkData.date} ${arg.checkData.time}`;
            }

            if (isNumberType) requestArg.checkData = Number(arg.checkData);
            await withScreenLockCall(saveMailApiCall, requestArg.mailType, requestArg, defaultValue?.id);
            onSuccess && onSuccess();
        },
        [saveMailApiCall, defaultValue, withScreenLockCall, isAll, onSuccess],
    );

    return (
        <section className={cx('formSection')}>
            <EnterIgnoreForm onSubmit={submitMessageListener}>
                <section className={cx('fullContentWrapper')}>
                    <section className={cx('contentWrapper')}>
                        <Tooltip placement="top" title={messageFormatting('ONLY_CHECK_TITLE')}>
                            <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('TITLE')}>
                                <Input
                                    style={{ width: '350px' }}
                                    name="description"
                                    defaultValue={defaultValue?.description}
                                />
                            </Form.Item>
                        </Tooltip>
                        <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('TARGET_USER')}>
                            <UserFormList
                                users={users}
                                setUsers={setUsers}
                                deleteUser={deleteUser}
                                isAll={isAll}
                                setIsAll={setIsAll}
                                canToggle={!defaultValue}
                            />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('MAIL_TYPE')}>
                            <select
                                className={cx('mailTypeSelect')}
                                name="templateId"
                                onChange={(e) => onTableIdChange(parseInt(e.target.value, 10))}
                                style={{ width: '250px' }}
                            >
                                <option selected={defaultValue?.template.templateId === undefined} />
                                {mailTemplateList.map(({ templateId, templateName }) => (
                                    <option
                                        key={templateId}
                                        value={templateId}
                                        selected={templateId === defaultValue?.template.templateId}
                                    >
                                        {templateName as string}
                                    </option>
                                ))}
                            </select>
                        </Form.Item>
                        {isAll && (
                            <SendMailDatePicker
                                defaultStartDate={
                                    (defaultValue as SystemMailDto)?.startTime &&
                                    new Date((defaultValue as SystemMailDto)?.startTime)
                                }
                                defaultEndDate={
                                    (defaultValue as SystemMailDto)?.endTime &&
                                    new Date((defaultValue as SystemMailDto)?.endTime)
                                }
                                defaultStoreDay={defaultValue?.storeDay}
                            />
                        )}
                        <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('MAIL_EXPIRED_DATE')}>
                            <Input
                                name="storeDay"
                                type="number"
                                defaultValue={defaultValue?.storeDay}
                                style={{ width: '150px' }}
                            />
                        </Form.Item>

                        <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('MAIL_ITEM')}>
                            <ItemFormList items={items} addItems={addItem} deleteItem={deleteItem} />
                        </Form.Item>

                        <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('PERSON')}>
                            <div>{account.account.username}</div>
                        </Form.Item>
                        {isAll && (
                            <>
                                <SystemMailTypeEdit
                                    systemMailType={(defaultValue as SystemMailDto)?.conditionType}
                                    checkData={(defaultValue as SystemMailDto)?.conditionData}
                                />
                                <Form.Item labelCol={{ span: 5, offset: 0 }} label="password">
                                    <Input
                                        name="eventCode"
                                        defaultValue={(defaultValue as SystemMailDto)?.eventCode}
                                        style={{ width: '400px' }}
                                    />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 5, offset: 0 }} label={messageFormatting('SEND_URL')}>
                                    <Input name="url" style={{ width: '400px' }} />
                                </Form.Item>
                            </>
                        )}
                    </section>
                    <section className={cx('contentWrapper')}>
                        <h3>우편 내용 작성</h3>
                        {mailTemplateList.length && (
                            <MailTextFormList
                                mailTemplate={selectedMailTemplate}
                                defaultValue={defaultValue}
                                languages={languages}
                            />
                        )}
                    </section>
                </section>

                <footer className={cx('footer')}>
                    <Button className={cx('footerButton')} danger size="large" onClick={cancelButtonClickListener}>
                        {messageFormatting('CANCEL')}
                    </Button>
                    <Button className={cx('footerButton')} htmlType="submit" type="primary" size="large">
                        {messageFormatting('SAVE')}
                    </Button>
                </footer>
            </EnterIgnoreForm>
        </section>
    );
};

export default MailSendForm;
