import { List, Button } from 'antd';
import classNames from 'classnames/bind';
import { ProjectModal } from 'components';
import lStyle from './ListItem.module.scss';
import { useModal, useProjectAdmin, useScreenLock } from 'hooks';
import { useCallback } from 'react';
import { ProjectCreateRequest, ProjectUpdateRequest } from '@gamebase-ops/interfaces/project';
import { MyProjectDto } from '@gamebase-ops/interfaces/authorization';
import { ServiceAuthDto } from '@gamebase-ops/interfaces/serviceAuth';

const lx = classNames.bind(lStyle);
interface IProp {
    serviceAuthList?: ServiceAuthDto[];
}

const ProjectList: React.FC<IProp> = ({ serviceAuthList }) => {
    const {
        createProjectRequest,
        putProjectRequest,
        deleteProjectRequest,
        projectDetail,
        projectList,
        setProjectDetail,
    } = useProjectAdmin();

    const { withScreenLockCall } = useScreenLock();

    const { visible, openModal, closeModal } = useModal();

    const onCreateProjectSubmit = useCallback(
        async (arg: ProjectCreateRequest | ProjectUpdateRequest) => {
            if (projectDetail) {
                await withScreenLockCall(putProjectRequest, arg as ProjectUpdateRequest);
            } else {
                await withScreenLockCall(createProjectRequest, arg as ProjectCreateRequest);
            }
            closeModal();
        },
        [createProjectRequest, closeModal, putProjectRequest, projectDetail, withScreenLockCall],
    );

    const projectItemClickListener = useCallback(
        async (item: MyProjectDto) => {
            setProjectDetail(item);
            openModal();
        },
        [openModal, setProjectDetail],
    );

    const closeProjectModal = useCallback(() => {
        closeModal();
        setProjectDetail(null);
    }, [closeModal, setProjectDetail]);

    const deleteClickListener = useCallback(
        async (oid) => {
            if (window.confirm('프로젝트를 정말 삭제하시겠습니까?')) {
                deleteProjectRequest(oid);
            }
        },
        [deleteProjectRequest],
    );

    return (
        <div className={lx('wrapper')}>
            {visible && (
                <ProjectModal
                    visible={visible}
                    onCancel={closeProjectModal}
                    title={projectDetail ? '프로젝트 수정' : '프로젝트 생성'}
                    onSubmit={onCreateProjectSubmit}
                    projectValues={projectDetail}
                    serviceAuthList={serviceAuthList}
                />
            )}
            <Button onClick={openModal} style={{ marginBottom: '12px' }}>
                CreateProject
            </Button>
            <List
                dataSource={projectList}
                renderItem={(item) => (
                    <List.Item className={lx('item')} key={item.id}>
                        <section onClick={() => projectItemClickListener(item)}>
                            <span>{item.name} </span>

                            <Button
                                type="primary"
                                danger
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    deleteClickListener(item.id);
                                }}
                            >
                                삭제
                            </Button>
                        </section>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ProjectList;
