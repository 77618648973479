import { Route, Switch } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import {
    ItemPage,
    MailPage,
    ProjectDashBoard,
    UserPage,
    NoticePage,
    MailDetailPage,
    AccountMerge,
    ProjectSettingsPage,
    LogPage,
    VoidedPurchasePage,
    OrderPage,
    BlockUserPage,
    WebviewEventPage,
} from 'pages';

import { AUTH_LEVEL } from 'constants/constants';
import UserMerge from 'pages/project/accountMerge';
import { ProjectCategoryLayout } from 'components';

const ProjectRoute = () => {
    return (
        <Switch>
            <ProjectCategoryLayout>
                <Route
                    path="/project/log"
                    render={() => (
                        <PageAuthViewController service="log">
                            <LogPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/project/user/merged"
                    render={() => (
                        <PageAuthViewController service="user">
                            <UserMerge />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/item"
                    render={() => (
                        <PageAuthViewController service="item" level={AUTH_LEVEL.WRITE}>
                            <ItemPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/event/:type', '/project/event']}
                    render={() => (
                        <PageAuthViewController service="event" level={AUTH_LEVEL.WRITE}>
                            <WebviewEventPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/mail/detail/:id"
                    render={() => (
                        <PageAuthViewController service="mail" level={AUTH_LEVEL.READ}>
                            <MailDetailPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/project/mail"
                    render={() => (
                        <PageAuthViewController service="mail">
                            <MailPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/dashboard"
                    render={() => (
                        <PageAuthViewController>
                            <ProjectDashBoard />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/project/notice', '/project/notice/:type']}
                    render={() => (
                        <PageAuthViewController service="notice" level={AUTH_LEVEL.WRITE}>
                            <NoticePage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path="/project/user/:pid"
                    render={() => (
                        <PageAuthViewController service="user" level={AUTH_LEVEL.WRITE}>
                            <UserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/project/user"
                    render={() => (
                        <PageAuthViewController service="user" level={AUTH_LEVEL.WRITE}>
                            <UserPage />
                        </PageAuthViewController>
                    )}
                ></Route>
                <Route
                    path="/project/accountMerge"
                    render={() => (
                        <PageAuthViewController service="accountMerge">
                            <AccountMerge />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/project"
                    render={() => (
                        <PageAuthViewController service="project">
                            <ProjectSettingsPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/blockUser"
                    render={() => (
                        <PageAuthViewController service="blockUser">
                            <BlockUserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/order"
                    render={() => (
                        <PageAuthViewController service="user" level={AUTH_LEVEL.READ}>
                            <OrderPage />
                        </PageAuthViewController>
                    )}
                />
                <Route path="/project/voidedPurchase" render={() => <VoidedPurchasePage />} />
            </ProjectCategoryLayout>
        </Switch>
    );
};

export default ProjectRoute;
