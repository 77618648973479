import { DatePicker, Form, Input, TimePicker } from 'antd';
import { ESystemMailType } from 'constants/constants';
import { useMemo } from 'react';
import moment from 'moment';

interface IProp {
    systemMailType?: ESystemMailType;
    defaultValue?: string | number;
}

export default function SystemMailTypeCheckData({ systemMailType, defaultValue }: IProp) {
    /**
     * conditionData의 타입을 추론하기 위한 함수, systemMailType을 보고 3가지 타입을 확정을 시켜준다.
     */
    const inputType = useMemo(() => {
        switch (systemMailType) {
            case ESystemMailType.AppVersion:
                return 'string';
            case ESystemMailType.EqualClassId:
            case ESystemMailType.LessThanClassId:
            case ESystemMailType.GreaterThanClassId:
                return 'number';
            case ESystemMailType.EqualCreateUser:
            case ESystemMailType.LessThanCreateUser:
            case ESystemMailType.GreateThanCreateUser:
                return 'date';
            default:
                return null;
        }
    }, [systemMailType]);

    const isDefaultValueDateType = useMemo(() => {
        if (!defaultValue) return false;
        const formats = [moment.ISO_8601, 'MM/DD/YYYY  :)  HH*mm*ss'];
        return moment(defaultValue, formats, true).isValid();
    }, [defaultValue]);

    return (
        <Form.Item>
            {inputType && (
                <>
                    {inputType === 'string' && (
                        <Input
                            name="conditionData"
                            defaultValue={typeof defaultValue === 'string' ? defaultValue : undefined}
                        />
                    )}
                    {inputType === 'number' && (
                        <Input
                            name="conditionData"
                            type="number"
                            defaultValue={typeof defaultValue === 'number' ? defaultValue : undefined}
                        />
                    )}
                    {inputType === 'date' && (
                        <>
                            <DatePicker
                                name="conditionData.date"
                                defaultValue={defaultValue && isDefaultValueDateType ? moment(defaultValue) : undefined}
                            />
                            <TimePicker
                                name="conditionData.time"
                                defaultValue={defaultValue && isDefaultValueDateType ? moment(defaultValue) : undefined}
                            />
                        </>
                    )}
                </>
            )}
        </Form.Item>
    );
}
