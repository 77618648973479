import React, { useMemo, useEffect, Suspense } from 'react';
import * as qs from 'qs';
import { usePageAuthCheck } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useCallback } from 'react';

/**
 *
 * Route 페이지에 대한 권한을 체크 하는 컴포넌트
 *
 */
const PageAuthViewController: React.FC<IRouteController> = ({
    service,
    projectId,
    level = 1,
    children,
    auths = [],
}) => {
    const { search } = useLocation();
    const { projectId: queryProjectId } = qs.parse(search, { ignoreQueryPrefix: true });
    const requestProjectId = projectId !== undefined ? projectId : (queryProjectId as string);
    const params = useMemo(
        () => ({ service, projectId: requestProjectId, level: level }),
        [service, requestProjectId, level],
    );

    const { pageAuthCheck, isLoading } = usePageAuthCheck();

    const [successParam, setSuccessParam] = useState<IAuthCheck | null>(null);

    const checkParam = useCallback(async () => {
        const { level, service, projectId } = params;

        if (service && level) {
            const result = await pageAuthCheck({ level, service, projectId });

            setSuccessParam(result);
        }
    }, [pageAuthCheck, params]);

    useEffect(() => {
        checkParam();
    }, [checkParam]);

    // children으로는 페이지 컴포넌트를 넣어서 모든 권한체크가 되기 전까지는 children을 렌더하지 않습니다.
    if (isLoading && projectId && service) return null;

    return (
        <Suspense fallback={<div>로딩중</div>}>
            {React.Children.map(children, (child) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                React.cloneElement(child as any, {
                    auths: successParam ? [...auths, successParam] : auths,
                }),
            )}
        </Suspense>
    );
};

export default PageAuthViewController;
