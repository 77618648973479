import { IsNotEmpty, IsNumber } from 'class-validator';

export class ArcadePassRewardQueryDto {
    @IsNumber()
    @IsNotEmpty()
    projectId: number;

    @IsNumber()
    @IsNotEmpty()
    arcadeId: number;

    constructor(param: ArcadePassRewardQueryDto) {
        this.projectId = param.projectId;
        this.arcadeId = param.arcadeId;
    }
}
