import { useCallback, useState } from 'react';
import { PurchaseLogDto } from '@gamebase-ops/interfaces/purchaseLog/dto';
import { PurchaseLogAllRequest } from '@gamebase-ops/interfaces/purchaseLog/request';
import { PurchaseLogRepository } from 'repository';

export default function useOrder(projectId: string) {
    const [purchaseLogs, setPurchaseLogs] = useState<PurchaseLogDto[] | undefined>([]);

    const requestOrderList = useCallback(
        async (params: Omit<PurchaseLogAllRequest, 'projectId'>) => {
            setPurchaseLogs(undefined);
            try {
                const result = await PurchaseLogRepository.all({ query: { ...params, projectId } });
                setPurchaseLogs(result.result);
            } catch (e) {
                setPurchaseLogs([]);
                throw e;
            }
        },
        [projectId],
    );

    return { requestOrderList, purchaseLogs };
}
