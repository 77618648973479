import { MailDto, SystemMailDto } from '@gamebase-ops/interfaces/mail/dto';
import { MailTemplateDto } from '@gamebase-ops/interfaces/mailTemplate/dto';
import { useMemo, useState } from 'react';

export default function useMailTemplateSelected(
    mailTemplateList: MailTemplateDto[],
    defaultValue?: MailDto | SystemMailDto,
) {
    const [templateSelectedKey, setTemplateSelectedKey] = useState(defaultValue?.template.templateId || 0);

    const [isAll, setIsAll] = useState(defaultValue instanceof SystemMailDto);

    const selectedMailTemplate = useMemo(() => {
        const index = mailTemplateList.findIndex((template) => template.templateId === templateSelectedKey);
        if (index === -1) return null;
        return mailTemplateList[index];
    }, [mailTemplateList, templateSelectedKey]);

    return {
        templateSelectedKey,
        setTemplateSelectedKey,
        isAll,
        setIsAll,
        selectedMailTemplate,
    };
}
