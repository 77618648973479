import { IAccountEditDto, IPasswordChangeDto } from 'dto/account.dto';
import { PaginationQuery } from 'dto/utils.dto';
import { del, get, patch, post, put } from 'utils/api/client';

interface AccountList extends ListResponse {
    result: IAccount[];
}

const baseURL = '/api/v1/accounts';

class AuthRepository {
    loginByEmail(params: EmailLogin) {
        return post('/api/v1/accounts/login', params);
    }

    logout(params: { requesterId: string }) {
        return put<{ requesterId: string }>('/api/v1/accounts/logout', params);
    }

    getList(param: PaginationQuery) {
        return get<AccountList>('/api/v1/accounts', param);
    }

    getMy() {
        return get<IAccount>('/api/v1/accounts/my');
    }

    getRoles() {
        return get<IRole[]>('/api/v1/accounts/roles');
    }

    getPermissions() {
        return get<IPermission[]>('/api/v1/accounts/permissions');
    }

    getProjects() {
        return get<IProject[]>('/api/v1/accounts/category/projects');
    }

    getAccountByKeyword(param: KeywordQuery) {
        return get<IAccount[]>('/api/v1/accounts/keyword', param);
    }

    getAccountById(param: string) {
        return get<{ account: IAccount; roles: IRole[] }>(`/api/v1/accounts/${param}/detail`);
    }

    getMyRoles() {
        return get<IRole[]>(`${baseURL}/roles`);
    }

    getAccessToken() {
        return new Promise<string>((resolve) => {
            resolve('accessToken');
        });
    }

    getRefreshToken() {
        return new Promise<string>((resolve) => {
            resolve('');
        });
    }

    editSelf(param: IAccountEditDto) {
        return patch('/api/v1/accounts', param);
    }

    editFromAdmin(oid: string, data: IAccountEditDto) {
        return patch(`/api/v1/accounts/${oid}`, data);
    }

    changePassword(param: IPasswordChangeDto) {
        return patch('/api/v1/accounts/change/password', param);
    }

    resetPassword(param: { oid: string }) {
        return put('/api/v1/accounts/reset/password', param);
    }

    deleteMyRole(roleId: string) {
        return del(`/api/v1/accounts/my_role/${roleId}`);
    }
}

export default new AuthRepository();
