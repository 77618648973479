import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { screenLockAction, screenUnLockAction, setMessageAction } from 'store/modules/screen/action';

export default function useScreenLock() {
    const { isLock, message } = useSelector<RootStore, RootStore['screen']>(({ screen }) => screen);
    const dispatch = useDispatch();

    const screenLock = useCallback(() => {
        dispatch(screenLockAction());
    }, [dispatch]);

    const screenUnLock = useCallback(() => {
        dispatch(screenUnLockAction());
    }, [dispatch]);

    const withScreenLockCall: typeof CallWithFunction = useCallback(
        async (excuteFunction, ...args) => {
            try {
                screenLock();
                const result = await excuteFunction(...args);
                screenUnLock();

                return result;
            } finally {
                screenUnLock();
            }
        },
        [screenUnLock, screenLock],
    );

    const withScreenLockCallByMessage = useCallback<(message: string) => typeof CallWithFunction>(
        (message: string) =>
            (excuteFunction, ...args) => {
                dispatch(setMessageAction(message));
                return withScreenLockCall(excuteFunction, ...args);
            },
        [withScreenLockCall, dispatch],
    );

    return { isLock, screenLock, screenUnLock, withScreenLockCall, withScreenLockCallByMessage, message };
}
