import { useScreenLock } from 'hooks';
import useGetProjectContentLock from './model/useGetProjectContentLock';
import { useCallback } from 'react';
import { ItemRepository } from 'repository';

export default function useProjectContentLock(projectId: string) {
    const { data: contentsList, refetch } = useGetProjectContentLock(projectId);
    const { withScreenLockCall } = useScreenLock();

    const lockItem = useCallback(
        async (itemId: number) => {
            await withScreenLockCall(ItemRepository.lock, {
                query: {
                    projectId,
                },
                body: {
                    ItemId: itemId,
                },
            });
            refetch();
        },
        [projectId, withScreenLockCall, refetch],
    );

    const unLockItem = useCallback(
        async (itemId: number) => {
            await withScreenLockCall(ItemRepository.unlock, {
                query: {
                    projectId,
                },
                body: {
                    ItemId: itemId,
                },
            });
            refetch();
        },
        [projectId, withScreenLockCall, refetch],
    );

    return {
        contentsList: contentsList?.result,
        lockItem,
        unLockItem,
    };
}
