import moment from 'moment';
export const dateTimeMerge = (date: Date, time: Date) => {
    const mergedDate = new Date(date);
    mergedDate.setHours(time.getHours());
    mergedDate.setMinutes(time.getMinutes());
    mergedDate.setSeconds(time.getSeconds());

    return mergedDate;
};

export const getSendMailDateFormat = (date: Date, time: Date) => {
    const dateText = moment(date).format('YYYY-MM-DD');
    const timeText = moment(time).format('HH:mm:ss');

    return `${dateText} ${timeText}`;
};

export const getStartDateTime = (targetDate?: Date) => {
    const date = targetDate ? new Date(targetDate) : new Date();

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

export const getEndDateTime = (targetDate?: Date) => {
    const date = targetDate ? new Date(targetDate) : new Date();

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    return date;
};
