import { css } from '@emotion/react';
import { WebviewNoticeDto } from '@gamebase-ops/interfaces/notice/dto';
import { Button, DatePicker, Form, Input, Tabs } from 'antd';
import { EnterIgnoreForm } from 'components';
import { WebviewNoticeCreateDto } from 'dto/webviewNotice.dto';
import { useQueries, useScreenLock } from 'hooks';

import useWebviewNoticeEdit from 'hooks/notice/useWebviewNoticeEdit';
import useProjectDetail from 'hooks/project/useProjectDetail';
import moment from 'moment';
import { useCallback } from 'react';
import { notificationPush } from 'utils/notificationUtils';
import WebviewEditor from './WebviewEditor';
import WebviewTitleImage from './WebviewTitleImage';

interface IProp {
    onSubmit: (param: WebviewNoticeCreateDto) => void;
    noticeWebviewData?: WebviewNoticeDto;
}

export default function WebviewEditorForm({ onSubmit, noticeWebviewData }: IProp) {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);

    const { withScreenLockCallByMessage } = useScreenLock();
    const {
        setNoticeEditorValues,
        uploadImage,
        uploadTitleImage,
        setTitleImages,
        uploadHTML,
        webviewNoticeCreateDto,
        setSelectedLang,
        setPreferLanguage,
    } = useWebviewNoticeEdit(projectId, noticeWebviewData ? JSON.parse(JSON.stringify(noticeWebviewData)) : undefined);

    const submitListener = useCallback(
        async (params: any) => {
            const { startTime, endTime, titles } = params;
            const submitObject = { ...webviewNoticeCreateDto, titles };

            if (startTime.date) {
                submitObject.startTime = new Date(`${startTime.date}`);
            } else {
                notificationPush({
                    status: 'error',
                    title: '시작날짜를 입력해주세요',
                });

                return;
            }

            if (endTime.date) {
                submitObject.endTime = new Date(`${endTime.date}`);
            } else {
                notificationPush({
                    status: 'error',
                    title: '종료날짜를 입력해주세요',
                });

                return;
            }

            projectDetail?.languages?.map((lang: string) => {
                setPreferLanguage(params.preferLang, lang);
                if (!titles[lang]) titles[lang] = titles[params.preferLang];
            });

            const titleImageUploadScreenLock = withScreenLockCallByMessage('메인 이미지 업로드 중 입니다.');
            await titleImageUploadScreenLock(uploadTitleImage);

            const htmlUploadScreenLock = withScreenLockCallByMessage('HTML을 업로드 중입니다.');
            await htmlUploadScreenLock(uploadHTML);

            onSubmit({ ...submitObject, defaultLanguage: params.preferLang });
        },
        [
            withScreenLockCallByMessage,
            uploadTitleImage,
            uploadHTML,
            webviewNoticeCreateDto,
            onSubmit,
            setPreferLanguage,
            projectDetail,
        ],
    );

    return (
        <EnterIgnoreForm onSubmit={submitListener}>
            <div>
                <Form.Item label="우선언어">
                    <select name="preferLang" css={selectStyle} defaultValue={noticeWebviewData?.defaultLanguage}>
                        {projectDetail?.languages?.map((lang) => (
                            <option key={lang} value={lang}>
                                {lang}
                            </option>
                        ))}
                    </select>
                </Form.Item>
            </div>
            <section css={wrapper}>
                <Tabs type="card" onChange={setSelectedLang}>
                    {projectDetail?.languages?.map((lang) => (
                        <Tabs.TabPane css={editFromWrapper} key={lang} tab={lang} forceRender>
                            <Form.Item label="타이틀">
                                <Input name={`titles.${lang}`} defaultValue={noticeWebviewData?.titles?.[lang]} />
                            </Form.Item>
                            <Form.Item label="배너">
                                <WebviewTitleImage
                                    onChange={setTitleImages(lang)}
                                    titleImageUrl={noticeWebviewData?.mainImages?.[lang]}
                                />
                            </Form.Item>
                            <div css={editorWrapper}>
                                <WebviewEditor
                                    baseName={lang}
                                    onImageUpload={(file) =>
                                        withScreenLockCallByMessage('이미지 업로드 중입니다.')(uploadImage, file)
                                    }
                                    onChange={setNoticeEditorValues}
                                    lang={lang}
                                    defaultValueUrl={noticeWebviewData?.contents?.[lang]?.url}
                                />
                            </div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </section>
            <section css={dateRangePickerWrapper}>
                <Form.Item>
                    <section css={dateTimePickerWrapper}>
                        <label>시작: </label>
                        <DatePicker
                            name="startTime.date"
                            defaultValue={
                                noticeWebviewData?.startTime ? moment(noticeWebviewData.startTime) : undefined
                            }
                        />
                    </section>
                    <section css={dateTimePickerWrapper}>
                        <label>종료: </label>
                        <DatePicker
                            name="endTime.date"
                            defaultValue={noticeWebviewData?.endTime ? moment(noticeWebviewData.endTime) : undefined}
                        />
                    </section>
                </Form.Item>
            </section>
            <footer css={formFooter}>
                <Button type="primary" htmlType="submit">
                    등록
                </Button>
            </footer>
        </EnterIgnoreForm>
    );
}

const wrapper = css`
    display: flex;
`;

const formFooter = css`
    margin-top: 16px;
    border-top: 1px solid #efefef;
    padding-top: 8px;
    text-align: right;
`;

const editFromWrapper = css`
    width: 768px;
`;

const dateTimePickerWrapper = css`
    margin-bottom: 24px;
`;

const dateRangePickerWrapper = css`
    margin-top: 32px;
`;

const editorWrapper = css``;

const selectStyle = css`
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    width: 240px;
`;
