import { useScreenLock } from 'hooks';
import { useCallback, useState } from 'react';
import { ProjectRepository } from 'repository';

export default function useIPWhiteList(projectId: string) {
    const [ipWhiteList, setIpWhiteList] = useState<string[]>();
    const { withScreenLockCall } = useScreenLock();

    const getIPwhiteList = useCallback(async () => {
        const result = await ProjectRepository.ipWhiteList({ query: { projectId } });
        result.result ? setIpWhiteList(result.result) : setIpWhiteList([]);
    }, [projectId]);

    const removeIp = useCallback(
        async (ipAddress: string) => {
            await withScreenLockCall(ProjectRepository.removeFromIpWhiteList, {
                query: { projectId },
                body: { IpAddress: ipAddress },
            });
            await getIPwhiteList();
        },
        [withScreenLockCall, projectId, getIPwhiteList],
    );

    const addIp = useCallback(
        async (ipAddress: string) => {
            await withScreenLockCall(ProjectRepository.addToIpWhiteList, {
                query: { projectId },
                body: { IpAddress: ipAddress },
            });
            await getIPwhiteList();
        },
        [withScreenLockCall, projectId, getIPwhiteList],
    );

    return {
        ipWhiteList,
        getIPwhiteList,
        removeIp,
        addIp,
    };
}
