import { EGameServerState, EPlatform } from '@gamebase-ops/interfaces/consts';
import { IsDate, IsEnum, IsIn, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { EMERGENCY_NOTICE_PLATFORM, NOTICE_STATE, NOTICE_TYPE } from 'constants/constants';

import { IsExcuteTest } from './customDecorators';
import { PaginationQuery } from './utils.dto';

export class ICreateNoticePostDto {
    constructor(param: ICreateNoticePostDto) {
        this.startTime = new Date(param.startTime);
        this.endTime = new Date(param.endTime);
        this.messages = param.messages;

        this.type = param.type;
        this.defaultLanguage = param.defaultLanguage;
        this.titles = param.titles;
        this.repeatNum = param.repeatNum;
        this.noticePlatform = param.noticePlatform;
    }

    @IsNotEmpty()
    @IsString()
    defaultLanguage: string;

    @IsNotEmpty()
    @IsDate()
    @IsExcuteTest<ICreateNoticePostDto>(({ endTime }, startTime) => startTime.valueOf() < endTime.valueOf(), {
        message: 'START_MORE_THEN_END',
    })
    startTime: Date;

    @IsNotEmpty()
    @IsDate()
    @IsExcuteTest<ICreateNoticePostDto>(
        ({ startTime, endTime }) => {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            const toDayValue = today.valueOf();
            return startTime.valueOf() > toDayValue && endTime.valueOf() > toDayValue;
        },
        {
            message: '현재 날짜보다 이전으로 입력 할 수 없습니다.',
        },
    )
    endTime: Date;

    @IsNotEmpty()
    @IsExcuteTest<ICreateNoticePostDto>(
        ({ messages }) => {
            const keys = Object.keys(messages);
            const regexp = new RegExp('["]');
            return !keys.reduce<boolean>((acc, key) => acc || regexp.test(messages[key] as any), false);
        },
        {
            message: '큰따옴표(")를 사용하실수 없습니다',
        },
    )
    @IsExcuteTest<ICreateNoticePostDto>(
        ({ messages }) => {
            const keys = Object.keys(messages);

            return keys.reduce<boolean>((acc, key) => {
                if (messages[key] === undefined) return false;
                if (!messages[key]?.length) return false;
                return acc;
            }, true);
        },
        {
            message: 'messages를 입력해주세요',
        },
    )
    messages: IMessage;

    @IsOptional()
    @IsExcuteTest<ICreateNoticePostDto>(
        ({ titles }) => {
            if (titles) {
                const keys = Object.keys(titles);
                const regexp = new RegExp('["]');
                return !keys.reduce<boolean>((acc, key) => acc || regexp.test(titles[key] as any), false);
            }
            return true;
        },
        {
            message: '큰따옴표(")를 사용하실수 없습니다',
        },
    )
    @IsExcuteTest<ICreateNoticePostDto>(
        ({ type, titles }) => {
            if (type === NOTICE_TYPE.NORMAL) {
                return Object.keys(titles || {}).length > 0;
            }
            return true;
        },
        {
            message: '언어별 제목을 설정해주세요',
        },
    )
    titles?: IMessage;

    @IsNumber()
    @IsIn([NOTICE_TYPE.URGENT, NOTICE_TYPE.NORMAL, NOTICE_TYPE.INGAME])
    type: NOTICE_TYPE;

    @IsOptional()
    @IsNumber()
    repeatNum?: number;

    @IsOptional()
    @IsIn([EMERGENCY_NOTICE_PLATFORM.BOTH, EMERGENCY_NOTICE_PLATFORM.ANDROID, EMERGENCY_NOTICE_PLATFORM.iOS])
    noticePlatform?: EMERGENCY_NOTICE_PLATFORM;
}

export class MaintenanceRequestDto {
    @IsEnum(EPlatform)
    platform: EPlatform;

    @IsEnum(EGameServerState)
    status: EGameServerState;

    constructor(param: MaintenanceRequestDto) {
        this.platform = param.platform;
        this.status = param.status;
    }
}

export class MaintenanceScheduleCreateDto {
    constructor(param: MaintenanceScheduleCreateDto) {
        this.serverState = param.serverState;
        this.scheduleTime = param.scheduleTime;
        this.platform = param.platform;
    }
    @IsNumber()
    serverState: EGameServerState;

    @IsDate()
    scheduleTime: Date;

    @IsNumber()
    platform: EPlatform;
}

export class MaintenanceScheduleListQuery extends PaginationQuery {
    constructor(param: MaintenanceScheduleListQuery) {
        super(param);
        this.projectId = param.projectId;
        this.platform = param.platform;
        this.serverState = param.serverState;
        this.requestState = param.requestState;
        this.scheduleTime = param.scheduleTime;
    }
    @IsNotEmpty()
    @IsNumber()
    projectId: number;

    @IsOptional()
    @IsEnum(EPlatform)
    platform?: EPlatform;

    @IsOptional()
    @IsEnum(EGameServerState)
    serverState?: EGameServerState;

    @IsOptional()
    @IsEnum(NOTICE_STATE)
    requestState?: NOTICE_STATE;

    @IsOptional()
    @IsString()
    scheduleTime?: string;
}

export interface IMaintenanceSchedule {
    projectId: number;
    platform: EPlatform;
    serverState: EGameServerState;
    requestState: NOTICE_STATE;
    scheduleTime: Date;
    _id: string;
}
