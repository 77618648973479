import { handleActions } from 'redux-actions';

import { Login, Logout } from './action';

const initState: AuthStore = {
    email: sessionStorage.getItem('xEmail'),
};

export default handleActions(
    {
        [(Login.Email.Request, Login.Google.Request)]: () => {
            sessionStorage.removeItem('xEmail');
            return initState;
        },
        [Login.Email.Success]: (_, { payload }: { payload: { email: string } }) => {
            sessionStorage.setItem('xEmail', payload.email);
            return {
                email: payload.email,
            };
        },
        [(Logout.Failure, Logout.Request, Logout.Success)]: () => {
            sessionStorage.removeItem('xEmail');
            return { email: null };
        },
    },
    initState,
);
