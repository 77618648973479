import { useScreenLock } from 'hooks';
import useGetProjectList from 'hooks/project/model/useGetProjectList';
import { useCallback, useState } from 'react';
import { notificationPush } from 'utils/notificationUtils';
import { ProjectRepository } from 'repository';
import { ProjectCreateRequest, ProjectUpdateRequest } from '@gamebase-ops/interfaces/project';
import { MyProjectDto } from '@gamebase-ops/interfaces/authorization';
import { COMMON_PROJECT_ID } from 'constants/constants';

export default function useProjectAdmin() {
    const [projectDetail, setProjectDetail] = useState<MyProjectDto | null>(null);

    const { data: projectList, refetch } = useGetProjectList();
    const { withScreenLockCall } = useScreenLock();

    const createProjectRequest = useCallback(
        async (param: ProjectCreateRequest) => {
            await withScreenLockCall(ProjectRepository.create, { body: param });
            notificationPush({ status: 'success', message: '프로젝트가 생성 되었습니다.' });
            await withScreenLockCall(refetch);
        },
        [refetch, withScreenLockCall],
    );

    const putProjectRequest = useCallback(
        async (param: ProjectUpdateRequest) => {
            await withScreenLockCall(ProjectRepository.update, { body: param });
            notificationPush({ status: 'success', message: '프로젝트가 수정 되었습니다.' });
            await withScreenLockCall(refetch);
        },
        [withScreenLockCall, refetch],
    );

    const deleteProjectRequest = useCallback(
        async (param: string) => {
            await withScreenLockCall(ProjectRepository.delete, { query: { id: param, projectId: COMMON_PROJECT_ID } });
            notificationPush({ status: 'success', message: '프로젝트가 삭제 처리 되었습니다.' });
            await withScreenLockCall(refetch);
        },
        [withScreenLockCall, refetch],
    );

    return {
        projectDetail,
        createProjectRequest,
        putProjectRequest,
        deleteProjectRequest,
        projectList: projectList?.result,
        setProjectDetail,
    };
}
