import { RoleBasicDto } from '@gamebase-ops/interfaces/role';
import { EditRoleRequestDto } from 'dto/role.dto';
import { useCallback, useEffect, useState } from 'react';
import { AuthorizationRepository, RoleRepository } from 'repository';

function useMyRole() {
    const LIMIT = 15;
    const [page, setPage] = useState(1);

    const [roles, setRoles] = useState<RoleBasicDto[]>();

    const mutate = useCallback(async () => {
        const { result } = await AuthorizationRepository.roleList({ query: { page, limit: LIMIT } });

        setRoles(result.data);
    }, [page]);

    useEffect(() => {
        mutate();
    }, [mutate]);

    const createMyRole = useCallback(
        async (param: ICreateRoleDto) => {
            await RoleRepository.create({ body: param });
            mutate();
        },
        [mutate],
    );

    const editMyRole = useCallback(
        async (id: string, param: EditRoleRequestDto) => {
            await RoleRepository.update({ body: { id, ...param } });
            mutate();
        },
        [mutate],
    );

    const deleteMyRole = useCallback(
        async (param: string) => {
            await RoleRepository.delete({ query: { id: param } });
            mutate();
        },
        [mutate],
    );

    return {
        roles,
        createMyRole,
        editMyRole,
        deleteMyRole,
        LIMIT,
        page,
        setPage,
    };
}

export default useMyRole;
