import { useCallback, useState } from 'react';
import { ESystemMailType, MAIL_TYPE } from 'constants/constants';

import { MailRepository } from 'repository';

import { MailCreateRequest, MailUpdateRequest } from '@gamebase-ops/interfaces/mail/request';
import { ISendMailItem } from 'dto/mail.dto';
interface IParam {
    defaultUsers?: string[];
    defaultItems?: ISendMailItem[];
    isCopyMode?: boolean;
}

export default function useMailSend(projectId: string, param?: IParam) {
    const [users, setUsers] = useState<string[]>(param?.defaultUsers || []);
    const [items, setItems] = useState<ISendMailItem[]>(param?.defaultItems || []);

    const addItem = useCallback((param: ISendMailItem) => {
        setItems((prev) => [...prev, param]);
    }, []);

    const deleteItem = useCallback((index: number) => {
        setItems((prev) => prev.filter((_, prevIndex) => index !== prevIndex));
    }, []);

    const deleteUser = useCallback((userId: string) => {
        setUsers((prev) => prev.filter((user) => user !== userId));
    }, []);

    const postTargetUsersMailSend = useCallback(
        async (id: string) => {
            await MailRepository.sendMail({ query: { projectId }, body: { id } });
        },
        [projectId],
    );

    const postSystemMailSend = useCallback(
        async (id: string) => {
            await MailRepository.sendSystemMail({ query: { projectId }, body: { id } });
        },
        [projectId],
    );

    const postTargetUserMailSaveContent = useCallback(
        async (param: MailCreateRequest) => {
            await MailRepository.createMail({ query: { projectId }, body: param });
        },
        [projectId],
    );

    const putTargetUserMailSaveContent = useCallback(
        async (id: string, param: MailUpdateRequest) => {
            await MailRepository.updateMail({ query: { projectId }, body: { ...param, id } });
        },
        [projectId],
    );

    const getInputType = useCallback((systemMailType) => {
        switch (systemMailType) {
            case ESystemMailType.AppVersion:
                return 'string';
            case ESystemMailType.EqualClassId:
            case ESystemMailType.LessThanClassId:
            case ESystemMailType.GreaterThanClassId:
                return 'number';
            case ESystemMailType.EqualCreateUser:
            case ESystemMailType.LessThanCreateUser:
            case ESystemMailType.GreateThanCreateUser:
                return 'date';
            default:
                return null;
        }
    }, []);

    const postAllUserMailSaveContent = useCallback(
        async (param: any) => {
            const inputType = getInputType(param.conditionType);
            if (inputType === 'date') {
                param.conditionData = new Date(`${param.conditionData.date} ${param.conditionData.time}`);
            }
            if (inputType === 'number') {
                param.conditionData = Number(param.conditionData);
            }

            await MailRepository.createSystemMail({ query: { projectId }, body: param });
        },
        [getInputType, projectId],
    );

    const putAllUserMailSaveContent = useCallback(
        async (id: string, param: any) => {
            const inputType = getInputType(param.conditionType);
            if (inputType === 'date') {
                param.conditionData = new Date(`${param.conditionData.date} ${param.conditionData.time}`);
            }
            if (inputType === 'number') {
                param.conditionData = Number(param.conditionData);
            }

            await MailRepository.updateSystemMail({ query: { projectId }, body: { ...param, id } });
        },
        [getInputType, projectId],
    );

    const saveMailApiCall = useCallback(
        async (mailType: MAIL_TYPE, arg, mailId?: string) => {
            arg.items = arg.items || [];

            if (mailType === MAIL_TYPE.SYSTEM) {
                if (mailId && param?.isCopyMode !== true) {
                    await putAllUserMailSaveContent(mailId, arg);
                } else {
                    await postAllUserMailSaveContent(arg);
                }
            } else {
                if (mailId && param?.isCopyMode !== true) {
                    await putTargetUserMailSaveContent(mailId, arg);
                } else {
                    await postTargetUserMailSaveContent(arg);
                }
            }
        },
        [
            param,
            postAllUserMailSaveContent,
            postTargetUserMailSaveContent,
            putAllUserMailSaveContent,
            putTargetUserMailSaveContent,
        ],
    );

    return {
        users,
        setUsers,
        deleteUser,
        items,
        addItem,
        deleteItem,
        saveMailApiCall,
        postTargetUsersMailSend,
        postSystemMailSend,
    };
}
