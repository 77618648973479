import { Form, Input, Button } from 'antd';
import { useAuth, useFormRules } from 'hooks';

const LoginForm = () => {
    const { emailLoginRequest } = useAuth();
    const { formValidator } = useFormRules();

    return (
        <Form onFinish={emailLoginRequest} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item name="userId" label="userId" rules={formValidator.getRules('account_id')}>
                <Input />
            </Form.Item>
            <Form.Item name="password" label="password" rules={formValidator.getRules('account_password')}>
                <Input type="password" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LoginForm;
